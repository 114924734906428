import { RootRoute } from '@tanstack/react-router'
import { Keyword_ } from '../containers/Dashboards/SerpAiAlpha'
import { TopicClusterTab } from '../containers/topic-clusters/TopicClusters'
import { TimeRange } from '../hooks/useTimeRanges'
import { TableState } from '@tanstack/react-table'
import { TopicTab } from '../containers/topics/Topic'
import { TopicPb } from '../utils/proto'
import { QueryFacetId } from '../utils/queryFacets'
import { KeywordSource as KeywordSourceType } from '../utils/Api'
import { ProjectsOverviewWidget } from '../containers/projects/Projects'
import { BubbleChartState } from '../components/BubbleChart'
import { SearchMonitoredUrlsTab } from '../containers/monitored-urls/MonitoredUrls'
import { SerpWidgetState } from '../components/SerpResultsTables'
import Root from '../containers/Root'
import { MetricId } from '../utils/Metrics'

export const Route = new RootRoute({
  validateSearch: search => {
    return search as {
      brandId?: string
      clusterId?: string
      clusterListFilters?: Record<string, unknown>
      clustersBubbleChart?: BubbleChartState
      clustersFocusDomainId?: string
      clustersSelectedMetricIds?: string[]
      clustersTable?: Partial<TableState>
      clustersView?: 'table' | 'cards'
      continueAs?: string
      expandedMonitoredUrlIds?: Record<string, boolean>
      expandedTopicIds?: Record<string, boolean>
      featuredSnippetSegmentId?: string
      googleSearchConsoleSiteUrl?: string
      groupBy?: QueryFacetId
      hubspotListId?: string
      import?: true
      importer?: 'google-search-console'
      keyword?: Keyword_ | undefined
      keywordGroup?: string
      keywordGroups?: string[]
      keywordId?: string
      keywordIds?: string[]
      keywordSource?: Partial<KeywordSourceType>
      keywordSourceId?: string
      loginFlow?: 'topic-clusters'
      metricId?: MetricId
      monitoredUrlId?: string
      monitoredUrlsBubbleChart?: BubbleChartState
      monitoredUrlsFilters?: Record<string, unknown>
      monitoredUrlsImportTabId?: 'domain' | 'sitemap' | 'text'
      monitoredUrlsExplorerSearch?: string
      monitoredUrlsTab?: SearchMonitoredUrlsTab
      newTopic?: Partial<TopicPb>
      projectId?: string
      reputationSingleKeywordMode?: 'reputation' | 'rank'
      rollup?: string
      segmentId?: string
      segmentIds?: string[]
      serpwooUploaded?: boolean
      sourceType?: string // This should be more strict
      stepIndex?: number
      timeRanges?: TimeRange[]
      topicClusterTab?: TopicClusterTab
      topicId?: string
      topicTab?: TopicTab
      topicsFilters?: Record<string, unknown>
      topicsTab?: 'flat' | 'tree'
      url?: string
      urlIds?: string[]
      workspaceId?: string
      projectsOverviewWidget?: ProjectsOverviewWidget
      serpWidget?: SerpWidgetState
      // widgets?: {
      //   metrics?: WidgetState
      //   top?: WidgetState
      //   winners?: WidgetState
      //   losers?: WidgetState
      //   //
      //   overview?: WidgetState
      //   keywordGroups?: WidgetState
      //   segments?: WidgetState
      //   urls?: WidgetState
      //   keywords?: WidgetState
      //   rollups?: WidgetState
      //   //
      //   serps?: WidgetState
      // }
    }
  },
  component: Root,
})
