import { Router } from '@tanstack/react-router'
import { parseSearchWith } from '@tanstack/react-router'
import compressJson from 'compressed-json'
import { decodeFromBinary } from './utils'
import { routeTree } from './routeTree.gen'

//

export const router = new Router({
  routeTree,
  parseSearch: parseSearchWith(value => {
    try {
      // Try to decode the compressed json
      const decoded = decodeFromBinary(value)
      const parsed = JSON.parse(decoded)
      const decompressed = compressJson.decompress(parsed)
      return decompressed
    } catch {
      // If it doesn't work, attempt to fall back to normal JSON decoding
      return JSON.parse(value)
    }
  }),
  // reloadOnWindowFocus: false,
  // defaultPreload: 'intent',
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
