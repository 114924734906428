import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceBySegmentMetricsWidget,
  PerformanceBySegmentTopWidget,
  PerformanceBySegmentWinnersWidget,
  PerformanceBySegmentLosersWidget,
  PerformanceBySegmentSegmentsWidget,
} from '../../../containers/Dashboards/PerformanceBySegment'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-by-segment'
)({
  validateSearch: search =>
    search as {
      performanceBySegmentMetricsWidget?: PerformanceBySegmentMetricsWidget
      performanceBySegmentTopWidget?: PerformanceBySegmentTopWidget
      performanceBySegmentWinnersWidget?: PerformanceBySegmentWinnersWidget
      performanceBySegmentLosersWidget?: PerformanceBySegmentLosersWidget
      performanceBySegmentSegmentsWidget?: PerformanceBySegmentSegmentsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceBySegmentMetricsWidget:
          search.performanceBySegmentMetricsWidget,
        performanceBySegmentTopWidget: search.performanceBySegmentTopWidget,
        performanceBySegmentWinnersWidget:
          search.performanceBySegmentWinnersWidget,
        performanceBySegmentLosersWidget:
          search.performanceBySegmentLosersWidget,
        performanceBySegmentSegmentsWidget:
          search.performanceBySegmentSegmentsWidget,
      }
    },
  ],
})
