// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/overages.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProductFamily, UsageType } from "./usage_pb.js";
import { Money } from "../../../google/type/money_pb.js";

/**
 * UpdateMaxOverageRequest is the request for UpdateMaxOverage.
 *
 * @generated from message nozzle.billing.v1.UpdateMaxOverageRequest
 */
export class UpdateMaxOverageRequest extends Message<UpdateMaxOverageRequest> {
  /**
   * @generated from field: nozzle.billing.v1.MaxOverage max_overage = 1;
   */
  maxOverage?: MaxOverage;

  /**
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdateMaxOverageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateMaxOverageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_overage", kind: "message", T: MaxOverage },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMaxOverageRequest {
    return new UpdateMaxOverageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMaxOverageRequest {
    return new UpdateMaxOverageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMaxOverageRequest {
    return new UpdateMaxOverageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMaxOverageRequest | PlainMessage<UpdateMaxOverageRequest> | undefined, b: UpdateMaxOverageRequest | PlainMessage<UpdateMaxOverageRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMaxOverageRequest, a, b);
  }
}

/**
 * UpdateMaxOverageResponse is the request for UpdateMaxOverage.
 *
 * @generated from message nozzle.billing.v1.UpdateMaxOverageResponse
 */
export class UpdateMaxOverageResponse extends Message<UpdateMaxOverageResponse> {
  /**
   * @generated from field: nozzle.billing.v1.MaxOverage max_overage = 1;
   */
  maxOverage?: MaxOverage;

  constructor(data?: PartialMessage<UpdateMaxOverageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateMaxOverageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_overage", kind: "message", T: MaxOverage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMaxOverageResponse {
    return new UpdateMaxOverageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMaxOverageResponse {
    return new UpdateMaxOverageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMaxOverageResponse {
    return new UpdateMaxOverageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMaxOverageResponse | PlainMessage<UpdateMaxOverageResponse> | undefined, b: UpdateMaxOverageResponse | PlainMessage<UpdateMaxOverageResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMaxOverageResponse, a, b);
  }
}

/**
 * MaxOverage is a maximum spend limit for a workspace, product family and usage type.
 *
 * @generated from message nozzle.billing.v1.MaxOverage
 */
export class MaxOverage extends Message<MaxOverage> {
  /**
   * @generated from field: int64 overage_id = 1;
   */
  overageId = protoInt64.zero;

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * optional, if 0, the max overage will be applied to the entire workspace
   *
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 3;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * optional, if 0, the max overage will be applied to the entire workspace/ProductFamily
   *
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 4;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * This is the maximum amount that can be charged for a single billing period.
   *
   * @generated from field: google.type.Money amount = 5;
   */
  amount?: Money;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<MaxOverage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.MaxOverage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "overage_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 4, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 5, name: "amount", kind: "message", T: Money },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MaxOverage {
    return new MaxOverage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MaxOverage {
    return new MaxOverage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MaxOverage {
    return new MaxOverage().fromJsonString(jsonString, options);
  }

  static equals(a: MaxOverage | PlainMessage<MaxOverage> | undefined, b: MaxOverage | PlainMessage<MaxOverage> | undefined): boolean {
    return proto3.util.equals(MaxOverage, a, b);
  }
}

