import * as React from 'react'
import {
  useNavigate,
  useSearch,
  FullSearchSchema,
  RegisteredRouter,
} from '@tanstack/react-router'

import { Updater, deepEqual, functionalUpdate, getBy, setBy } from '../utils'
import useGetLatest from '../hooks/useGetLatest'
import LocalStorage from '../utils/LocalStorage'
import { NoInfer } from '../utils/types'
import useStableCallback from '../hooks/useStableCallback'
import Link from './Link'
import SessionStorage from '../utils/SessionStorage'
import { useStructuralSharing } from '../hooks/useStructuralSharing'

const leasedByPath: Record<string, string> = {}
// const uidsByPath: Record<string, Set<string>> = {}

type UseSearchStateOptions<TValue> = {
  path: string | any[]
  useDefaultValue?: (ctx: {
    search: FullSearchSchema<RegisteredRouter['routeTree']>
    deps: any[]
  }) => NoInfer<TValue>
  useCachedValue?: (ctx: {
    search: FullSearchSchema<RegisteredRouter['routeTree']>
    deps: any[]
  }) => NoInfer<TValue>
  useDeps?: (ctx: {
    search: FullSearchSchema<RegisteredRouter['routeTree']>
  }) => any[]
  usePersister?: (ctx: {
    search: FullSearchSchema<RegisteredRouter['routeTree']>
    deps: any[]
  }) => (value: NoInfer<TValue>) => void
  useIsInvalid?: (value: TValue) => any
  useTransform?: (value: TValue) => NoInfer<TValue>
  writeDefault?: boolean
  defaultReplace?: boolean
}

export function useSearchState<TValue>({
  path,
  useDefaultValue,
  useCachedValue,
  useDeps,
  usePersister,
  useIsInvalid,
  useTransform,
  writeDefault,
  defaultReplace,
}: UseSearchStateOptions<TValue>): {
  state: TValue
  setState: (updater: Updater<TValue>, opts?: { replace?: boolean }) => void
  reset: (opts?: { replace?: boolean }) => void
  Link: (
    props: React.HTMLProps<HTMLAnchorElement> & {
      value: Updater<TValue>
    }
  ) => JSX.Element
  isDirty: boolean
} {
  const uid = React.useRef(Math.random().toString(36).slice(2)).current
  defaultReplace = defaultReplace ?? true
  const serializedPath = JSON.stringify(path)

  if (!leasedByPath[serializedPath]) {
    leasedByPath[serializedPath] = uid

    Promise.resolve().then(() => {
      leasedByPath[serializedPath] = ''
    })
  }

  const lease = useStableCallback((fn: () => void) => {
    if (leasedByPath[serializedPath] === uid) {
      fn()
    } else {
      // console.log('deduped', path)
    }
  })

  const search = useSearch({ strict: false })
  const navigate = useNavigate()
  const originalDeps = useDeps?.({ search })
  const deps = React.useMemo(() => originalDeps || [], [originalDeps])
  const serializedDeps = JSON.stringify(deps)

  const cachedValue = useCachedValue?.({ search, deps })
  const defaultValue = useDefaultValue?.({ search, deps })

  const resolvedDefaultValue =
    cachedValue !== undefined ? cachedValue : defaultValue

  const isCachedDirty = React.useMemo(
    () => !deepEqual(cachedValue, defaultValue),
    [cachedValue, defaultValue]
  )

  const originalValue = getBy(search, path) as any
  let value = React.useMemo(
    () => (originalValue !== undefined ? originalValue : resolvedDefaultValue),
    [originalValue, resolvedDefaultValue]
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useTransform) value = useTransform(value)

  const isDirty = React.useMemo(
    () => !deepEqual(value, resolvedDefaultValue),
    [resolvedDefaultValue, value]
  )

  const setValue = useStableCallback(
    (
      updater: Updater<TValue>,
      opts?: {
        replace?: boolean
      }
    ) => {
      return navigate({
        search: old =>
          // @ts-ignore
          setBy(old, path, prev => {
            return functionalUpdate(
              updater,
              originalValue === undefined ? value : prev
            )
          }),
        replace: opts?.replace ?? defaultReplace,
      })
    }
  )

  // Create a link to the value
  const StateLink = useStableCallback(
    (props: React.ComponentProps<typeof Link> & { value: Updater<TValue> }) => {
      return (
        <Link
          replace={defaultReplace}
          {...props}
          search={(s: any) => {
            return setBy(s, path, functionalUpdate(props.value, value))
          }}
        />
      )
    }
  )

  const isInvalid = useIsInvalid?.(value)
  const previousDepsRef = React.useRef(serializedDeps)

  React.useLayoutEffect(() => {
    // If the value is undefined and we have a default value, set it
    if (
      originalValue === undefined &&
      resolvedDefaultValue !== undefined &&
      (writeDefault || isCachedDirty)
    ) {
      lease(() => {
        console.info('default value', path, value)
        setValue(() => resolvedDefaultValue, {
          replace: true,
        })
      })

      return
    }

    // If the value is invalid and we have a default value, set it
    // Note: this is the actual default value, not the resolved default value
    // that could be cached
    if (isInvalid && defaultValue !== undefined && value !== defaultValue) {
      lease(() => {
        console.info('invalid', path, value)
        setValue(defaultValue, { replace: true })
      })
      return
    }

    // If the deps are dirty, we have a non-undefined value, and the default value is ready,
    // Reset the value to the default
    if (
      // Deps changed
      serializedDeps !== previousDepsRef.current &&
      defaultValue !== undefined
    ) {
      lease(() => {
        console.info('deps dirty', path, value)
        setValue(() => defaultValue, { replace: true })
      })
    }
  }, [
    defaultValue,
    isCachedDirty,
    isInvalid,
    lease,
    originalValue,
    path,
    resolvedDefaultValue,
    serializedDeps,
    setValue,
    value,
    writeDefault,
  ])

  const getPersister = useGetLatest(usePersister?.({ search, deps }))

  // Any time the value changes, allow us to persist it
  React.useLayoutEffect(() => {
    if (value !== undefined) {
      getPersister()?.(value)
    }
  }, [getPersister, value])

  const reset = React.useCallback(
    (opts?: { replace?: boolean }) => {
      setValue(() => resolvedDefaultValue!, opts)
    },
    [resolvedDefaultValue, setValue]
  )

  const ref = React.useRef({})

  // Return the value and updater
  return Object.assign(ref.current, {
    state: value,
    setState: setValue,
    reset,
    Link: StateLink,
    isDirty,
  }) as any
}

export const useLocalStorageSearchPersisterState = <TValue,>(
  opts: UseSearchStateOptions<TValue> & {
    useDeps?: (ctx: {
      search: FullSearchSchema<RegisteredRouter['routeTree']>
      deps: any[]
    }) => any
    useDefaultValue?: (ctx: {
      search: FullSearchSchema<RegisteredRouter['routeTree']>
      deps: any[]
    }) => TValue
    mode?: 'localStorage' | 'sessionStorage'
  }
) => {
  const storageImpl =
    opts.mode === 'sessionStorage' ? SessionStorage : LocalStorage

  return useSearchState({
    ...opts,
    useCachedValue: ({ deps }): TValue => {
      // Get the cached last value
      const key = `searchState:${JSON.stringify(opts.path)}:${JSON.stringify(
        deps.filter(Boolean)
      )}`

      return useStructuralSharing(storageImpl.get(key) as TValue)
    },
    usePersister: ({ deps }) => {
      const key = `searchState:${JSON.stringify(opts.path)}:${JSON.stringify(
        deps.filter(Boolean)
      )}`

      return (value: any) => storageImpl.set(key, value)
    },
  })
}
