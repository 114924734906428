import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { BiIntersect } from 'react-icons/bi'
import { FaCog, FaMagic, FaPlus, FaTimes } from 'react-icons/fa'
import { MdCallSplit } from 'react-icons/md'
import Button from '../components/Button'
import Select from '../components/Select'
import Tooltip from '../components/Tooltip'
import { useKeywordFacetsQuery } from '../hooks/keywords'
import useGetLatest from '../hooks/useGetLatest'
import { booleanOperatorOptions } from '../options/booleanOperatorOptions'
import { countryOptions } from '../options/countryOptions'
import { deviceOptions } from '../options/deviceOptions'
import { engineOptions } from '../utils/engines'
import {
  booleanIsOptions,
  numberIsOptions,
  stringIsOptions,
} from '../options/isValueOptions'
import { languageOptions } from '../options/languageOptions'
import { locationTypeOptions } from '../utils/locationType'
import { matchTypeOptions } from '../options/matchTypeOptions'
import { numberOperatorOptions } from '../options/numberOperatorOptions'
import { stringOperatorOptions } from '../options/stringOperatorOptions'
import { functionalUpdate, setBy } from '../utils'
import {
  flattenCondition,
  makeAllCondition,
  makeAnyCondition,
} from '../utils/Conditions'
import { useColumnsQuery } from '../utils/columns'
import { withProps } from '../utils/withProps'
import Caption from './Caption'
import Card from './Card'

//

export const ConditionChipPiece = withProps('div')(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge(
      'hover:background-color[rgba(0,0,0,.2)] flex h-6 items-center justify-center whitespace-nowrap border-r border-[rgba(0,0,0,.2)] px-1.5 text-sm',
      className
    ),
  })
)

export const ConditionRemove = withProps(ConditionChipPiece)(
  ({ className, ...props }) => ({
    ...props,
    className: twMerge('border-r-0 opacity-30 hover:opacity-100', className),
  })
)

export default function Condition({
  isRoot = true,
  condition = { matchType: 'expression' },
  ...rest
}) {
  const props = {
    ...rest,
    isRoot,
    condition,
  }

  if (condition.matchType === 'expression') {
    return <ExpressionCondition {...props} />
  }

  return <GroupingCondition {...props} />
}

type GroupingProps = {
  condition: any
  stage?: string
  onChange?: any
  error?: any
  workspaceId?: any
  projectId?: any
  isRoot?: boolean
  canEdit?: boolean
}

function GroupingCondition({
  condition,
  stage,
  onChange,
  error,
  workspaceId,
  projectId,
  canEdit,
}: GroupingProps) {
  // const columnsQuery = useColumnsQuery()

  const handleIndexChange = (index: any) => (updater: any) =>
    onChange((old: any) => {
      const newConditions = old.conditions
        .map((subCondition: any, i: any) => {
          if (i === index) {
            return functionalUpdate(updater, subCondition)
          }
          return subCondition
        })
        .filter(Boolean)

      return {
        ...old,
        conditions: newConditions,
      }
    })

  const handleRemoveCondition = () => onChange(undefined)

  const isAny = condition.matchType === 'any'

  const isMultiOr = isAny && condition.conditions?.length > 1

  const prettifyButton = (
    <Button
      size="xs"
      color={['gray-600', 'gray-800']}
      className="h-6 rounded-b-none rounded-l-none rounded-r-none"
      onClick={() =>
        onChange((old: any) => flattenCondition(old, { removeEmpty: true }))
      }
    >
      <Tooltip tooltip="Prettify">
        <FaMagic className="inline text-xs" />
      </Tooltip>
    </Button>
  )

  const andButton = (
    <Button
      size="xs"
      color={['gray-600', 'gray-800']}
      className="first:rounded-r-none last:rounded-l-none last:opacity-50 last:hover:opacity-100"
      onClick={() =>
        onChange((old: any) =>
          setBy(old, 'conditions', (prev = []) => [
            ...prev,
            !isAny ? { matchType: 'expression' } : { matchType: 'all' },
          ])
        )
      }
    >
      {!isAny ? <FaPlus /> : <BiIntersect className="inline" />} AND
    </Button>
  )

  const orButton = (
    <Button
      size="xs"
      color={['gray-600', 'gray-800']}
      className="first:rounded-r-none last:rounded-l-none last:opacity-50 last:hover:opacity-100"
      onClick={() =>
        onChange((old: any) =>
          setBy(old, 'conditions', (prev = []) => [
            ...prev,
            isAny
              ? { matchType: 'expression' }
              : {
                  matchType: 'any',
                },
          ])
        )
      }
    >
      {isAny ? <FaPlus /> : <MdCallSplit className="inline" />} OR
    </Button>
  )

  return (
    <Card className="p-0 shadow hover:shadow-xl">
      {condition.matchType === 'any' ? (
        <div className="flex">
          <Button
            size="xs"
            color="gray-700"
            hoverColor="gray-700"
            className="flex h-6 w-full cursor-default justify-between rounded-b-none rounded-r-none"
            disabled={!(canEdit ?? true)}
          >
            <span>Match Any</span>
          </Button>
          {canEdit ?? true ? (
            <>
              <Button
                size="xs"
                color={['gray-600', 'gray-800']}
                className="h-6 rounded-b-none rounded-l-none rounded-r-none"
                onClick={() =>
                  onChange((old: any) => ({
                    ...old,
                    matchType: 'all',
                  }))
                }
              >
                <Tooltip tooltip="Convert to Match All (AND)">
                  <BiIntersect className="text-xs" />
                </Tooltip>
              </Button>
              {prettifyButton}
              <Button
                size="xs"
                color="gray-500"
                hoverColor="red-500"
                className="h-6 rounded-b-none rounded-l-none"
                onClick={() => handleRemoveCondition()}
              >
                <FaTimes className="inline" />
              </Button>
            </>
          ) : null}
        </div>
      ) : (
        <div className="flex">
          <Button
            size="xs"
            color="gray-700"
            hoverColor="gray-700"
            className="flex h-6 w-full cursor-default justify-between rounded-b-none rounded-r-none"
            disabled={!(canEdit ?? true)}
          >
            <span>Match All</span>
          </Button>
          {canEdit ?? true ? (
            <>
              <Button
                size="xs"
                color={['gray-600', 'gray-800']}
                className="h-6 rounded-b-none rounded-l-none rounded-r-none"
                onClick={() =>
                  onChange((old: any) => ({
                    ...old,
                    matchType: 'any',
                  }))
                }
              >
                <Tooltip tooltip="Convert to Match Any (OR)">
                  <MdCallSplit className="text-xs" />
                </Tooltip>
              </Button>
              {prettifyButton}
              <Button
                size="xs"
                color="gray-500"
                hoverColor="red-500"
                className="h-6 rounded-b-none rounded-l-none"
                onClick={() => handleRemoveCondition()}
              >
                <FaTimes className="inline" />
              </Button>
            </>
          ) : null}
        </div>
      )}
      <div className="rounded-b-md border border-t-0 border-gray-200 p-2 dark:border-gray-800">
        <div
          className={twMerge(
            'flex flex-wrap items-start gap-2',
            isMultiOr && `flex-col`
          )}
        >
          {condition?.conditions?.map((subCondition: any, index: any) => {
            return (
              <div className="flex gap-2" key={index}>
                {isMultiOr ? (
                  <Caption className={twMerge(!index && `invisible`)}>
                    OR
                  </Caption>
                ) : null}
                <Condition
                  {...{
                    condition: subCondition,
                    onChange: handleIndexChange(index),
                    error: error?.conditions?.[index],
                    stage,
                    workspaceId,
                    projectId,
                    parentCondition: condition,
                    isRoot: false,
                    canEdit,
                  }}
                />
              </div>
            )
          })}
          <div className="flex gap-2">
            {isMultiOr ? <Caption className="invisible">OR</Caption> : null}
            {canEdit ?? true ? (
              !condition.conditions?.length ? (
                <Button
                  size="xs"
                  color={['gray-600', 'gray-800']}
                  onClick={() =>
                    onChange((old: any) =>
                      setBy(old, 'conditions', (prev = []) => [
                        ...prev,
                        { matchType: 'expression' },
                      ])
                    )
                  }
                >
                  <FaPlus className="inline" /> Add Condition
                </Button>
              ) : (
                <span className="flex">
                  {isAny ? (
                    <>
                      {orButton}
                      {andButton}
                    </>
                  ) : (
                    <>
                      {andButton}
                      {orButton}
                    </>
                  )}
                </span>
              )
            ) : null}
          </div>
        </div>
      </div>
    </Card>
  )
}

type ExpressProps = {
  condition: any
  onChange?: any
  error?: any
  stage?: any
  teamId?: any
  isRoot?: boolean
  parentCondition?: any
  canEdit?: boolean
}

function ExpressionCondition({
  condition,
  onChange,
  error,
  stage,
  teamId,
  isRoot,
  parentCondition,
  canEdit,
}: ExpressProps) {
  const focusersRef = React.useRef<any>({})

  const getOnChange = useGetLatest(onChange)

  const onRemove = () => onChange?.(undefined)

  const keywordFacets = useKeywordFacetsQuery({
    projectId: teamId,
  })

  const columnsQuery = useColumnsQuery()

  const columns = React.useMemo(
    () => columnsQuery.data?.columnsByStage[stage] ?? [],
    [columnsQuery.data, stage]
  )

  const column = columns.find((d: any) => d.id === condition.columnId)

  const operatorOptions = React.useMemo(
    () =>
      !column
        ? []
        : column.dataType === 'float' || column.dataType === 'integer'
        ? numberOperatorOptions
        : column.dataType === 'bool'
        ? booleanOperatorOptions
        : stringOperatorOptions,
    [column]
  )

  const isIsOperator = condition.operator?.includes('is')

  const valueOptions = React.useMemo(
    () =>
      !column
        ? []
        : isIsOperator
        ? column.dataType === 'float' || column.dataType === 'integer'
          ? numberIsOptions
          : column.dataType === 'bool'
          ? booleanIsOptions
          : stringIsOptions
        : // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          keywordFacets.data?.[column.id] ||
          (column.id === 'engine'
            ? engineOptions
            : column.id === 'device'
            ? deviceOptions
            : column.id === 'language'
            ? languageOptions
            : column.id === 'country'
            ? countryOptions
            : column.id === 'location_type'
            ? locationTypeOptions
            : []),
    [column, isIsOperator, keywordFacets.data]
  )

  const andButton = (
    <Button
      size="xs"
      color={['gray-600', 'gray-800']}
      className="first:rounded-r-none last:rounded-l-none last:opacity-50 last:hover:opacity-100"
      onClick={() =>
        onChange((old: any) =>
          makeAllCondition([old, { matchType: 'expression' }])
        )
      }
    >
      <BiIntersect /> AND
    </Button>
  )

  const orButton = (
    <Button
      size="xs"
      color={['gray-600', 'gray-800']}
      className="first:rounded-r-none last:rounded-l-none last:opacity-50 last:hover:opacity-100"
      onClick={() =>
        onChange((old: any) =>
          makeAnyCondition([old, { matchType: 'expression' }])
        )
      }
    >
      <MdCallSplit /> OR
    </Button>
  )

  React.useEffect(() => {
    if (
      column &&
      !operatorOptions.map(d => d.value).includes(condition.operator)
    ) {
      getOnChange()((old: any) => ({
        ...old,

        operator: operatorOptions[0].value,
      }))
    }
  }, [column, condition.operator, getOnChange, operatorOptions])

  return (
    <div className="inline-flex flex-wrap gap-2">
      <Button
        className=" inline-flex
    flex-nowrap
    gap-0 overflow-hidden
    p-0"
        type="button"
        color={error ? 'yellow-500' : 'blue-500'}
        size="sm"
      >
        {isRoot && onChange ? (
          <Select
            options={matchTypeOptions}
            onChange={value =>
              onChange((old: any) => ({
                conditions: [old],
                matchType: value,
              }))
            }
          >
            {({ onClick }: any) => {
              return (
                <ConditionChipPiece onClick={onClick}>
                  <FaCog className="inline text-xs" />
                </ConditionChipPiece>
              )
            }}
          </Select>
        ) : null}
        <Select
          value={condition.columnId}
          options={columns}
          onChange={columnId => {
            getOnChange()((old: any) => ({
              ...old,
              columnId,
              matchType: 'expression',
            }))
            Promise.resolve().then(() => {
              if (
                columns.find((d: any) => d.id == columnId).dataType == 'bool'
              ) {
                focusersRef.current.values()
              } else {
                focusersRef.current.operator()
              }
            })
          }}
          className={twMerge(!onChange && `cursor-default`)}
        >
          {({ onClick, selectedOption }: any) => {
            focusersRef.current.columnId = onClick

            return (
              <ConditionChipPiece
                onClick={onChange ? onClick : null}
                // @ts-expect-error  // Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                className={!onChange ? 'disabled' : null}
              >
                {selectedOption.value ? selectedOption.label : 'Column...'}
              </ConditionChipPiece>
            )
          }}
        </Select>
        <Select
          value={condition.operator}
          options={operatorOptions}
          onChange={operator => {
            getOnChange()((old: any) => ({
              ...old,
              operator,
            }))
            Promise.resolve().then(() => {
              focusersRef.current.values()
            })
          }}
          className={twMerge(!onChange && `cursor-default`)}
        >
          {({ onClick, selectedOption }: any) => {
            // Set this as the next operator focus
            focusersRef.current.operator = onClick

            return (
              <ConditionChipPiece onClick={onChange ? onClick : null}>
                {selectedOption.value
                  ? selectedOption.label.split(' ')[0]
                  : 'Operator...'}
              </ConditionChipPiece>
            )
          }}
        </Select>
        {column?.dataType === 'bool' ? (
          <Select
            multi
            value={condition.values}
            options={booleanIsOptions}
            onChange={values => {
              values = values.filter(d =>
                typeof d === 'string' ? d.trim() : true
              )
              getOnChange()((old: any) => ({
                ...old,
                values,
              }))
            }}
            className={twMerge(!onChange && `cursor-default`)}
          >
            {({ onClick, selectedOption }: any) => {
              // Set this as the next value focus
              focusersRef.current.values = onClick

              return (
                <ConditionChipPiece onClick={onChange ? onClick : null}>
                  {typeof selectedOption.length
                    ? selectedOption.map((d: any) => d.label).join(', ')
                    : 'True/False...'}
                </ConditionChipPiece>
              )
            }}
          </Select>
        ) : (
          <Select
            multi
            create={!isIsOperator}
            placeholder="Enter value(s)"
            value={condition.values || []}
            options={valueOptions}
            onChange={values => {
              values = values.filter(d =>
                typeof d === 'string' && d !== '' ? d.trim() : true
              )
              getOnChange()((old: any) => ({
                ...old,
                values,
              }))
            }}
            className={twMerge(!onChange && `cursor-default`)}
          >
            {({ onClick, selectedOption }: any) => {
              focusersRef.current.values = onClick
              return (
                <ConditionChipPiece onClick={onChange ? onClick : null}>
                  {selectedOption.length
                    ? selectedOption.map((d: any) => d.label).join(', ')
                    : 'Values...'}
                </ConditionChipPiece>
              )
            }}
          </Select>
        )}
        {!isRoot && (canEdit ?? true) ? (
          parentCondition.matchType === 'any' ? (
            <ConditionChipPiece
              onClick={() =>
                onChange((old: any) => ({
                  ...old,
                  matchType: 'all',
                  conditions: [old],
                }))
              }
            >
              <Tooltip tooltip="Convert to Match All (AND)">
                <BiIntersect className="text-xs" />
              </Tooltip>
            </ConditionChipPiece>
          ) : (
            <ConditionChipPiece
              onClick={() =>
                onChange((old: any) => ({
                  ...old,
                  matchType: 'any',
                  conditions: [old],
                }))
              }
            >
              <Tooltip tooltip="Convert to Match Any (OR)">
                <MdCallSplit className="text-xs" />
              </Tooltip>
            </ConditionChipPiece>
          )
        ) : null}
        {onChange && (canEdit ?? true) ? (
          <ConditionRemove onClick={onRemove}>
            <Tooltip tooltip="Remove">
              <FaTimes className="inline text-sm" />
            </Tooltip>
          </ConditionRemove>
        ) : null}
      </Button>
      {isRoot && onChange ? (
        <span className="flex">
          {andButton}
          {orButton}
        </span>
      ) : null}
    </div>
  )
}
