// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/urls/v1/page.proto (package nozzle.urls.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Operation } from "../../../google/longrunning/operations_pb.js";
import { ClassificationCategory, Entity } from "./language_pb.js";
import { LighthouseResult } from "./lighthouse-result_pb.js";

/**
 * GradeLevel maps to the automated readability index.
 *
 * @generated from enum nozzle.urls.v1.GradeLevel
 */
export enum GradeLevel {
  /**
   * @generated from enum value: GRADE_LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GRADE_LEVEL_KINDERGARTEN = 1;
   */
  KINDERGARTEN = 1,

  /**
   * @generated from enum value: GRADE_LEVEL_FIRST_GRADE = 2;
   */
  FIRST_GRADE = 2,

  /**
   * @generated from enum value: GRADE_LEVEL_SECOND_GRADE = 3;
   */
  SECOND_GRADE = 3,

  /**
   * @generated from enum value: GRADE_LEVEL_THIRD_GRADE = 4;
   */
  THIRD_GRADE = 4,

  /**
   * @generated from enum value: GRADE_LEVEL_FOURTH_GRADE = 5;
   */
  FOURTH_GRADE = 5,

  /**
   * @generated from enum value: GRADE_LEVEL_FIFTH_GRADE = 6;
   */
  FIFTH_GRADE = 6,

  /**
   * @generated from enum value: GRADE_LEVEL_SIXTH_GRADE = 7;
   */
  SIXTH_GRADE = 7,

  /**
   * @generated from enum value: GRADE_LEVEL_SEVENTH_GRADE = 8;
   */
  SEVENTH_GRADE = 8,

  /**
   * @generated from enum value: GRADE_LEVEL_EIGHTH_GRADE = 9;
   */
  EIGHTH_GRADE = 9,

  /**
   * @generated from enum value: GRADE_LEVEL_NINTH_GRADE = 10;
   */
  NINTH_GRADE = 10,

  /**
   * @generated from enum value: GRADE_LEVEL_TENTH_GRADE = 11;
   */
  TENTH_GRADE = 11,

  /**
   * @generated from enum value: GRADE_LEVEL_ELEVENTH_GRADE = 12;
   */
  ELEVENTH_GRADE = 12,

  /**
   * @generated from enum value: GRADE_LEVEL_TWELFTH_GRADE = 13;
   */
  TWELFTH_GRADE = 13,

  /**
   * @generated from enum value: GRADE_LEVEL_COLLEGE = 14;
   */
  COLLEGE = 14,
}
// Retrieve enum metadata with: proto3.getEnumType(GradeLevel)
proto3.util.setEnumType(GradeLevel, "nozzle.urls.v1.GradeLevel", [
  { no: 0, name: "GRADE_LEVEL_UNSPECIFIED" },
  { no: 1, name: "GRADE_LEVEL_KINDERGARTEN" },
  { no: 2, name: "GRADE_LEVEL_FIRST_GRADE" },
  { no: 3, name: "GRADE_LEVEL_SECOND_GRADE" },
  { no: 4, name: "GRADE_LEVEL_THIRD_GRADE" },
  { no: 5, name: "GRADE_LEVEL_FOURTH_GRADE" },
  { no: 6, name: "GRADE_LEVEL_FIFTH_GRADE" },
  { no: 7, name: "GRADE_LEVEL_SIXTH_GRADE" },
  { no: 8, name: "GRADE_LEVEL_SEVENTH_GRADE" },
  { no: 9, name: "GRADE_LEVEL_EIGHTH_GRADE" },
  { no: 10, name: "GRADE_LEVEL_NINTH_GRADE" },
  { no: 11, name: "GRADE_LEVEL_TENTH_GRADE" },
  { no: 12, name: "GRADE_LEVEL_ELEVENTH_GRADE" },
  { no: 13, name: "GRADE_LEVEL_TWELFTH_GRADE" },
  { no: 14, name: "GRADE_LEVEL_COLLEGE" },
]);

/**
 * PageFetchType is how the page was retrieved by the enrich API.
 *
 * @generated from enum nozzle.urls.v1.PageFetchType
 */
export enum PageFetchType {
  /**
   * @generated from enum value: PAGE_FETCH_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAGE_FETCH_TYPE_GO = 1;
   */
  GO = 1,

  /**
   * @generated from enum value: PAGE_FETCH_TYPE_CHROME_HEADLESS = 2;
   */
  CHROME_HEADLESS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PageFetchType)
proto3.util.setEnumType(PageFetchType, "nozzle.urls.v1.PageFetchType", [
  { no: 0, name: "PAGE_FETCH_TYPE_UNSPECIFIED" },
  { no: 1, name: "PAGE_FETCH_TYPE_GO" },
  { no: 2, name: "PAGE_FETCH_TYPE_CHROME_HEADLESS" },
]);

/**
 * PageFetchError is the error that occurred when fetching the page.
 *
 * @generated from enum nozzle.urls.v1.PageFetchError
 */
export enum PageFetchError {
  /**
   * @generated from enum value: PAGE_FETCH_ERROR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_NOT_FOUND = 1;
   */
  NOT_FOUND = 1,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_NO_CONTENT = 2;
   */
  NO_CONTENT = 2,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_FORBIDDEN = 3;
   */
  FORBIDDEN = 3,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_UNAUTHORIZED = 4;
   */
  UNAUTHORIZED = 4,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_INTERNAL_SERVER_ERROR = 5;
   */
  INTERNAL_SERVER_ERROR = 5,

  /**
   * @generated from enum value: PAGE_FETCH_ERROR_UNKNOWN_STATUS_CODE = 6;
   */
  UNKNOWN_STATUS_CODE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(PageFetchError)
proto3.util.setEnumType(PageFetchError, "nozzle.urls.v1.PageFetchError", [
  { no: 0, name: "PAGE_FETCH_ERROR_UNSPECIFIED" },
  { no: 1, name: "PAGE_FETCH_ERROR_NOT_FOUND" },
  { no: 2, name: "PAGE_FETCH_ERROR_NO_CONTENT" },
  { no: 3, name: "PAGE_FETCH_ERROR_FORBIDDEN" },
  { no: 4, name: "PAGE_FETCH_ERROR_UNAUTHORIZED" },
  { no: 5, name: "PAGE_FETCH_ERROR_INTERNAL_SERVER_ERROR" },
  { no: 6, name: "PAGE_FETCH_ERROR_UNKNOWN_STATUS_CODE" },
]);

/**
 * RequestPageRequest is the request for RequestPage.
 *
 * @generated from message nozzle.urls.v1.RequestPageRequest
 */
export class RequestPageRequest extends Message<RequestPageRequest> {
  /**
   * @generated from field: int64 domain_id = 1;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: int64 url_id = 2;
   */
  urlId = protoInt64.zero;

  /**
   * @generated from field: string url = 7;
   */
  url = "";

  /**
   * @generated from field: bool include_entities = 3;
   */
  includeEntities = false;

  /**
   * @generated from field: bool include_categories = 4;
   */
  includeCategories = false;

  /**
   * @generated from field: bool include_lighthouse = 5;
   */
  includeLighthouse = false;

  /**
   * @generated from field: bool wait_for_completion = 6;
   */
  waitForCompletion = false;

  constructor(data?: PartialMessage<RequestPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.RequestPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "include_entities", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "include_categories", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "include_lighthouse", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "wait_for_completion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestPageRequest {
    return new RequestPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestPageRequest {
    return new RequestPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestPageRequest {
    return new RequestPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestPageRequest | PlainMessage<RequestPageRequest> | undefined, b: RequestPageRequest | PlainMessage<RequestPageRequest> | undefined): boolean {
    return proto3.util.equals(RequestPageRequest, a, b);
  }
}

/**
 * RequestPageResponse is the response for RequestPage.
 *
 * @generated from message nozzle.urls.v1.RequestPageResponse
 */
export class RequestPageResponse extends Message<RequestPageResponse> {
  /**
   * @generated from field: google.longrunning.Operation operation = 1;
   */
  operation?: Operation;

  /**
   * @generated from field: nozzle.urls.v1.Page page = 2;
   */
  page?: Page;

  constructor(data?: PartialMessage<RequestPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.RequestPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operation", kind: "message", T: Operation },
    { no: 2, name: "page", kind: "message", T: Page },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestPageResponse {
    return new RequestPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestPageResponse {
    return new RequestPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestPageResponse {
    return new RequestPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestPageResponse | PlainMessage<RequestPageResponse> | undefined, b: RequestPageResponse | PlainMessage<RequestPageResponse> | undefined): boolean {
    return proto3.util.equals(RequestPageResponse, a, b);
  }
}

/**
 * BatchRequestPageRequest is the request for BatchRequestPage.
 *
 * @generated from message nozzle.urls.v1.BatchRequestPageRequest
 */
export class BatchRequestPageRequest extends Message<BatchRequestPageRequest> {
  /**
   * @generated from field: repeated nozzle.urls.v1.RequestPageRequest requests = 1;
   */
  requests: RequestPageRequest[] = [];

  /**
   * wait_for_completion will override the wait settings on each request
   *
   * @generated from field: bool wait_for_completion = 6;
   */
  waitForCompletion = false;

  constructor(data?: PartialMessage<BatchRequestPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.BatchRequestPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: RequestPageRequest, repeated: true },
    { no: 6, name: "wait_for_completion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchRequestPageRequest {
    return new BatchRequestPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchRequestPageRequest {
    return new BatchRequestPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchRequestPageRequest {
    return new BatchRequestPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchRequestPageRequest | PlainMessage<BatchRequestPageRequest> | undefined, b: BatchRequestPageRequest | PlainMessage<BatchRequestPageRequest> | undefined): boolean {
    return proto3.util.equals(BatchRequestPageRequest, a, b);
  }
}

/**
 * BatchRequestPageResponse is the response for BatchRequestPage.
 *
 * @generated from message nozzle.urls.v1.BatchRequestPageResponse
 */
export class BatchRequestPageResponse extends Message<BatchRequestPageResponse> {
  /**
   * @generated from field: google.longrunning.Operation operation = 1;
   */
  operation?: Operation;

  /**
   * @generated from field: repeated nozzle.urls.v1.Page pages = 2;
   */
  pages: Page[] = [];

  constructor(data?: PartialMessage<BatchRequestPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.BatchRequestPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operation", kind: "message", T: Operation },
    { no: 2, name: "pages", kind: "message", T: Page, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchRequestPageResponse {
    return new BatchRequestPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchRequestPageResponse {
    return new BatchRequestPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchRequestPageResponse {
    return new BatchRequestPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchRequestPageResponse | PlainMessage<BatchRequestPageResponse> | undefined, b: BatchRequestPageResponse | PlainMessage<BatchRequestPageResponse> | undefined): boolean {
    return proto3.util.equals(BatchRequestPageResponse, a, b);
  }
}

/**
 * GetPageRequest is the request for GetPage.
 *
 * @generated from message nozzle.urls.v1.GetPageRequest
 */
export class GetPageRequest extends Message<GetPageRequest> {
  /**
   * @generated from field: int64 domain_id = 1;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: int64 page_id = 2;
   */
  pageId = protoInt64.zero;

  /**
   * @generated from field: int64 url_id = 3;
   */
  urlId = protoInt64.zero;

  constructor(data?: PartialMessage<GetPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.GetPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPageRequest {
    return new GetPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPageRequest {
    return new GetPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPageRequest {
    return new GetPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPageRequest | PlainMessage<GetPageRequest> | undefined, b: GetPageRequest | PlainMessage<GetPageRequest> | undefined): boolean {
    return proto3.util.equals(GetPageRequest, a, b);
  }
}

/**
 * GetPageResponse is the response for GetPage.
 *
 * @generated from message nozzle.urls.v1.GetPageResponse
 */
export class GetPageResponse extends Message<GetPageResponse> {
  /**
   * @generated from field: nozzle.urls.v1.Page page = 1;
   */
  page?: Page;

  constructor(data?: PartialMessage<GetPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.GetPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page", kind: "message", T: Page },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPageResponse {
    return new GetPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPageResponse {
    return new GetPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPageResponse {
    return new GetPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPageResponse | PlainMessage<GetPageResponse> | undefined, b: GetPageResponse | PlainMessage<GetPageResponse> | undefined): boolean {
    return proto3.util.equals(GetPageResponse, a, b);
  }
}

/**
 * BatchGetPageRequest is the request for BatchGetPage.
 *
 * @generated from message nozzle.urls.v1.BatchGetPageRequest
 */
export class BatchGetPageRequest extends Message<BatchGetPageRequest> {
  /**
   * @generated from field: repeated nozzle.urls.v1.GetPageRequest requests = 1;
   */
  requests: GetPageRequest[] = [];

  constructor(data?: PartialMessage<BatchGetPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.BatchGetPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: GetPageRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetPageRequest {
    return new BatchGetPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetPageRequest {
    return new BatchGetPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetPageRequest {
    return new BatchGetPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetPageRequest | PlainMessage<BatchGetPageRequest> | undefined, b: BatchGetPageRequest | PlainMessage<BatchGetPageRequest> | undefined): boolean {
    return proto3.util.equals(BatchGetPageRequest, a, b);
  }
}

/**
 * BatchGetPageResponse is the response for BatchGetPage.
 *
 * @generated from message nozzle.urls.v1.BatchGetPageResponse
 */
export class BatchGetPageResponse extends Message<BatchGetPageResponse> {
  /**
   * @generated from field: repeated nozzle.urls.v1.Page pages = 1;
   */
  pages: Page[] = [];

  constructor(data?: PartialMessage<BatchGetPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.BatchGetPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pages", kind: "message", T: Page, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetPageResponse {
    return new BatchGetPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetPageResponse {
    return new BatchGetPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetPageResponse {
    return new BatchGetPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetPageResponse | PlainMessage<BatchGetPageResponse> | undefined, b: BatchGetPageResponse | PlainMessage<BatchGetPageResponse> | undefined): boolean {
    return proto3.util.equals(BatchGetPageResponse, a, b);
  }
}

/**
 * Urls are important
 *
 * @generated from message nozzle.urls.v1.Url
 */
export class Url extends Message<Url> {
  /**
   * Url
   *
   * @generated from field: int64 url_id = 1;
   */
  urlId = protoInt64.zero;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * Host
   *
   * @generated from field: int64 host_id = 3;
   */
  hostId = protoInt64.zero;

  /**
   * @generated from field: string host = 4;
   */
  host = "";

  /**
   * Domain
   *
   * @generated from field: int64 domain_id = 5;
   */
  domainId = protoInt64.zero;

  /**
   * @generated from field: string domain = 6;
   */
  domain = "";

  constructor(data?: PartialMessage<Url>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Url";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "host_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "host", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "domain_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Url {
    return new Url().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Url {
    return new Url().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Url {
    return new Url().fromJsonString(jsonString, options);
  }

  static equals(a: Url | PlainMessage<Url> | undefined, b: Url | PlainMessage<Url> | undefined): boolean {
    return proto3.util.equals(Url, a, b);
  }
}

/**
 * Page contains data for a page.
 *
 * @generated from message nozzle.urls.v1.Page
 */
export class Page extends Message<Page> {
  /**
   * @generated from field: int64 page_id = 27;
   */
  pageId = protoInt64.zero;

  /**
   * @generated from field: nozzle.urls.v1.Url url = 1;
   */
  url?: Url;

  /**
   * @generated from field: string language_code = 2;
   */
  languageCode = "";

  /**
   * @generated from field: string content_text = 3;
   */
  contentText = "";

  /**
   * @generated from field: string content_html = 4;
   */
  contentHtml = "";

  /**
   * @generated from field: string title = 5;
   */
  title = "";

  /**
   * @generated from field: string description = 6;
   */
  description = "";

  /**
   * @generated from field: string site_name = 7;
   */
  siteName = "";

  /**
   * @generated from field: nozzle.urls.v1.Robots robots = 18;
   */
  robots?: Robots;

  /**
   * @generated from field: nozzle.urls.v1.Url canonical_url = 19;
   */
  canonicalUrl?: Url;

  /**
   * @generated from field: bytes schema = 20;
   */
  schema = new Uint8Array(0);

  /**
   * @generated from field: repeated nozzle.urls.v1.Heading headings = 8;
   */
  headings: Heading[] = [];

  /**
   * @generated from field: repeated nozzle.urls.v1.Link links = 9;
   */
  links: Link[] = [];

  /**
   * @generated from field: repeated nozzle.urls.v1.Image images = 10;
   */
  images: Image[] = [];

  /**
   * @generated from field: repeated nozzle.urls.v1.BoldWord bold_words = 11;
   */
  boldWords: BoldWord[] = [];

  /**
   * @generated from field: repeated nozzle.urls.v1.ItalicizedWord italicized_words = 12;
   */
  italicizedWords: ItalicizedWord[] = [];

  /**
   * @generated from field: string refresh_redirect = 22;
   */
  refreshRedirect = "";

  /**
   * @generated from field: string charset = 23;
   */
  charset = "";

  /**
   * @generated from field: string viewport = 24;
   */
  viewport = "";

  /**
   * @generated from field: repeated nozzle.urls.v1.Entity entities = 14;
   */
  entities: Entity[] = [];

  /**
   * @generated from field: repeated nozzle.urls.v1.ClassificationCategory categories = 15;
   */
  categories: ClassificationCategory[] = [];

  /**
   * @generated from field: nozzle.urls.v1.TextStats text_stats = 16;
   */
  textStats?: TextStats;

  /**
   * @generated from field: nozzle.urls.v1.OpenGraph open_graph = 21;
   */
  openGraph?: OpenGraph;

  /**
   * @generated from field: repeated nozzle.urls.v1.Technology technologies = 25;
   */
  technologies: Technology[] = [];

  /**
   * @generated from field: nozzle.urls.v1.PageFetchType fetch_type = 26;
   */
  fetchType = PageFetchType.UNSPECIFIED;

  /**
   * Lighthouse
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult lighthouse = 13;
   */
  lighthouse?: LighthouseResult;

  /**
   * @generated from field: nozzle.urls.v1.PageFetchError page_fetch_error = 28;
   */
  pageFetchError = PageFetchError.UNSPECIFIED;

  constructor(data?: PartialMessage<Page>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Page";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 27, name: "page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "url", kind: "message", T: Url },
    { no: 2, name: "language_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content_html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "site_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "robots", kind: "message", T: Robots },
    { no: 19, name: "canonical_url", kind: "message", T: Url },
    { no: 20, name: "schema", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 8, name: "headings", kind: "message", T: Heading, repeated: true },
    { no: 9, name: "links", kind: "message", T: Link, repeated: true },
    { no: 10, name: "images", kind: "message", T: Image, repeated: true },
    { no: 11, name: "bold_words", kind: "message", T: BoldWord, repeated: true },
    { no: 12, name: "italicized_words", kind: "message", T: ItalicizedWord, repeated: true },
    { no: 22, name: "refresh_redirect", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "charset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "viewport", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "entities", kind: "message", T: Entity, repeated: true },
    { no: 15, name: "categories", kind: "message", T: ClassificationCategory, repeated: true },
    { no: 16, name: "text_stats", kind: "message", T: TextStats },
    { no: 21, name: "open_graph", kind: "message", T: OpenGraph },
    { no: 25, name: "technologies", kind: "message", T: Technology, repeated: true },
    { no: 26, name: "fetch_type", kind: "enum", T: proto3.getEnumType(PageFetchType) },
    { no: 13, name: "lighthouse", kind: "message", T: LighthouseResult },
    { no: 28, name: "page_fetch_error", kind: "enum", T: proto3.getEnumType(PageFetchError) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Page {
    return new Page().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJsonString(jsonString, options);
  }

  static equals(a: Page | PlainMessage<Page> | undefined, b: Page | PlainMessage<Page> | undefined): boolean {
    return proto3.util.equals(Page, a, b);
  }
}

/**
 * Heading is info for a heading tag.
 *
 * @generated from message nozzle.urls.v1.Heading
 */
export class Heading extends Message<Heading> {
  /**
   * @generated from field: int32 level = 1;
   */
  level = 0;

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  constructor(data?: PartialMessage<Heading>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Heading";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Heading {
    return new Heading().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Heading {
    return new Heading().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Heading {
    return new Heading().fromJsonString(jsonString, options);
  }

  static equals(a: Heading | PlainMessage<Heading> | undefined, b: Heading | PlainMessage<Heading> | undefined): boolean {
    return proto3.util.equals(Heading, a, b);
  }
}

/**
 * BoldWord is a word that was bolded.
 *
 * @generated from message nozzle.urls.v1.BoldWord
 */
export class BoldWord extends Message<BoldWord> {
  /**
   * @generated from field: string word = 1;
   */
  word = "";

  constructor(data?: PartialMessage<BoldWord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.BoldWord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "word", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BoldWord {
    return new BoldWord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BoldWord {
    return new BoldWord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BoldWord {
    return new BoldWord().fromJsonString(jsonString, options);
  }

  static equals(a: BoldWord | PlainMessage<BoldWord> | undefined, b: BoldWord | PlainMessage<BoldWord> | undefined): boolean {
    return proto3.util.equals(BoldWord, a, b);
  }
}

/**
 * ItalicizedWord is a word that was bolded.
 *
 * @generated from message nozzle.urls.v1.ItalicizedWord
 */
export class ItalicizedWord extends Message<ItalicizedWord> {
  /**
   * @generated from field: string word = 1;
   */
  word = "";

  constructor(data?: PartialMessage<ItalicizedWord>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.ItalicizedWord";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "word", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ItalicizedWord {
    return new ItalicizedWord().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ItalicizedWord {
    return new ItalicizedWord().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ItalicizedWord {
    return new ItalicizedWord().fromJsonString(jsonString, options);
  }

  static equals(a: ItalicizedWord | PlainMessage<ItalicizedWord> | undefined, b: ItalicizedWord | PlainMessage<ItalicizedWord> | undefined): boolean {
    return proto3.util.equals(ItalicizedWord, a, b);
  }
}

/**
 * Paragraph is a paragraph of text.
 *
 * @generated from message nozzle.urls.v1.Paragraph
 */
export class Paragraph extends Message<Paragraph> {
  /**
   * @generated from field: string text = 1;
   */
  text = "";

  constructor(data?: PartialMessage<Paragraph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Paragraph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Paragraph {
    return new Paragraph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Paragraph {
    return new Paragraph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Paragraph {
    return new Paragraph().fromJsonString(jsonString, options);
  }

  static equals(a: Paragraph | PlainMessage<Paragraph> | undefined, b: Paragraph | PlainMessage<Paragraph> | undefined): boolean {
    return proto3.util.equals(Paragraph, a, b);
  }
}

/**
 * Image contains data for an image.
 *
 * @generated from message nozzle.urls.v1.Image
 */
export class Image extends Message<Image> {
  /**
   * link contains a Title/URL combo and data related to the link.
   *
   * @generated from field: nozzle.urls.v1.Link link = 1;
   */
  link?: Link;

  /**
   * src is what was found in the src attribute of the img tag. Omitted if it's the base64 representation.
   *
   * @generated from field: string src = 2;
   */
  src = "";

  /**
   * alt is what was found in the alt attribute of the img tag.
   *
   * @generated from field: string alt = 3;
   */
  alt = "";

  /**
   * is_embedded indicates the image was embedded in the img tag, rather than just linked to.
   *
   * @generated from field: bool is_embedded = 4;
   */
  isEmbedded = false;

  constructor(data?: PartialMessage<Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link", kind: "message", T: Link },
    { no: 2, name: "src", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "alt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_embedded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Image {
    return new Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJsonString(jsonString, options);
  }

  static equals(a: Image | PlainMessage<Image> | undefined, b: Image | PlainMessage<Image> | undefined): boolean {
    return proto3.util.equals(Image, a, b);
  }
}

/**
 * Link contains data for a link.
 *
 * @generated from message nozzle.urls.v1.Link
 */
export class Link extends Message<Link> {
  /**
   * anchor_text contains the anchor_text of the Link.
   *
   * @generated from field: string anchor_text = 1;
   */
  anchorText = "";

  /**
   * url contains the url data of the link.
   *
   * @generated from field: nozzle.urls.v1.Url url = 2;
   */
  url?: Url;

  /**
   * is_relative indicates the url was found as a relative link version of the link (/images, /maps, etc).
   *
   * @generated from field: bool is_relative = 3;
   */
  isRelative = false;

  /**
   * length is the number of characters in the url.
   *
   * @generated from field: int64 length = 4;
   */
  length = protoInt64.zero;

  /**
   * is_https indicates the Link for the Result is secure.
   *
   * @generated from field: bool is_https = 5;
   */
  isHttps = false;

  /**
   * is_external indicates the link goes to an external site.
   *
   * @generated from field: bool is_external = 6;
   */
  isExternal = false;

  constructor(data?: PartialMessage<Link>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Link";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "anchor_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "message", T: Url },
    { no: 3, name: "is_relative", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "length", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "is_https", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_external", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Link {
    return new Link().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Link {
    return new Link().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Link {
    return new Link().fromJsonString(jsonString, options);
  }

  static equals(a: Link | PlainMessage<Link> | undefined, b: Link | PlainMessage<Link> | undefined): boolean {
    return proto3.util.equals(Link, a, b);
  }
}

/**
 * TextStats contains stats for the text on a page.
 *
 * @generated from message nozzle.urls.v1.TextStats
 */
export class TextStats extends Message<TextStats> {
  /**
   * @generated from field: int32 heading_count = 1;
   */
  headingCount = 0;

  /**
   * @generated from field: int32 paragraph_count = 2;
   */
  paragraphCount = 0;

  /**
   * @generated from field: int32 sentence_count = 3;
   */
  sentenceCount = 0;

  /**
   * @generated from field: int32 word_count = 4;
   */
  wordCount = 0;

  /**
   * @generated from field: int32 letter_count = 5;
   */
  letterCount = 0;

  /**
   * @generated from field: int32 punctuation_count = 6;
   */
  punctuationCount = 0;

  /**
   * @generated from field: int32 syllable_count = 7;
   */
  syllableCount = 0;

  /**
   * @generated from field: int32 space_count = 8;
   */
  spaceCount = 0;

  /**
   * @generated from field: int32 difficult_word_count = 9;
   */
  difficultWordCount = 0;

  /**
   * @generated from field: int32 bold_word_count = 10;
   */
  boldWordCount = 0;

  /**
   * @generated from field: int32 italicized_word_count = 11;
   */
  italicizedWordCount = 0;

  /**
   * @generated from field: int32 image_count = 12;
   */
  imageCount = 0;

  /**
   * @generated from field: int32 total_link_count = 13;
   */
  totalLinkCount = 0;

  /**
   * @generated from field: int32 internal_link_count = 14;
   */
  internalLinkCount = 0;

  /**
   * @generated from field: int32 external_link_count = 15;
   */
  externalLinkCount = 0;

  /**
   * @generated from field: float automated_readability_index = 16;
   */
  automatedReadabilityIndex = 0;

  /**
   * @generated from field: float coleman_liau_index = 17;
   */
  colemanLiauIndex = 0;

  /**
   * @generated from field: float dale_chall_readability_score = 18;
   */
  daleChallReadabilityScore = 0;

  /**
   * @generated from field: float flesch_kincaid_reading_ease = 19;
   */
  fleschKincaidReadingEase = 0;

  /**
   * @generated from field: float flesch_kincaid_grade_level = 20;
   */
  fleschKincaidGradeLevel = 0;

  /**
   * @generated from field: float gunning_fog_score = 21;
   */
  gunningFogScore = 0;

  /**
   * @generated from field: float smog_index = 22;
   */
  smogIndex = 0;

  /**
   * @generated from field: nozzle.urls.v1.GradeLevel reading_ease = 23;
   */
  readingEase = GradeLevel.UNSPECIFIED;

  constructor(data?: PartialMessage<TextStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.TextStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "heading_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "paragraph_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sentence_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "word_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "letter_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "punctuation_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "syllable_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "space_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "difficult_word_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "bold_word_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "italicized_word_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "image_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "total_link_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "internal_link_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "external_link_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "automated_readability_index", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: "coleman_liau_index", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 18, name: "dale_chall_readability_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "flesch_kincaid_reading_ease", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 20, name: "flesch_kincaid_grade_level", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 21, name: "gunning_fog_score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 22, name: "smog_index", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 23, name: "reading_ease", kind: "enum", T: proto3.getEnumType(GradeLevel) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextStats {
    return new TextStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextStats {
    return new TextStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextStats {
    return new TextStats().fromJsonString(jsonString, options);
  }

  static equals(a: TextStats | PlainMessage<TextStats> | undefined, b: TextStats | PlainMessage<TextStats> | undefined): boolean {
    return proto3.util.equals(TextStats, a, b);
  }
}

/**
 * OpenGraph contains page open graph data.
 *
 * @generated from message nozzle.urls.v1.OpenGraph
 */
export class OpenGraph extends Message<OpenGraph> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * @generated from field: string image = 5;
   */
  image = "";

  /**
   * @generated from field: string locale = 6;
   */
  locale = "";

  constructor(data?: PartialMessage<OpenGraph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.OpenGraph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenGraph {
    return new OpenGraph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenGraph {
    return new OpenGraph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenGraph {
    return new OpenGraph().fromJsonString(jsonString, options);
  }

  static equals(a: OpenGraph | PlainMessage<OpenGraph> | undefined, b: OpenGraph | PlainMessage<OpenGraph> | undefined): boolean {
    return proto3.util.equals(OpenGraph, a, b);
  }
}

/**
 * Robots contains data for a robots tag.
 *
 * @generated from message nozzle.urls.v1.Robots
 */
export class Robots extends Message<Robots> {
  /**
   * @generated from field: bool all = 1;
   */
  all = false;

  /**
   * @generated from field: bool no_index = 2;
   */
  noIndex = false;

  /**
   * @generated from field: bool no_follow = 3;
   */
  noFollow = false;

  /**
   * @generated from field: bool none = 4;
   */
  none = false;

  /**
   * @generated from field: bool no_archive = 5;
   */
  noArchive = false;

  /**
   * @generated from field: bool no_snippet = 6;
   */
  noSnippet = false;

  /**
   * @generated from field: bool max_snippet = 7;
   */
  maxSnippet = false;

  /**
   * @generated from field: bool max_image_preview = 8;
   */
  maxImagePreview = false;

  /**
   * @generated from field: bool max_video_preview = 9;
   */
  maxVideoPreview = false;

  /**
   * @generated from field: bool no_translate = 10;
   */
  noTranslate = false;

  /**
   * @generated from field: bool no_image_index = 11;
   */
  noImageIndex = false;

  /**
   * @generated from field: bool unavailable_after = 12;
   */
  unavailableAfter = false;

  /**
   * @generated from field: bool no_site_link_search_box = 13;
   */
  noSiteLinkSearchBox = false;

  /**
   * @generated from field: bool index_if_embedded = 14;
   */
  indexIfEmbedded = false;

  constructor(data?: PartialMessage<Robots>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Robots";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "all", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "no_index", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "no_follow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "none", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "no_archive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "no_snippet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "max_snippet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "max_image_preview", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "max_video_preview", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "no_translate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "no_image_index", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "unavailable_after", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "no_site_link_search_box", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "index_if_embedded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Robots {
    return new Robots().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Robots {
    return new Robots().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Robots {
    return new Robots().fromJsonString(jsonString, options);
  }

  static equals(a: Robots | PlainMessage<Robots> | undefined, b: Robots | PlainMessage<Robots> | undefined): boolean {
    return proto3.util.equals(Robots, a, b);
  }
}

/**
 * Technology represents a wappalyzer technology.
 *
 * @generated from message nozzle.urls.v1.Technology
 */
export class Technology extends Message<Technology> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string version = 3;
   */
  version = "";

  /**
   * @generated from field: string icon = 4;
   */
  icon = "";

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: repeated nozzle.urls.v1.TechnologyCategory categories = 6;
   */
  categories: TechnologyCategory[] = [];

  /**
   * @generated from field: int64 confidence = 7;
   */
  confidence = protoInt64.zero;

  constructor(data?: PartialMessage<Technology>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.Technology";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "categories", kind: "message", T: TechnologyCategory, repeated: true },
    { no: 7, name: "confidence", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Technology {
    return new Technology().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Technology {
    return new Technology().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Technology {
    return new Technology().fromJsonString(jsonString, options);
  }

  static equals(a: Technology | PlainMessage<Technology> | undefined, b: Technology | PlainMessage<Technology> | undefined): boolean {
    return proto3.util.equals(Technology, a, b);
  }
}

/**
 * TechnologyCategory represents a wappalyzer technology category.
 *
 * @generated from message nozzle.urls.v1.TechnologyCategory
 */
export class TechnologyCategory extends Message<TechnologyCategory> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 priority = 3;
   */
  priority = protoInt64.zero;

  constructor(data?: PartialMessage<TechnologyCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.TechnologyCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "priority", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TechnologyCategory {
    return new TechnologyCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TechnologyCategory {
    return new TechnologyCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TechnologyCategory {
    return new TechnologyCategory().fromJsonString(jsonString, options);
  }

  static equals(a: TechnologyCategory | PlainMessage<TechnologyCategory> | undefined, b: TechnologyCategory | PlainMessage<TechnologyCategory> | undefined): boolean {
    return proto3.util.equals(TechnologyCategory, a, b);
  }
}

