import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import { useActiveWorkspaceId } from '../hooks/workspaces'
import { brandTypeOptions } from '../options/brandTypeOptions'
import { sortBy } from '../utils'
import {
  deleteBrand,
  fetchBrand,
  fetchBrands,
  onBrandsChangeSubscription,
  patchBrand,
  postBrand,
} from '../utils/Api'
import {
  BRAND_PROPERTY_TYPE_DOMAIN,
  BRAND_PROPERTY_TYPE_FACEBOOK,
  BRAND_PROPERTY_TYPE_INSTAGRAM,
  BRAND_PROPERTY_TYPE_LINKEDIN,
  BRAND_PROPERTY_TYPE_PINTEREST,
  BRAND_PROPERTY_TYPE_SUBDOMAIN,
  BRAND_PROPERTY_TYPE_TWITTER,
  BRAND_PROPERTY_TYPE_URL,
  BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
  queryKeyBrands,
} from '../utils/Constants'
import useErrorPopup from './useErrorPopup'
import { useQuerySubscription } from './useQuerySubscription'
import { useSearch } from '@tanstack/react-router'
import { useActiveProjectIdState } from '../utils/searchParams'
import { Brand, BrandProperty } from '../../openapi'
import { propertyTypes } from '../containers/brands/BrandForm'

export function useBrand({
  id,
  workspaceId,
  enabled = true,
}: {
  id: undefined | string
  workspaceId?: string
  enabled?: boolean
}) {
  const activeWorkspaceId = useActiveWorkspaceId()

  workspaceId = workspaceId ?? activeWorkspaceId

  useQuerySubscription({
    kind: queryKeyBrands,
    label: 'Brand',
    subscriber: onBrandsChangeSubscription,
  })

  return useQuery(
    [queryKeyBrands, id, workspaceId],
    () => fetchBrand(id!, workspaceId!),
    {
      enabled: enabled && !!(workspaceId && id),
    }
  )
}

export function useBrands({
  projectId,
  workspaceId,
  enabled = true,
  staleTime,
}: {
  projectId: undefined | string
  workspaceId?: string
  enabled?: boolean
  staleTime?: number
}) {
  const activeWorkspaceId = useActiveWorkspaceId()

  workspaceId = workspaceId ?? activeWorkspaceId

  useQuerySubscription({
    kind: queryKeyBrands,
    label: 'Brand',
    subscriber: onBrandsChangeSubscription,
  })

  return useQuery(
    [queryKeyBrands, projectId, workspaceId],
    () => fetchBrands(projectId!, workspaceId!),
    {
      enabled: enabled && !!(workspaceId && projectId),
      staleTime,
    }
  )
}

export function useRemoveBrandById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  return useMutation(deleteBrand, {
    onSuccess: async () => {
      toast({
        message: 'Brand deleted',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyBrands)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to remove brand.')
    },
  })
}

export function useSaveBrand(options: { silent?: boolean } = {}) {
  const workspaceId = useActiveWorkspaceId()
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(
    newBrand =>
      patchBrand({
        workspaceId,
        ...newBrand,
      }),
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async data => {
        if (!options.silent) {
          toast({
            message: 'Brand saved',
            color: 'green-500',
          })
        }
        await queryClient.invalidateQueries(queryKeyBrands)
        queryClient.setQueryData([queryKeyBrands, data.id], data)
      },
      onError: err => {
        console.error(err)
        if (!options.silent) {
          errorPopup('Failed to save brand.')
        }
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return mutateAsync
}

//

export function useCreateBrand(options: { silent?: boolean } = {}) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(postBrand, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async () => {
      if (!options?.silent) {
        toast({
          message: 'Brand created',
          color: 'green-500',
        })
      }
      await queryClient.invalidateQueries(queryKeyBrands)
    },
    onError: err => {
      console.error(err)
      if (!options) {
        errorPopup('Failed to create brand.')
      }
    },
    onSettled: () => {
      setLoading(false)
    },
  })

  return mutateAsync
}

export function useBrandOptionsQuery({ enabled = true } = {}) {
  const projectId = useActiveProjectIdState().state

  const brandsQuery = useBrands({
    projectId,
    enabled,
  })

  // Build the focus options
  const data = React.useMemo(
    () =>
      (brandsQuery.data || [])
        .map(brand => ({
          value: String(brand.id),
          label: brand.name,
          brand,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        ),
    [brandsQuery.data]
  )

  return { ...brandsQuery, data }
}

export function useBrandTypeOptionsQuery(opts?: { enabled?: boolean }) {
  const { enabled } = opts ?? {}
  const projectId = useActiveProjectIdState().state
  const brandsQuery = useBrands({ projectId, enabled })

  // Build the focus options
  const data = React.useMemo(() => {
    const options = brandTypeOptions.map(option => ({
      ...option,
      brands: [],
    }))

    ;(brandsQuery.data ?? []).forEach(brand => {
      const option = options.find(d => d.value === brand.type)

      option?.brands.push(brand)
    })

    return options
      .filter(d => d.brands.length)
      .map(d => ({
        ...d,
        label: `${d.label} - ${d.brands
          .slice(0, 3)
          // @ts-expect-error  // Property 'name' does not exist on type 'never'.
          .map(brand => brand.name)
          .join(', ')}${
          d.brands.length > 3 ? `, ${d.brands.length - 3} more...` : ''
        }`,
      }))
  }, [brandsQuery.data])

  return { ...brandsQuery, data }
}

export function useBrandPropertyOptionsQuery(opts?: { enabled?: boolean }) {
  const { enabled } = opts ?? {}
  const projectId = useActiveProjectIdState().state
  const brandsQuery = useBrands({ projectId, enabled })

  // Build the focus options
  const data = React.useMemo(() => {
    const properties: any = []

    sortBy(brandsQuery.data || [], d => d.name).forEach((brand: any) => {
      sortBy(brand.properties || [], d => d.name).forEach((property: any) => {
        properties.push({
          label: `${brand.name} - ${property.name || `(Unnamed Property)`}`,
          value: `${brand.id}__${property.name || ''}`,
          brand,
        })
      })
    })

    return properties
  }, [brandsQuery.data])

  return { ...brandsQuery, data }
}

// A function to get the property for a given url
export function getBrandPropertyFromUrl(
  brands: Brand[] | undefined,
  url: string
) {
  if (!url) {
    return
  }

  const lowerUrl = url.toLowerCase()
  let property: undefined | (BrandProperty & { brand: Brand })

  const brand = brands?.find(brand => {
    const p = brand.properties.find(property => {
      const lowerPropertyValue = property.value?.toLowerCase()

      if (property.type === BRAND_PROPERTY_TYPE_DOMAIN) {
        return lowerUrl.toLowerCase().includes(lowerPropertyValue)
      } else if (property.type === BRAND_PROPERTY_TYPE_SUBDOMAIN) {
        return lowerUrl.toLowerCase().includes(lowerPropertyValue)
      } else if (property.type === BRAND_PROPERTY_TYPE_URL) {
        return lowerUrl.startsWith(lowerPropertyValue)
      } else if (
        [
          BRAND_PROPERTY_TYPE_INSTAGRAM,
          BRAND_PROPERTY_TYPE_PINTEREST,
          BRAND_PROPERTY_TYPE_TWITTER,
          BRAND_PROPERTY_TYPE_FACEBOOK,
          BRAND_PROPERTY_TYPE_LINKEDIN,
          BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO,
        ].includes(property.type)
      ) {
        const foundPropertyType = propertyTypes.find(
          d => d.type === property.type
        )!

        const condition =
          foundPropertyType.createCondition?.(lowerPropertyValue)

        if (condition) {
          return lowerUrl.startsWith(condition.values?.[0])
        }
      }
    })

    if (p) {
      property = p as any
      return true
    }
  })

  if (property) {
    property.brand = brand!
  }

  return property
}
