export default {
  set,
  get,
}

let storage: Record<string, string> = {}

if (typeof document !== 'undefined') {
  storage = window.sessionStorage
}

function set<T>(key: string, value: T) {
  storage[key] = JSON.stringify(value)
  return value
}

function get<T>(key: string, defaultValue?: T): T {
  try {
    return JSON.parse(storage[key]!) as T
  } catch (e) {
    return defaultValue as T
  }
}

// Rollin'...
const rollKey = 'rollId'
const rollId = 1

if (typeof document !== 'undefined') {
  if (get(rollKey) !== rollId) {
    window.sessionStorage.clear()
    window.location.reload()
    set(rollKey, rollId)
  }
}
