import { createFileRoute } from '@tanstack/react-router'
import {
  ShareOfVoiceMetricsWidget,
  ShareOfVoiceTopWidget,
  ShareOfVoiceWinnersWidget,
  ShareOfVoiceLosersWidget,
  ShareOfVoiceRollupsWidget,
} from '../../../containers/Dashboards/ShareOfVoice'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/competitive-share-of-voice'
)({
  validateSearch: search =>
    search as {
      shareOfVoicedMetricsWidget?: ShareOfVoiceMetricsWidget
      shareOfVoiceTopWidget?: ShareOfVoiceTopWidget
      shareOfVoiceWinnersWidget?: ShareOfVoiceWinnersWidget
      shareOfVoiceLosersWidget?: ShareOfVoiceLosersWidget
      shareOfVoiceRollupsWidget?: ShareOfVoiceRollupsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        shareOfVoicedMetricsWidget: search.shareOfVoicedMetricsWidget,
        shareOfVoiceTopWidget: search.shareOfVoiceTopWidget,
        shareOfVoiceWinnersWidget: search.shareOfVoiceWinnersWidget,
        shareOfVoiceLosersWidget: search.shareOfVoiceLosersWidget,
        shareOfVoiceRollupsWidget: search.shareOfVoiceRollupsWidget,
      }
    },
  ],
})
