import { twMerge } from 'tailwind-merge'
import * as React from 'react'
import { FaArrowLeft, FaArrowRight, FaCheck, FaTimes } from 'react-icons/fa'
import useIsomorphicLayoutEffect from '../hooks/useIsomorphicLayoutEffect'
import { pick } from '../utils'
import Button from './Button'
import Loader from './Loader'
import NumberInput from './NumberInput'
import { NumberInputWrap } from './NumberInputWrap'

export type PagerProps = {
  limit: number
  size?: number
  offset: number
  setLimit: (limit: number) => void
  canPreviousPage?: boolean
  canNextPage?: boolean
  isLoading?: boolean
  compact?: boolean
} & (
  | {
      setOffset: (offset: number) => void
    }
  | {
      onPreviousPage?: (offset: number) => void
      onNextPage?: (offset: number) => void
    }
) &
  React.HTMLAttributes<HTMLDivElement>

export default function Pager(props_: PagerProps) {
  const [props, rest] = pick(props_, [
    'limit',
    'size',
    'offset',
    'setOffset',
    'onPreviousPage',
    'onNextPage',
    'setLimit',
    'canPreviousPage',
    'canNextPage',
    'isLoading',
    'compact',
  ])

  // Page Size
  const [limitInput, setLimitInput] = React.useState(props.limit)
  useIsomorphicLayoutEffect(() => {
    if (props.limit !== undefined) setLimitInput(props.limit)
  }, [props.limit])

  // Offset
  const [offsetInput, setOffsetInput] = React.useState(props.offset)
  const setOffset = (offset: number) => props.setOffset(offset)

  useIsomorphicLayoutEffect(() => {
    if (props.offset !== undefined) setOffsetInput(props.offset + 1)
  }, [props.offset])

  const submitOffset = (e?: React.KeyboardEvent) => {
    if (e?.preventDefault) e.preventDefault()
    setOffset(Number(offsetInput) - 1)
  }

  const submitLimit = (e?: React.KeyboardEvent) => {
    if (e?.preventDefault) e.preventDefault()
    const num = Number(limitInput)
    props.setLimit(Math.max(1, num))
  }

  const cancelOffset = () => setOffsetInput(props.offset + 1)
  const cancelSize = () => setLimitInput(props.limit)

  // return {

  const canPreviousPage =
    props.canPreviousPage !== undefined
      ? props.canPreviousPage
      : props.offset > 0

  const canNextPage =
    props.canNextPage !== undefined
      ? props.canNextPage
      : props.size !== undefined
      ? props.offset < Math.ceil(props.size / props.limit) - 1
      : true
  // }

  const onPreviousPage = () =>
    canPreviousPage &&
    (props.onPreviousPage
      ? props.onPreviousPage(props.offset - 1)
      : setOffset(props.offset - 1))

  const onNextPage = () =>
    canNextPage &&
    (props.onNextPage
      ? props.onNextPage(props.offset + 1)
      : setOffset(props.offset + 1))

  // Pages
  const totalPages =
    props.size === undefined ? 0 : Math.ceil(props.size / props.limit)

  return (
    <div
      disabled={rest.disabled}
      style={rest.style}
      className={twMerge(
        'flex flex-wrap items-center gap-1',
        props.compact && 'text-xs',
        rest.className
      )}
    >
      <form
        className="flex items-center gap-1"
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onKeyDown={e => {
          if (e.key === 'ArrowRight') {
            e.preventDefault()
            onNextPage()
          } else if (e.key === 'ArrowLeft') {
            e.preventDefault()
            onPreviousPage()
          } else if (e.key === 'ArrowUp') {
            e.preventDefault()
            setOffset(0)
          } else if (e.key === 'ArrowDown') {
            e.preventDefault()
            setOffset((totalPages ?? 1) - 1)
          }
        }}
      >
        <Button
          size={props.compact ? 'xs' : 'sm'}
          color={['gray-200', 'gray-700']}
          onClick={onPreviousPage}
          className={twMerge(
            `flex items-center gap-1 py-1.5`,
            !canPreviousPage && 'opacity-50'
          )}
        >
          <FaArrowLeft className="inline" /> Prev
        </Button>
        <Button
          size={props.compact ? 'xs' : 'sm'}
          color={['gray-200', 'gray-700']}
          onClick={onNextPage}
          className={twMerge(
            `flex items-center gap-1 py-1.5`,
            !canNextPage && 'opacity-50'
          )}
        >
          Next <FaArrowRight className="inline" />
        </Button>
      </form>
      {props.setOffset ? (
        <form
          className="ml-2 mr-2 flex items-center gap-1"
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            submitOffset()
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              submitOffset()
            }
          }}
        >
          <span className="flex items-center gap-2">
            Page
            <NumberInputWrap>
              <NumberInput
                min="1"
                max={`${totalPages}`}
                value={offsetInput}
                onChange={value => setOffsetInput(value!)}
                className="max-w-[60px]
              rounded-md border-b border-l-0 border-r-0 border-t-0 border-gray-500 border-opacity-30 bg-gray-500 bg-opacity-5 focus:bg-gray-500 focus:bg-opacity-10"
              />
            </NumberInputWrap>
          </span>
          {Number(offsetInput) - 1 !== props.offset ? (
            <>
              <Button
                size="xs"
                color="green-500"
                onClick={() => submitOffset()}
                className="py-1.5"
              >
                <FaCheck className="inline" />
              </Button>
              <Button
                size="xs"
                color="red-500"
                onClick={cancelOffset}
                className="py-1.5"
              >
                <FaTimes className="inline" />
              </Button>
            </>
          ) : null}
          {totalPages ? <div>of {totalPages}</div> : null}
        </form>
      ) : (
        <div className="ml-2 mr-2 flex items-center gap-1">
          Page {props.offset + 1}
        </div>
      )}
      <form
        className="flex items-center gap-1"
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          submitLimit()
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            submitLimit()
          }
        }}
      >
        <span className="flex items-center gap-2">
          <span>Show</span>
          <NumberInputWrap>
            <NumberInput
              value={limitInput}
              onChange={val => setLimitInput(val!)}
              placeholder="..."
              className="w-[60px]
               rounded-md border-b border-l-0 border-r-0 border-t-0 border-gray-500 border-opacity-30 bg-gray-500 bg-opacity-5 focus:bg-gray-500 focus:bg-opacity-10"
              min={1}
            />
          </NumberInputWrap>
        </span>
        {Number(limitInput) !== Number(props.limit) ? (
          <>
            <Button
              size="xs"
              color="green-500"
              onClick={() => {
                submitLimit()
              }}
              className="py-1.5"
            >
              <FaCheck className="inline" />
            </Button>
            <Button
              size="xs"
              color="red-500"
              onClick={cancelSize}
              className="py-1.5"
            >
              <FaTimes className="inline" />
            </Button>
          </>
        ) : null}
      </form>
      {props.isLoading ? (
        <div>
          <Loader color="gray-500" />
        </div>
      ) : null}
      <div className="flex-[9999 0 auto]" />
    </div>
  )
}
