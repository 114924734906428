export const reputationImpactOptions = [
  { label: 'Very Negative', value: -2 },
  { label: 'Negative', value: -1 },
  { label: 'Neutral', value: 0 },
  { label: 'Positive', value: 1 },
  { label: 'Very Positive', value: 2 },
]

export function formatReputationImpact(reputationImpact: number) {
  return (
    reputationImpactOptions.find(d => d.value === reputationImpact)?.label ??
    'N/A'
  )
}
