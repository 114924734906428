// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/coupon.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * CouponCodeState represents the current state of a coupon code.
 *
 * @generated from enum nozzle.billing.v1.CouponCodeState
 */
export enum CouponCodeState {
  /**
   * @generated from enum value: COUPON_CODE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Can be applied to a subscription.
   *
   * @generated from enum value: COUPON_CODE_STATE_NOT_REDEEMED = 1;
   */
  NOT_REDEEMED = 1,

  /**
   * Cannot be applied to a subscription as the coupon code has been already used.
   *
   * @generated from enum value: COUPON_CODE_STATE_REDEEMED = 2;
   */
  REDEEMED = 2,

  /**
   * Cannot be applied to a subscription as it has been made inactive.
   *
   * @generated from enum value: COUPON_CODE_STATE_ARCHIVED = 3;
   */
  ARCHIVED = 3,

  /**
   * Coupon code was not found
   *
   * @generated from enum value: COUPON_CODE_STATE_NOT_FOUND = 4;
   */
  NOT_FOUND = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(CouponCodeState)
proto3.util.setEnumType(CouponCodeState, "nozzle.billing.v1.CouponCodeState", [
  { no: 0, name: "COUPON_CODE_STATE_UNSPECIFIED" },
  { no: 1, name: "COUPON_CODE_STATE_NOT_REDEEMED" },
  { no: 2, name: "COUPON_CODE_STATE_REDEEMED" },
  { no: 3, name: "COUPON_CODE_STATE_ARCHIVED" },
  { no: 4, name: "COUPON_CODE_STATE_NOT_FOUND" },
]);

/**
 * Coupons are a superset of rewards. All rewards are expressed as coupons, but we will
 * also support coupons that are not directly tied to a reward, for marketing discounts,
 * for customer service, etc.
 *
 * @generated from message nozzle.billing.v1.Coupon
 */
export class Coupon extends Message<Coupon> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Coupon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Coupon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coupon {
    return new Coupon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coupon {
    return new Coupon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coupon {
    return new Coupon().fromJsonString(jsonString, options);
  }

  static equals(a: Coupon | PlainMessage<Coupon> | undefined, b: Coupon | PlainMessage<Coupon> | undefined): boolean {
    return proto3.util.equals(Coupon, a, b);
  }
}

/**
 * CouponCode is a coupon code that can be applied to a subscription.
 *
 * @generated from message nozzle.billing.v1.CouponCode
 */
export class CouponCode extends Message<CouponCode> {
  /**
   * @generated from field: string coupon_code = 1;
   */
  couponCode = "";

  /**
   * @generated from field: nozzle.billing.v1.CouponCodeState state = 2;
   */
  state = CouponCodeState.UNSPECIFIED;

  constructor(data?: PartialMessage<CouponCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CouponCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coupon_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(CouponCodeState) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CouponCode {
    return new CouponCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CouponCode {
    return new CouponCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CouponCode {
    return new CouponCode().fromJsonString(jsonString, options);
  }

  static equals(a: CouponCode | PlainMessage<CouponCode> | undefined, b: CouponCode | PlainMessage<CouponCode> | undefined): boolean {
    return proto3.util.equals(CouponCode, a, b);
  }
}

