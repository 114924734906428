import * as React from 'react'
import { splitFormProps, useField } from 'react-form'
import LabeledInput from '../components/LabeledInput'
import Validate from '../utils/Validate'

//

type Props = {
  onChange?: (e: any) => void
  field?: string
  label?: React.ReactNode
  placeholder?: string
  disabled?: boolean
  validate?: (e: any) => void
  autoFocus?: boolean
  filterValue?: (str?: string) => string
  autoComplete?: string
  onPaste?: (e: any) => void
  maxLength?: any
  inputProps?: any
  type?: any
  className?: string
  required?: boolean
  onBlur?: (e: any) => void
}

export default React.forwardRef(function TextField(
  { onChange, onBlur, ...props }: Props,
  ref
) {
  if (props.required) {
    props.validate = props.validate || Validate.required('A value is required')
  }

  const [field, formProps, rest] = splitFormProps(props)
  const {
    value,
    meta: { error, isTouched, isValidating },
    setValue,
    setMeta,
    // @ts-expect-error  // Argument of type '{ field?: string | undefined; la... Remove this comment to see the full error message
  } = useField(field, formProps)

  return (
    <LabeledInput
      ref={ref}
      onChange={e => {
        // @ts-expect-error  // Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
        setValue(e.target.value)
        if (onChange) {
          onChange(e)
        }
      }}
      onBlur={e => {
        setMeta({ isTouched: true })
        onBlur?.(e)
      }}
      value={value || ''}
      error={isTouched && error}
      isValidating={isValidating}
      {...rest}
    />
  )
})
