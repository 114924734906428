// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/invoice.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Date } from "../../../google/type/date_pb.js";
import { Money } from "../../../google/type/money_pb.js";

/**
 * InvoiceState represents the current state of an invoice.
 *
 * @generated from enum nozzle.billing.v1.InvoiceState
 */
export enum InvoiceState {
  /**
   * @generated from enum value: INVOICE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Indicates a paid invoice.
   *
   * @generated from enum value: INVOICE_STATE_PAID = 1;
   */
  PAID = 1,

  /**
   * Indicates the payment is not yet collected and will be in this state until
   * the due date to indicate the due period.
   *
   * @generated from enum value: INVOICE_STATE_POSTED = 2;
   */
  POSTED = 2,

  /**
   * Indicates the payment is not yet collected and is being retried as per retry settings.
   *
   * @generated from enum value: INVOICE_STATE_PAYMENT_DUE = 3;
   */
  PAYMENT_DUE = 3,

  /**
   * Indicates the payment is not made and all attempts to collect is failed.
   *
   * @generated from enum value: INVOICE_STATE_NOT_PAID = 4;
   */
  NOT_PAID = 4,

  /**
   * Indicates a voided invoice.
   *
   * @generated from enum value: INVOICE_STATE_VOIDED = 5;
   */
  VOIDED = 5,

  /**
   * The invoice is a draft and yet to be closed (sent for payment collection).
   *
   * @generated from enum value: INVOICE_STATE_PENDING = 6;
   */
  PENDING = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(InvoiceState)
proto3.util.setEnumType(InvoiceState, "nozzle.billing.v1.InvoiceState", [
  { no: 0, name: "INVOICE_STATE_UNSPECIFIED" },
  { no: 1, name: "INVOICE_STATE_PAID" },
  { no: 2, name: "INVOICE_STATE_POSTED" },
  { no: 3, name: "INVOICE_STATE_PAYMENT_DUE" },
  { no: 4, name: "INVOICE_STATE_NOT_PAID" },
  { no: 5, name: "INVOICE_STATE_VOIDED" },
  { no: 6, name: "INVOICE_STATE_PENDING" },
]);

/**
 * DunningState represents the current state of payment collection for an invoice.
 *
 * @generated from enum nozzle.billing.v1.DunningState
 */
export enum DunningState {
  /**
   * @generated from enum value: DUNNING_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Dunning is still in progress.
   *
   * @generated from enum value: DUNNING_STATE_IN_PROGRESS = 1;
   */
  IN_PROGRESS = 1,

  /**
   * Maximum number of attempts have been made.
   *
   * @generated from enum value: DUNNING_STATE_EXHAUSTED = 2;
   */
  EXHAUSTED = 2,

  /**
   * Dunning has stopped for this invoice.
   *
   * @generated from enum value: DUNNING_STATE_STOPPED = 3;
   */
  STOPPED = 3,

  /**
   * Payment successfully collected during dunning process.
   *
   * @generated from enum value: DUNNING_STATE_SUCCESS = 4;
   */
  SUCCESS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DunningState)
proto3.util.setEnumType(DunningState, "nozzle.billing.v1.DunningState", [
  { no: 0, name: "DUNNING_STATE_UNSPECIFIED" },
  { no: 1, name: "DUNNING_STATE_IN_PROGRESS" },
  { no: 2, name: "DUNNING_STATE_EXHAUSTED" },
  { no: 3, name: "DUNNING_STATE_STOPPED" },
  { no: 4, name: "DUNNING_STATE_SUCCESS" },
]);

/**
 * ListInvoicesRequest is the request for ListInvoices.
 *
 * @generated from message nozzle.billing.v1.ListInvoicesRequest
 */
export class ListInvoicesRequest extends Message<ListInvoicesRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  constructor(data?: PartialMessage<ListInvoicesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListInvoicesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInvoicesRequest {
    return new ListInvoicesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListInvoicesRequest | PlainMessage<ListInvoicesRequest> | undefined, b: ListInvoicesRequest | PlainMessage<ListInvoicesRequest> | undefined): boolean {
    return proto3.util.equals(ListInvoicesRequest, a, b);
  }
}

/**
 * ListInvoicesResponse is the response for ListInvoices.
 *
 * @generated from message nozzle.billing.v1.ListInvoicesResponse
 */
export class ListInvoicesResponse extends Message<ListInvoicesResponse> {
  /**
   * @generated from field: repeated nozzle.billing.v1.Invoice invoices = 1;
   */
  invoices: Invoice[] = [];

  constructor(data?: PartialMessage<ListInvoicesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ListInvoicesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoices", kind: "message", T: Invoice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListInvoicesResponse {
    return new ListInvoicesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListInvoicesResponse | PlainMessage<ListInvoicesResponse> | undefined, b: ListInvoicesResponse | PlainMessage<ListInvoicesResponse> | undefined): boolean {
    return proto3.util.equals(ListInvoicesResponse, a, b);
  }
}

/**
 * GetInvoiceRequest is the request for GetInvoice.
 *
 * @generated from message nozzle.billing.v1.GetInvoiceRequest
 */
export class GetInvoiceRequest extends Message<GetInvoiceRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: string invoice_id = 2;
   */
  invoiceId = "";

  constructor(data?: PartialMessage<GetInvoiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetInvoiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "invoice_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoiceRequest {
    return new GetInvoiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoiceRequest | PlainMessage<GetInvoiceRequest> | undefined, b: GetInvoiceRequest | PlainMessage<GetInvoiceRequest> | undefined): boolean {
    return proto3.util.equals(GetInvoiceRequest, a, b);
  }
}

/**
 * GetInvoiceResponse is the response for GetInvoice.
 *
 * @generated from message nozzle.billing.v1.GetInvoiceResponse
 */
export class GetInvoiceResponse extends Message<GetInvoiceResponse> {
  /**
   * @generated from field: nozzle.billing.v1.Invoice invoice = 1;
   */
  invoice?: Invoice;

  constructor(data?: PartialMessage<GetInvoiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetInvoiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoice", kind: "message", T: Invoice },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoiceResponse {
    return new GetInvoiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoiceResponse | PlainMessage<GetInvoiceResponse> | undefined, b: GetInvoiceResponse | PlainMessage<GetInvoiceResponse> | undefined): boolean {
    return proto3.util.equals(GetInvoiceResponse, a, b);
  }
}

/**
 * GetInvoicePdfRequest is the request for GetInvoicePdf.
 *
 * @generated from message nozzle.billing.v1.GetInvoicePdfRequest
 */
export class GetInvoicePdfRequest extends Message<GetInvoicePdfRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: string invoice_id = 2;
   */
  invoiceId = "";

  constructor(data?: PartialMessage<GetInvoicePdfRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetInvoicePdfRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "invoice_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoicePdfRequest {
    return new GetInvoicePdfRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoicePdfRequest {
    return new GetInvoicePdfRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoicePdfRequest {
    return new GetInvoicePdfRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoicePdfRequest | PlainMessage<GetInvoicePdfRequest> | undefined, b: GetInvoicePdfRequest | PlainMessage<GetInvoicePdfRequest> | undefined): boolean {
    return proto3.util.equals(GetInvoicePdfRequest, a, b);
  }
}

/**
 * GetInvoicePdfResponse is the response for GetInvoicePdf.
 *
 * @generated from message nozzle.billing.v1.GetInvoicePdfResponse
 */
export class GetInvoicePdfResponse extends Message<GetInvoicePdfResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: google.protobuf.Timestamp expires_at = 2;
   */
  expiresAt?: Timestamp;

  constructor(data?: PartialMessage<GetInvoicePdfResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetInvoicePdfResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expires_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInvoicePdfResponse {
    return new GetInvoicePdfResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInvoicePdfResponse {
    return new GetInvoicePdfResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInvoicePdfResponse {
    return new GetInvoicePdfResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInvoicePdfResponse | PlainMessage<GetInvoicePdfResponse> | undefined, b: GetInvoicePdfResponse | PlainMessage<GetInvoicePdfResponse> | undefined): boolean {
    return proto3.util.equals(GetInvoicePdfResponse, a, b);
  }
}

/**
 * Invoice represents a single invoice type.
 *
 * @generated from message nozzle.billing.v1.Invoice
 */
export class Invoice extends Message<Invoice> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: string invoice_id = 2;
   */
  invoiceId = "";

  /**
   * @generated from field: nozzle.billing.v1.InvoiceState state = 3;
   */
  state = InvoiceState.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.DunningState dunning_state = 4;
   */
  dunningState = DunningState.UNSPECIFIED;

  /**
   * @generated from field: google.type.Date date = 5;
   */
  date?: Date;

  /**
   * @generated from field: google.type.Date due_date = 6;
   */
  dueDate?: Date;

  /**
   * @generated from field: google.type.Date period_start = 7;
   */
  periodStart?: Date;

  /**
   * @generated from field: google.type.Date period_end = 8;
   */
  periodEnd?: Date;

  /**
   * @generated from field: google.type.Money subtotal = 9;
   */
  subtotal?: Money;

  /**
   * @generated from field: google.type.Money total = 10;
   */
  total?: Money;

  /**
   * @generated from field: google.type.Money tax = 11;
   */
  tax?: Money;

  /**
   * @generated from field: google.type.Money amount_due = 12;
   */
  amountDue?: Money;

  /**
   * @generated from field: string po_number = 13;
   */
  poNumber = "";

  /**
   * @generated from field: google.protobuf.Timestamp generated_at = 17;
   */
  generatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp next_retry_at = 14;
   */
  nextRetryAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp paid_at = 15;
   */
  paidAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp voided_at = 16;
   */
  voidedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 18;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Invoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Invoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "invoice_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(InvoiceState) },
    { no: 4, name: "dunning_state", kind: "enum", T: proto3.getEnumType(DunningState) },
    { no: 5, name: "date", kind: "message", T: Date },
    { no: 6, name: "due_date", kind: "message", T: Date },
    { no: 7, name: "period_start", kind: "message", T: Date },
    { no: 8, name: "period_end", kind: "message", T: Date },
    { no: 9, name: "subtotal", kind: "message", T: Money },
    { no: 10, name: "total", kind: "message", T: Money },
    { no: 11, name: "tax", kind: "message", T: Money },
    { no: 12, name: "amount_due", kind: "message", T: Money },
    { no: 13, name: "po_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "generated_at", kind: "message", T: Timestamp },
    { no: 14, name: "next_retry_at", kind: "message", T: Timestamp },
    { no: 15, name: "paid_at", kind: "message", T: Timestamp },
    { no: 16, name: "voided_at", kind: "message", T: Timestamp },
    { no: 18, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoice {
    return new Invoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoice {
    return new Invoice().fromJsonString(jsonString, options);
  }

  static equals(a: Invoice | PlainMessage<Invoice> | undefined, b: Invoice | PlainMessage<Invoice> | undefined): boolean {
    return proto3.util.equals(Invoice, a, b);
  }
}

