// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/ranking.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Keyword } from "../../keywords/v1/keywords_pb.js";

/**
 * RequestRankingRequest is the request for RequestRanking.
 *
 * @generated from message nozzle.workspaces.v1.RequestRankingRequest
 */
export class RequestRankingRequest extends Message<RequestRankingRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * This can be either a keyword id, or phrase + locale id + device
   *
   * @generated from field: nozzle.keywords.v1.Keyword keyword = 3;
   */
  keyword?: Keyword;

  constructor(data?: PartialMessage<RequestRankingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.RequestRankingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "keyword", kind: "message", T: Keyword },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestRankingRequest {
    return new RequestRankingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestRankingRequest {
    return new RequestRankingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestRankingRequest {
    return new RequestRankingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RequestRankingRequest | PlainMessage<RequestRankingRequest> | undefined, b: RequestRankingRequest | PlainMessage<RequestRankingRequest> | undefined): boolean {
    return proto3.util.equals(RequestRankingRequest, a, b);
  }
}

/**
 * RequestRankingResponse is the response for RequestRanking.
 *
 * @generated from message nozzle.workspaces.v1.RequestRankingResponse
 */
export class RequestRankingResponse extends Message<RequestRankingResponse> {
  /**
   * @generated from field: int64 ranking_id = 1;
   */
  rankingId = protoInt64.zero;

  constructor(data?: PartialMessage<RequestRankingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.RequestRankingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ranking_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RequestRankingResponse {
    return new RequestRankingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RequestRankingResponse {
    return new RequestRankingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RequestRankingResponse {
    return new RequestRankingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RequestRankingResponse | PlainMessage<RequestRankingResponse> | undefined, b: RequestRankingResponse | PlainMessage<RequestRankingResponse> | undefined): boolean {
    return proto3.util.equals(RequestRankingResponse, a, b);
  }
}

