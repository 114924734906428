import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceByKeywordGroupMetricsWidget,
  PerformanceByKeywordGroupTopWidget,
  PerformanceByKeywordGroupWinnersWidget,
  PerformanceByKeywordGroupLosersWidget,
  PerformanceByKeywordGroupKeywordGroupsWidget,
} from '../../../containers/Dashboards/PerformanceByKeywordGroup'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-by-keyword-group'
)({
  validateSearch: search =>
    search as {
      performanceByKeywordGroupMetricsWidget?: PerformanceByKeywordGroupMetricsWidget
      performanceByKeywordGroupTopWidget?: PerformanceByKeywordGroupTopWidget
      performanceByKeywordGroupWinnersWidget?: PerformanceByKeywordGroupWinnersWidget
      performanceByKeywordGroupLosersWidget?: PerformanceByKeywordGroupLosersWidget
      performanceByKeywordGroupKeywordGroupsWidget?: PerformanceByKeywordGroupKeywordGroupsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByKeywordGroupMetricsWidget:
          search.performanceByKeywordGroupMetricsWidget,
        performanceByKeywordGroupTopWidget:
          search.performanceByKeywordGroupTopWidget,
        performanceByKeywordGroupWinnersWidget:
          search.performanceByKeywordGroupWinnersWidget,
        performanceByKeywordGroupLosersWidget:
          search.performanceByKeywordGroupLosersWidget,
        performanceByKeywordGroupKeywordGroupsWidget:
          search.performanceByKeywordGroupKeywordGroupsWidget,
      }
    },
  ],
})
