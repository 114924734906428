// sidebarWidth: 42,
// sidebarFlyoutWidth: 200,
// filterbarWidth: 50,
// filterbarFlyoutWidth: 230,

export const DEFAULT_SAMPLES = 30

export const POST_AGGREGATION_VALUE = 'value'
export const POST_AGGREGATION_CHANGE = 'change'
export const NO_GROUP_NAME = '(No Group)'
export const NO_DEVICE_NAME = '(No Device)'
export const NO_LOCALE_NAME = '(No Locale)'

export const KEYWORD_SOURCE_TYPE_BASIC = 'basic'
export const KEYWORD_SOURCE_TYPE_CITY = 'city'
export const KEYWORD_SOURCE_TYPE_JSON = 'json'
export const KEYWORD_SOURCE_TYPE_TEMPLATE = 'template'
export const KEYWORD_SOURCE_TYPE_AUTOMOTIVE = 'templateAutomotive'

export const UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const BRAND_TYPE_OWNED = 'Owned'
export const BRAND_TYPE_FRIENDLY = 'Friendly'
export const BRAND_TYPE_NEUTRAL = 'Neutral'
export const BRAND_TYPE_COMPETITOR_DIRECT = 'Competitor (Direct)'
export const BRAND_TYPE_COMPETITOR_INDIRECT = 'Competitor (Indirect)'
export const BRAND_TYPE_COMPETITOR_REPLACEMENT = 'Competitor (Replacement)'
export const BRAND_TYPE_POSITIVE = 'Positive'
export const BRAND_TYPE_NEGATIVE = 'Negative'

export const BRAND_PROPERTY_TYPE_URL = 'BRAND_PROPERTY_TYPE_URL'
export const BRAND_PROPERTY_TYPE_SUBDOMAIN = 'BRAND_PROPERTY_TYPE_SUBDOMAIN'
export const BRAND_PROPERTY_TYPE_CUSTOM = 'BRAND_PROPERTY_TYPE_CUSTOM'
export const BRAND_PROPERTY_TYPE_DOMAIN = 'BRAND_PROPERTY_TYPE_DOMAIN'
export const BRAND_PROPERTY_TYPE_GOOGLE_BUSINESS_PROFILE =
  'BRAND_PROPERTY_TYPE_GOOGLE_MY_BUSINESS'
export const BRAND_PROPERTY_TYPE_FACEBOOK = 'BRAND_PROPERTY_TYPE_FACEBOOK'
export const BRAND_PROPERTY_TYPE_TWITTER = 'BRAND_PROPERTY_TYPE_TWITTER'
export const BRAND_PROPERTY_TYPE_INSTAGRAM = 'BRAND_PROPERTY_TYPE_INSTAGRAM'
export const BRAND_PROPERTY_TYPE_LINKEDIN = 'BRAND_PROPERTY_TYPE_LINKEDIN'
export const BRAND_PROPERTY_TYPE_PINTEREST = 'BRAND_PROPERTY_TYPE_PINTEREST'
export const BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL =
  'BRAND_PROPERTY_TYPE_YOUTUBE_CHANNEL'
export const BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO =
  'BRAND_PROPERTY_TYPE_YOUTUBE_VIDEO'
export const BRAND_PROPERTY_TYPE_AMAZON_STOREFRONT =
  'BRAND_PROPERTY_TYPE_AMAZON_STOREFRONT'
export const BRAND_PROPERTY_TYPE_WALMART_MARKETPLACE =
  'BRAND_PROPERTY_TYPE_WALMART_MARKETPLACE'
export const BRAND_PROPERTY_TYPE_EBAY_STORE = 'BRAND_PROPERTY_TYPE_EBAY_STORE'
export const BRAND_PROPERTY_TYPE_OWLER = 'BRAND_PROPERTY_TYPE_OWLER'
export const BRAND_PROPERTY_TYPE_FOURSQUARE = 'BRAND_PROPERTY_TYPE_FOURSQUARE'
export const BRAND_PROPERTY_TYPE_ANGELLIST = 'BRAND_PROPERTY_TYPE_ANGELLIST'
export const BRAND_PROPERTY_TYPE_CRUNCHBASE = 'BRAND_PROPERTY_TYPE_CRUNCHBASE'

export const QUICK_OPTION_PRIORITY_IMMEDIATE_ACTION = 1
export const QUICK_OPTION_PRIORITY_SECONDARY_ACTION = 2
export const QUICK_OPTION_PRIORITY_DASHBOARDS = 3
export const QUICK_OPTION_PRIORITY_MENU_ITEM = 4
export const QUICK_OPTION_PRIORITY_SWITCH_PROJECT = 5
export const QUICK_OPTION_PRIORITY_PROJECTS = 6
export const QUICK_OPTION_PRIORITY_SWITCH_WORKSPACE = 7
export const QUICK_OPTION_PRIORITY_WORKSPACE = 8
export const QUICK_OPTION_PRIORITY_UTILITY = 9
export const QUICK_OPTION_PRIORITY_LOG_OUT = 10

export type QuickOptionPriority =
  | typeof QUICK_OPTION_PRIORITY_IMMEDIATE_ACTION
  | typeof QUICK_OPTION_PRIORITY_SECONDARY_ACTION
  | typeof QUICK_OPTION_PRIORITY_DASHBOARDS
  | typeof QUICK_OPTION_PRIORITY_MENU_ITEM
  | typeof QUICK_OPTION_PRIORITY_SWITCH_PROJECT
  | typeof QUICK_OPTION_PRIORITY_PROJECTS
  | typeof QUICK_OPTION_PRIORITY_SWITCH_WORKSPACE
  | typeof QUICK_OPTION_PRIORITY_WORKSPACE
  | typeof QUICK_OPTION_PRIORITY_UTILITY
  | typeof QUICK_OPTION_PRIORITY_LOG_OUT

export const queryKeyProfile = 'profile' as const
export const queryKeyAllKeywords = 'allKeywords' as const
export const queryKeyKeywordsFacets = 'keywordsFacets' as const
export const queryKeyApiTokens = 'apiTokens' as const
export const queryKeyAccount = 'account' as const
export const queryKeyBillingCards = 'billingCards' as const
export const queryKeyBillingPlans = 'billingPlans' as const
export const queryKeyBillingPublicPlans = 'billingPublicPlans' as const
export const queryKeyBrands = 'brands' as const
export const queryKeyColumns = 'columns' as const
export const queryKeyKeywordPulls = 'keyword-pulls' as const
export const queryKeyKeywordSources = 'keywordSources' as const
export const queryKeyQueryTimeSeries = 'query-time-series' as const
export const queryKeyReportsKeywordSerp = 'reports-keyword-serp' as const
export const queryKeyReportsKeywordSerpHtml =
  'reports-keyword-serp-html' as const
export const queryKeySegments = 'segments' as const
export const queryKeyTeams = 'teams' as const
export const queryKeyTeamsKeywordSources = 'teamsKeywordSources' as const
export const queryKeyUsage = 'usage' as const
export const queryKeyUsers = 'users' as const
export const queryKeyWorkspaces = 'workspaces' as const
export const queryKeyReportsTop = 'reports-top' as const
export const queryKeyYouTubeDomains = 'youtubeDomains' as const
export const queryKeyInvoices = 'invoices' as const
export const queryKeyInvoice = 'invoice' as const
export const queryKeyRewards = 'rewards' as const
export const queryKeyGoogleSites = 'googleSites' as const
export const queryKeyGooglePhrases = 'googlePhrases' as const
export const queryKeyAccuranker = 'accuranker' as const
export const queryKeyAccurankerDomains = 'accurankerDomains' as const
export const queryKeyAccurankerKeywords = 'accurankerKeywords' as const
export const queryKeyAccurankerBrands = 'accurankerBrands' as const
export const queryKeyAccurankerLocales = 'accurankerLocales' as const
export const queryKeyRankWatchProjects = 'rankWatchProjects' as const
export const queryKeyRankWatchLocales = 'rankWatchLocales' as const
export const queryKeyClustersViz = 'clusters-viz' as const
export const queryKeyClusters = 'clusters' as const
export const queryKeyClusterFacets = 'cluster-facets' as const
export const queryKeyPages = 'pages' as const
export const queryKeyMonitoredUrlsFacets = 'monitored-url-facets' as const
export const queryKeyTopicsFacets = 'topic-facets' as const

export const KeywordGroupAllKeywords = '- All Keywords -'

// Dashboard Slugs
export const performanceByKeywordSlug = 'performance-by-keyword'
export const performanceByKeywordGroupSlug = 'performance-by-keyword-group'
export const performanceBySegmentSlug = 'performance-by-segment'
export const performanceByUrlSlug = 'performance-by-url'
export const performanceOverviewSlug = 'performance-overview'
export const performanceSingleKeywordSlug = 'performance-single-keyword'
export const serpFeaturedSnippetsSlug = 'features-snippets'
export const competitiveShareOfVoiceSlug = 'competitive-share-of-voice'
export const reputationManagementKeywordSlug = 'reputation-management-keyword'
