import { formatOption } from '../utils/Format'

export const countryOptions = [
  {
    label: 'Afghanistan',
    value: 'af',
    // alpha2Code: 'af',
    alpha3Code: 'afg',
    // numericCode: 4,
    // ccTld: 'af',
  },
  {
    label: 'Albania',
    value: 'al',
    // alpha2Code: 'al',
    alpha3Code: 'alb',
    // numericCode: 8,
    // ccTld: 'al',
  },
  {
    label: 'Antarctica',
    value: 'aq',
    // alpha2Code: 'aq',
    alpha3Code: 'ata',
    // numericCode: 10,
    // ccTld: 'aq',
  },
  {
    label: 'Algeria',
    value: 'dz',
    // alpha2Code: 'dz',
    alpha3Code: 'dza',
    // numericCode: 12,
    // ccTld: 'dz',
  },
  {
    label: 'American Samoa',
    value: 'as',
    // alpha2Code: 'as',
    alpha3Code: 'asm',
    // numericCode: 16,
    // ccTld: 'as',
  },
  {
    label: 'Andorra',
    value: 'ad',
    // alpha2Code: 'ad',
    alpha3Code: 'and',
    // numericCode: 20,
    // ccTld: 'ad',
  },
  {
    label: 'Angola',
    value: 'ao',
    // alpha2Code: 'ao',
    alpha3Code: 'ago',
    // numericCode: 24,
    // ccTld: 'ao',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'ag',
    // alpha2Code: 'ag',
    alpha3Code: 'atg',
    // numericCode: 28,
    // ccTld: 'ag',
  },
  {
    label: 'Azerbaijan',
    value: 'az',
    // alpha2Code: 'az',
    alpha3Code: 'aze',
    // numericCode: 31,
    // ccTld: 'az',
  },
  {
    label: 'Argentina',
    value: 'ar',
    // alpha2Code: 'ar',
    alpha3Code: 'arg',
    // numericCode: 32,
    // ccTld: 'ar',
  },
  {
    label: 'Australia',
    value: 'au',
    // alpha2Code: 'au',
    alpha3Code: 'aus',
    // numericCode: 36,
    // ccTld: 'au',
  },
  {
    label: 'Austria',
    value: 'at',
    // alpha2Code: 'at',
    alpha3Code: 'aut',
    // numericCode: 40,
    // ccTld: 'at',
  },
  {
    label: 'Bahamas',
    value: 'bs',
    // alpha2Code: 'bs',
    alpha3Code: 'bhs',
    // numericCode: 44,
    // ccTld: 'bs',
  },
  {
    label: 'Bahrain',
    value: 'bh',
    // alpha2Code: 'bh',
    alpha3Code: 'bhr',
    // numericCode: 48,
    // ccTld: 'bh',
  },
  {
    label: 'Bangladesh',
    value: 'bd',
    // alpha2Code: 'bd',
    alpha3Code: 'bgd',
    // numericCode: 50,
    // ccTld: 'bd',
  },
  {
    label: 'Armenia',
    value: 'am',
    // alpha2Code: 'am',
    alpha3Code: 'arm',
    // numericCode: 51,
    // ccTld: 'am',
  },
  {
    label: 'Barbados',
    value: 'bb',
    // alpha2Code: 'bb',
    alpha3Code: 'brb',
    // numericCode: 52,
    // ccTld: 'bb',
  },
  {
    label: 'Belgium',
    value: 'be',
    // alpha2Code: 'be',
    alpha3Code: 'bel',
    // numericCode: 56,
    // ccTld: 'be',
  },
  {
    label: 'Bermuda',
    value: 'bm',
    // alpha2Code: 'bm',
    alpha3Code: 'bmu',
    // numericCode: 60,
    // ccTld: 'bm',
  },
  {
    label: 'Bhutan',
    value: 'bt',
    // alpha2Code: 'bt',
    alpha3Code: 'btn',
    // numericCode: 64,
    // ccTld: 'bt',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'bo',
    // alpha2Code: 'bo',
    alpha3Code: 'bol',
    // numericCode: 68,
    // ccTld: 'bo',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'ba',
    // alpha2Code: 'ba',
    alpha3Code: 'bih',
    // numericCode: 70,
    // ccTld: 'ba',
  },
  {
    label: 'Botswana',
    value: 'bw',
    // alpha2Code: 'bw',
    alpha3Code: 'bwa',
    // numericCode: 72,
    // ccTld: 'bw',
  },
  {
    label: 'Bouvet Island',
    value: 'bv',
    // alpha2Code: 'bv',
    alpha3Code: 'bvt',
    // numericCode: 74,
    // ccTld: '',
  },
  {
    label: 'Brazil',
    value: 'br',
    // alpha2Code: 'br',
    alpha3Code: 'bra',
    // numericCode: 76,
    // ccTld: 'br',
  },
  {
    label: 'Belize',
    value: 'bz',
    // alpha2Code: 'bz',
    alpha3Code: 'blz',
    // numericCode: 84,
    // ccTld: 'bz',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'io',
    // alpha2Code: 'io',
    alpha3Code: 'iot',
    // numericCode: 86,
    // ccTld: 'io',
  },
  {
    label: 'Solomon Islands',
    value: 'sb',
    // alpha2Code: 'sb',
    alpha3Code: 'slb',
    // numericCode: 90,
    // ccTld: 'sb',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'vg',
    // alpha2Code: 'vg',
    alpha3Code: 'vgb',
    // numericCode: 92,
    // ccTld: 'vg',
  },
  {
    label: 'Brunei Darussalam',
    value: 'bn',
    // alpha2Code: 'bn',
    alpha3Code: 'brn',
    // numericCode: 96,
    // ccTld: 'bn',
  },
  {
    label: 'Bulgaria',
    value: 'bg',
    // alpha2Code: 'bg',
    alpha3Code: 'bgr',
    // numericCode: 100,
    // ccTld: 'bg',
  },
  {
    label: 'Myanmar',
    value: 'mm',
    // alpha2Code: 'mm',
    alpha3Code: 'mmr',
    // numericCode: 104,
    // ccTld: 'mm',
  },
  {
    label: 'Burundi',
    value: 'bi',
    // alpha2Code: 'bi',
    alpha3Code: 'bdi',
    // numericCode: 108,
    // ccTld: 'bi',
  },
  {
    label: 'Belarus',
    value: 'by',
    // alpha2Code: 'by',
    alpha3Code: 'blr',
    // numericCode: 112,
    // ccTld: 'by',
  },
  {
    label: 'Cambodia',
    value: 'kh',
    // alpha2Code: 'kh',
    alpha3Code: 'khm',
    // numericCode: 116,
    // ccTld: 'kh',
  },
  {
    label: 'Cameroon',
    value: 'cm',
    // alpha2Code: 'cm',
    alpha3Code: 'cmr',
    // numericCode: 120,
    // ccTld: 'cm',
  },
  {
    label: 'Canada',
    value: 'ca',
    // alpha2Code: 'ca',
    alpha3Code: 'can',
    // numericCode: 124,
    // ccTld: 'ca',
  },
  {
    label: 'Cabo Verde',
    value: 'cv',
    // alpha2Code: 'cv',
    alpha3Code: 'cpv',
    // numericCode: 132,
    // ccTld: 'cv',
  },
  {
    label: 'Cayman Islands',
    value: 'ky',
    // alpha2Code: 'ky',
    alpha3Code: 'cym',
    // numericCode: 136,
    // ccTld: 'ky',
  },
  {
    label: 'Central African Republic',
    value: 'cf',
    // alpha2Code: 'cf',
    alpha3Code: 'caf',
    // numericCode: 140,
    // ccTld: 'cf',
  },
  {
    label: 'Sri Lanka',
    value: 'lk',
    // alpha2Code: 'lk',
    alpha3Code: 'lka',
    // numericCode: 144,
    // ccTld: 'lk',
  },
  {
    label: 'Chad',
    value: 'td',
    // alpha2Code: 'td',
    alpha3Code: 'tcd',
    // numericCode: 148,
    // ccTld: 'td',
  },
  {
    label: 'Chile',
    value: 'cl',
    // alpha2Code: 'cl',
    alpha3Code: 'chl',
    // numericCode: 152,
    // ccTld: 'cl',
  },
  // {
  //   label: 'China',
  //   value: 'cn',
  //   // alpha2Code: 'cn',
  //   alpha3Code: 'chn',
  //   // numericCode: 156,
  //   // ccTld: 'cn',
  // },
  {
    label: 'Taiwan',
    value: 'cx',
    // alpha2Code: 'cx',
    alpha3Code: 'twn',
    // numericCode: 158,
    // ccTld: 'cx',
  },
  {
    label: 'Christmas Island',
    value: 'cx',
    // alpha2Code: 'cx',
    alpha3Code: 'cxr',
    // numericCode: 162,
    // ccTld: 'cx',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'cc',
    // alpha2Code: 'cc',
    alpha3Code: 'cck',
    // numericCode: 166,
    // ccTld: 'cc',
  },
  {
    label: 'Colombia',
    value: 'co',
    // alpha2Code: 'co',
    alpha3Code: 'col',
    // numericCode: 170,
    // ccTld: 'co',
  },
  {
    label: 'Comoros',
    value: 'km',
    // alpha2Code: 'km',
    alpha3Code: 'com',
    // numericCode: 174,
    // ccTld: 'km',
  },
  {
    label: 'Mayotte',
    value: 'yt',
    // alpha2Code: 'yt',
    alpha3Code: 'myt',
    // numericCode: 175,
    // ccTld: 'yt',
  },
  {
    label: 'Congo',
    value: 'cg',
    // alpha2Code: 'cg',
    alpha3Code: 'cog',
    // numericCode: 178,
    // ccTld: 'cg',
  },
  {
    label: 'Congo (the Democratic Republic of the)',
    value: 'cd',
    // alpha2Code: 'cd',
    alpha3Code: 'cod',
    // numericCode: 180,
    // ccTld: 'cd',
  },
  {
    label: 'Cook Islands',
    value: 'ck',
    // alpha2Code: 'ck',
    alpha3Code: 'cok',
    // numericCode: 184,
    // ccTld: 'ck',
  },
  {
    label: 'Costa Rica',
    value: 'cr',
    // alpha2Code: 'cr',
    alpha3Code: 'cri',
    // numericCode: 188,
    // ccTld: 'cr',
  },
  {
    label: 'Croatia',
    value: 'hr',
    // alpha2Code: 'hr',
    alpha3Code: 'hrv',
    // numericCode: 191,
    // ccTld: 'hr',
  },
  {
    label: 'Cuba',
    value: 'cu',
    // alpha2Code: 'cu',
    alpha3Code: 'cub',
    // numericCode: 192,
    // ccTld: 'cu',
  },
  {
    label: 'Cyprus',
    value: 'cy',
    // alpha2Code: 'cy',
    alpha3Code: 'cyp',
    // numericCode: 196,
    // ccTld: 'cy',
  },
  {
    label: 'Czechia',
    value: 'cz',
    // alpha2Code: 'cz',
    alpha3Code: 'cze',
    // numericCode: 203,
    // ccTld: 'cz',
  },
  {
    label: 'Benin',
    value: 'bj',
    // alpha2Code: 'bj',
    alpha3Code: 'ben',
    // numericCode: 204,
    // ccTld: 'bj',
  },
  {
    label: 'Denmark',
    value: 'dk',
    // alpha2Code: 'dk',
    alpha3Code: 'dnk',
    // numericCode: 208,
    // ccTld: 'dk',
  },
  {
    label: 'Dominica',
    value: 'dm',
    // alpha2Code: 'dm',
    alpha3Code: 'dma',
    // numericCode: 212,
    // ccTld: 'dm',
  },
  {
    label: 'Dominican Republic',
    value: 'do',
    // alpha2Code: 'do',
    alpha3Code: 'dom',
    // numericCode: 214,
    // ccTld: 'do',
  },
  {
    label: 'Ecuador',
    value: 'ec',
    // alpha2Code: 'ec',
    alpha3Code: 'ecu',
    // numericCode: 218,
    // ccTld: 'ec',
  },
  {
    label: 'El Salvador',
    value: 'sv',
    // alpha2Code: 'sv',
    alpha3Code: 'slv',
    // numericCode: 222,
    // ccTld: 'sv',
  },
  {
    label: 'Equatorial Guinea',
    value: 'gq',
    // alpha2Code: 'gq',
    alpha3Code: 'gnq',
    // numericCode: 226,
    // ccTld: 'gq',
  },
  {
    label: 'Ethiopia',
    value: 'et',
    // alpha2Code: 'et',
    alpha3Code: 'eth',
    // numericCode: 231,
    // ccTld: 'et',
  },
  {
    label: 'Eritrea',
    value: 'er',
    // alpha2Code: 'er',
    alpha3Code: 'eri',
    // numericCode: 232,
    // ccTld: 'er',
  },
  {
    label: 'Estonia',
    value: 'ee',
    // alpha2Code: 'ee',
    alpha3Code: 'est',
    // numericCode: 233,
    // ccTld: 'ee',
  },
  {
    label: 'Faroe Islands',
    value: 'fo',
    // alpha2Code: 'fo',
    alpha3Code: 'fro',
    // numericCode: 234,
    // ccTld: 'fo',
  },
  {
    label: 'Falkland Islands',
    value: 'fk',
    // alpha2Code: 'fk',
    alpha3Code: 'flk',
    // numericCode: 238,
    // ccTld: 'fk',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'gs',
    // alpha2Code: 'gs',
    alpha3Code: 'sgs',
    // numericCode: 239,
    // ccTld: 'gs',
  },
  {
    label: 'Fiji',
    value: 'fj',
    // alpha2Code: 'fj',
    alpha3Code: 'fji',
    // numericCode: 242,
    // ccTld: 'fj',
  },
  {
    label: 'Finland',
    value: 'fi',
    // alpha2Code: 'fi',
    alpha3Code: 'fin',
    // numericCode: 246,
    // ccTld: 'fi',
  },
  {
    label: 'Åland Islands',
    value: 'ax',
    // alpha2Code: 'ax',
    alpha3Code: 'ala',
    // numericCode: 248,
    // ccTld: 'ax',
  },
  {
    label: 'France',
    value: 'fr',
    // alpha2Code: 'fr',
    alpha3Code: 'fra',
    // numericCode: 250,
    // ccTld: 'fr',
  },
  {
    label: 'French Guiana',
    value: 'gf',
    // alpha2Code: 'gf',
    alpha3Code: 'guf',
    // numericCode: 254,
    // ccTld: 'gf',
  },
  {
    label: 'French Polynesia',
    value: 'pf',
    // alpha2Code: 'pf',
    alpha3Code: 'pyf',
    // numericCode: 258,
    // ccTld: 'pf',
  },
  {
    label: 'French Southern Territories',
    value: 'tf',
    // alpha2Code: 'tf',
    alpha3Code: 'atf',
    // numericCode: 260,
    // ccTld: 'tf',
  },
  {
    label: 'Djibouti',
    value: 'dj',
    // alpha2Code: 'dj',
    alpha3Code: 'dji',
    // numericCode: 262,
    // ccTld: 'dj',
  },
  {
    label: 'Gabon',
    value: 'ga',
    // alpha2Code: 'ga',
    alpha3Code: 'gab',
    // numericCode: 266,
    // ccTld: 'ga',
  },
  {
    label: 'Georgia',
    value: 'ge',
    // alpha2Code: 'ge',
    alpha3Code: 'geo',
    // numericCode: 268,
    // ccTld: 'ge',
  },
  {
    label: 'Gambia',
    value: 'gm',
    // alpha2Code: 'gm',
    alpha3Code: 'gmb',
    // numericCode: 270,
    // ccTld: 'gm',
  },
  {
    label: 'Palestine, State of',
    value: 'ps',
    // alpha2Code: 'ps',
    alpha3Code: 'pse',
    // numericCode: 275,
    // ccTld: 'ps',
  },
  {
    label: 'Germany',
    value: 'de',
    // alpha2Code: 'de',
    alpha3Code: 'deu',
    // numericCode: 276,
    // ccTld: 'de',
  },
  {
    label: 'Ghana',
    value: 'gh',
    // alpha2Code: 'gh',
    alpha3Code: 'gha',
    // numericCode: 288,
    // ccTld: 'gh',
  },
  {
    label: 'Gibraltar',
    value: 'gi',
    // alpha2Code: 'gi',
    alpha3Code: 'gib',
    // numericCode: 292,
    // ccTld: 'gi',
  },
  {
    label: 'Kiribati',
    value: 'ki',
    // alpha2Code: 'ki',
    alpha3Code: 'kir',
    // numericCode: 296,
    // ccTld: 'ki',
  },
  {
    label: 'Greece',
    value: 'gr',
    // alpha2Code: 'gr',
    alpha3Code: 'grc',
    // numericCode: 300,
    // ccTld: 'gr',
  },
  {
    label: 'Greenland',
    value: 'gl',
    // alpha2Code: 'gl',
    alpha3Code: 'grl',
    // numericCode: 304,
    // ccTld: 'gl',
  },
  {
    label: 'Grenada',
    value: 'gd',
    // alpha2Code: 'gd',
    alpha3Code: 'grd',
    // numericCode: 308,
    // ccTld: 'gd',
  },
  {
    label: 'Guadeloupe',
    value: 'gp',
    // alpha2Code: 'gp',
    alpha3Code: 'glp',
    // numericCode: 312,
    // ccTld: 'gp',
  },
  {
    label: 'Guam',
    value: 'gu',
    // alpha2Code: 'gu',
    alpha3Code: 'gum',
    // numericCode: 316,
    // ccTld: 'gu',
  },
  {
    label: 'Guatemala',
    value: 'gt',
    // alpha2Code: 'gt',
    alpha3Code: 'gtm',
    // numericCode: 320,
    // ccTld: 'gt',
  },
  {
    label: 'Guinea',
    value: 'gn',
    // alpha2Code: 'gn',
    alpha3Code: 'gin',
    // numericCode: 324,
    // ccTld: 'gn',
  },
  {
    label: 'Guyana',
    value: 'gy',
    // alpha2Code: 'gy',
    alpha3Code: 'guy',
    // numericCode: 328,
    // ccTld: 'gy',
  },
  {
    label: 'Haiti',
    value: 'ht',
    // alpha2Code: 'ht',
    alpha3Code: 'hti',
    // numericCode: 332,
    // ccTld: 'ht',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'hm',
    // alpha2Code: 'hm',
    alpha3Code: 'hmd',
    // numericCode: 334,
    // ccTld: 'hm',
  },
  {
    label: 'Holy See',
    value: 'va',
    // alpha2Code: 'va',
    alpha3Code: 'vat',
    // numericCode: 336,
    // ccTld: 'va',
  },
  {
    label: 'Honduras',
    value: 'hn',
    // alpha2Code: 'hn',
    alpha3Code: 'hnd',
    // numericCode: 340,
    // ccTld: 'hn',
  },
  {
    label: 'Hong Kong',
    value: 'hk',
    // alpha2Code: 'hk',
    alpha3Code: 'hkg',
    // numericCode: 344,
    // ccTld: 'hk',
  },
  {
    label: 'Hungary',
    value: 'hu',
    // alpha2Code: 'hu',
    alpha3Code: 'hun',
    // numericCode: 348,
    // ccTld: 'hu',
  },
  {
    label: 'Iceland',
    value: 'is',
    // alpha2Code: 'is',
    alpha3Code: 'isl',
    // numericCode: 352,
    // ccTld: 'is',
  },
  {
    label: 'India',
    value: 'in',
    // alpha2Code: 'in',
    alpha3Code: 'ind',
    // numericCode: 356,
    // ccTld: 'in',
  },
  {
    label: 'Indonesia',
    value: 'id',
    // alpha2Code: 'id',
    alpha3Code: 'idn',
    // numericCode: 360,
    // ccTld: 'id',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'ir',
    // alpha2Code: 'ir',
    alpha3Code: 'irn',
    // numericCode: 364,
    // ccTld: 'ir',
  },
  {
    label: 'Iraq',
    value: 'iq',
    // alpha2Code: 'iq',
    alpha3Code: 'irq',
    // numericCode: 368,
    // ccTld: 'iq',
  },
  {
    label: 'Ireland',
    value: 'ie',
    // alpha2Code: 'ie',
    alpha3Code: 'irl',
    // numericCode: 372,
    // ccTld: 'ie',
  },
  {
    label: 'Israel',
    value: 'il',
    // alpha2Code: 'il',
    alpha3Code: 'isr',
    // numericCode: 376,
    // ccTld: 'il',
  },
  {
    label: 'Italy',
    value: 'it',
    // alpha2Code: 'it',
    alpha3Code: 'ita',
    // numericCode: 380,
    // ccTld: 'it',
  },
  {
    label: "Côte d'Ivoire",
    value: 'ci',
    // alpha2Code: 'ci',
    alpha3Code: 'civ',
    // numericCode: 384,
    // ccTld: 'ci',
  },
  {
    label: 'Jamaica',
    value: 'jm',
    // alpha2Code: 'jm',
    alpha3Code: 'jam',
    // numericCode: 388,
    // ccTld: 'jm',
  },
  {
    label: 'Japan',
    value: 'jp',
    // alpha2Code: 'jp',
    alpha3Code: 'jpn',
    // numericCode: 392,
    // ccTld: 'jp',
  },
  {
    label: 'Kazakhstan',
    value: 'kz',
    // alpha2Code: 'kz',
    alpha3Code: 'kaz',
    // numericCode: 398,
    // ccTld: 'kz',
  },
  {
    label: 'Jordan',
    value: 'jo',
    // alpha2Code: 'jo',
    alpha3Code: 'jor',
    // numericCode: 400,
    // ccTld: 'jo',
  },
  {
    label: 'Kenya',
    value: 'ke',
    // alpha2Code: 'ke',
    alpha3Code: 'ken',
    // numericCode: 404,
    // ccTld: 'ke',
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    value: 'kp',
    // alpha2Code: 'kp',
    alpha3Code: 'prk',
    // numericCode: 408,
    // ccTld: 'kp',
  },
  {
    label: 'Korea (the Republic of)',
    value: 'kr',
    // alpha2Code: 'kr',
    alpha3Code: 'kor',
    // numericCode: 410,
    // ccTld: 'kr',
  },
  {
    label: 'Kuwait',
    value: 'kw',
    // alpha2Code: 'kw',
    alpha3Code: 'kwt',
    // numericCode: 414,
    // ccTld: 'kw',
  },
  {
    label: 'Kyrgyzstan',
    value: 'kg',
    // alpha2Code: 'kg',
    alpha3Code: 'kgz',
    // numericCode: 417,
    // ccTld: 'kg',
  },
  {
    label: "Lao People's Democratic Republic (the)",
    value: 'la',
    // alpha2Code: 'la',
    alpha3Code: 'lao',
    // numericCode: 418,
    // ccTld: 'la',
  },
  {
    label: 'Lebanon',
    value: 'lb',
    // alpha2Code: 'lb',
    alpha3Code: 'lbn',
    // numericCode: 422,
    // ccTld: 'lb',
  },
  {
    label: 'Lesotho',
    value: 'ls',
    // alpha2Code: 'ls',
    alpha3Code: 'lso',
    // numericCode: 426,
    // ccTld: 'ls',
  },
  {
    label: 'Latvia',
    value: 'lv',
    // alpha2Code: 'lv',
    alpha3Code: 'lva',
    // numericCode: 428,
    // ccTld: 'lv',
  },
  {
    label: 'Liberia',
    value: 'lr',
    // alpha2Code: 'lr',
    alpha3Code: 'lbr',
    // numericCode: 430,
    // ccTld: 'lr',
  },
  {
    label: 'Libya',
    value: 'ly',
    // alpha2Code: 'ly',
    alpha3Code: 'lby',
    // numericCode: 434,
    // ccTld: 'ly',
  },
  {
    label: 'Liechtenstein',
    value: 'li',
    // alpha2Code: 'li',
    alpha3Code: 'lie',
    // numericCode: 438,
    // ccTld: 'li',
  },
  {
    label: 'Lithuania',
    value: 'lt',
    // alpha2Code: 'lt',
    alpha3Code: 'ltu',
    // numericCode: 440,
    // ccTld: 'lt',
  },
  {
    label: 'Luxembourg',
    value: 'lu',
    // alpha2Code: 'lu',
    alpha3Code: 'lux',
    // numericCode: 442,
    // ccTld: 'lu',
  },
  {
    label: 'Macao',
    value: 'mo',
    // alpha2Code: 'mo',
    alpha3Code: 'mac',
    // numericCode: 446,
    // ccTld: 'mo',
  },
  {
    label: 'Madagascar',
    value: 'mg',
    // alpha2Code: 'mg',
    alpha3Code: 'mdg',
    // numericCode: 450,
    // ccTld: 'mg',
  },
  {
    label: 'Malawi',
    value: 'mw',
    // alpha2Code: 'mw',
    alpha3Code: 'mwi',
    // numericCode: 454,
    // ccTld: 'mw',
  },
  {
    label: 'Malaysia',
    value: 'my',
    // alpha2Code: 'my',
    alpha3Code: 'mys',
    // numericCode: 458,
    // ccTld: 'my',
  },
  {
    label: 'Maldives',
    value: 'mv',
    // alpha2Code: 'mv',
    alpha3Code: 'mdv',
    // numericCode: 462,
    // ccTld: 'mv',
  },
  {
    label: 'Mali',
    value: 'ml',
    // alpha2Code: 'ml',
    alpha3Code: 'mli',
    // numericCode: 466,
    // ccTld: 'ml',
  },
  {
    label: 'Malta',
    value: 'mt',
    // alpha2Code: 'mt',
    alpha3Code: 'mlt',
    // numericCode: 470,
    // ccTld: 'mt',
  },
  {
    label: 'Martinique',
    value: 'mq',
    // alpha2Code: 'mq',
    alpha3Code: 'mtq',
    // numericCode: 474,
    // ccTld: 'mq',
  },
  {
    label: 'Mauritania',
    value: 'mr',
    // alpha2Code: 'mr',
    alpha3Code: 'mrt',
    // numericCode: 478,
    // ccTld: 'mr',
  },
  {
    label: 'Mauritius',
    value: 'mu',
    // alpha2Code: 'mu',
    alpha3Code: 'mus',
    // numericCode: 480,
    // ccTld: 'mu',
  },
  {
    label: 'Mexico',
    value: 'mx',
    // alpha2Code: 'mx',
    alpha3Code: 'mex',
    // numericCode: 484,
    // ccTld: 'mx',
  },
  {
    label: 'Monaco',
    value: 'mc',
    // alpha2Code: 'mc',
    alpha3Code: 'mco',
    // numericCode: 492,
    // ccTld: 'mc',
  },
  {
    label: 'Mongolia',
    value: 'mn',
    // alpha2Code: 'mn',
    alpha3Code: 'mng',
    // numericCode: 496,
    // ccTld: 'mn',
  },
  {
    label: 'Moldova (the Republic of)',
    value: 'md',
    // alpha2Code: 'md',
    alpha3Code: 'mda',
    // numericCode: 498,
    // ccTld: 'md',
  },
  {
    label: 'Montenegro',
    value: 'me',
    // alpha2Code: 'me',
    alpha3Code: 'mne',
    // numericCode: 499,
    // ccTld: 'me',
  },
  {
    label: 'Montserrat',
    value: 'ms',
    // alpha2Code: 'ms',
    alpha3Code: 'msr',
    // numericCode: 500,
    // ccTld: 'ms',
  },
  {
    label: 'Morocco',
    value: 'ma',
    // alpha2Code: 'ma',
    alpha3Code: 'mar',
    // numericCode: 504,
    // ccTld: 'ma',
  },
  {
    label: 'Mozambique',
    value: 'mz',
    // alpha2Code: 'mz',
    alpha3Code: 'moz',
    // numericCode: 508,
    // ccTld: 'mz',
  },
  {
    label: 'Oman',
    value: 'om',
    // alpha2Code: 'om',
    alpha3Code: 'omn',
    // numericCode: 512,
    // ccTld: 'om',
  },
  {
    label: 'Namibia',
    value: 'na',
    // alpha2Code: 'na',
    alpha3Code: 'nam',
    // numericCode: 516,
    // ccTld: 'na',
  },
  {
    label: 'Nauru',
    value: 'nr',
    // alpha2Code: 'nr',
    alpha3Code: 'nru',
    // numericCode: 520,
    // ccTld: 'nr',
  },
  {
    label: 'Nepal',
    value: 'np',
    // alpha2Code: 'np',
    alpha3Code: 'npl',
    // numericCode: 524,
    // ccTld: 'np',
  },
  {
    label: 'Netherlands',
    value: 'nl',
    // alpha2Code: 'nl',
    alpha3Code: 'nld',
    // numericCode: 528,
    // ccTld: 'nl',
  },
  {
    label: 'Curaçao',
    value: 'cw',
    // alpha2Code: 'cw',
    alpha3Code: 'cuw',
    // numericCode: 531,
    // ccTld: 'cw',
  },
  {
    label: 'Aruba',
    value: 'aw',
    // alpha2Code: 'aw',
    alpha3Code: 'abw',
    // numericCode: 533,
    // ccTld: 'aw',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'sx',
    // alpha2Code: 'sx',
    alpha3Code: 'sxm',
    // numericCode: 534,
    // ccTld: 'sx',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'bq',
    // alpha2Code: 'bq',
    alpha3Code: 'bes',
    // numericCode: 535,
    // ccTld: 'nl',
  },
  {
    label: 'New Caledonia',
    value: 'nc',
    // alpha2Code: 'nc',
    alpha3Code: 'ncl',
    // numericCode: 540,
    // ccTld: 'nc',
  },
  {
    label: 'Vanuatu',
    value: 'vu',
    // alpha2Code: 'vu',
    alpha3Code: 'vut',
    // numericCode: 548,
    // ccTld: 'vu',
  },
  {
    label: 'New Zealand',
    value: 'nz',
    // alpha2Code: 'nz',
    alpha3Code: 'nzl',
    // numericCode: 554,
    // ccTld: 'nz',
  },
  {
    label: 'Nicaragua',
    value: 'ni',
    // alpha2Code: 'ni',
    alpha3Code: 'nic',
    // numericCode: 558,
    // ccTld: 'ni',
  },
  {
    label: 'Niger',
    value: 'ne',
    // alpha2Code: 'ne',
    alpha3Code: 'ner',
    // numericCode: 562,
    // ccTld: 'ne',
  },
  {
    label: 'Nigeria',
    value: 'ng',
    // alpha2Code: 'ng',
    alpha3Code: 'nga',
    // numericCode: 566,
    // ccTld: 'ng',
  },
  {
    label: 'Niue',
    value: 'nu',
    // alpha2Code: 'nu',
    alpha3Code: 'niu',
    // numericCode: 570,
    // ccTld: 'nu',
  },
  {
    label: 'Norfolk Island',
    value: 'nf',
    // alpha2Code: 'nf',
    alpha3Code: 'nfk',
    // numericCode: 574,
    // ccTld: 'nf',
  },
  {
    label: 'Norway',
    value: 'no',
    // alpha2Code: 'no',
    alpha3Code: 'nor',
    // numericCode: 578,
    // ccTld: 'no',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'mp',
    // alpha2Code: 'mp',
    alpha3Code: 'mnp',
    // numericCode: 580,
    // ccTld: 'mp',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'um',
    // alpha2Code: 'um',
    alpha3Code: 'umi',
    // numericCode: 581,
    // ccTld: '',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'fm',
    // alpha2Code: 'fm',
    alpha3Code: 'fsm',
    // numericCode: 583,
    // ccTld: 'fm',
  },
  {
    label: 'Marshall Islands',
    value: 'mh',
    // alpha2Code: 'mh',
    alpha3Code: 'mhl',
    // numericCode: 584,
    // ccTld: 'mh',
  },
  {
    label: 'Palau',
    value: 'pw',
    // alpha2Code: 'pw',
    alpha3Code: 'plw',
    // numericCode: 585,
    // ccTld: 'pw',
  },
  {
    label: 'Pakistan',
    value: 'pk',
    // alpha2Code: 'pk',
    alpha3Code: 'pak',
    // numericCode: 586,
    // ccTld: 'pk',
  },
  {
    label: 'Panama',
    value: 'pa',
    // alpha2Code: 'pa',
    alpha3Code: 'pan',
    // numericCode: 591,
    // ccTld: 'pa',
  },
  {
    label: 'Papua New Guinea',
    value: 'pg',
    // alpha2Code: 'pg',
    alpha3Code: 'png',
    // numericCode: 598,
    // ccTld: 'pg',
  },
  {
    label: 'Paraguay',
    value: 'py',
    // alpha2Code: 'py',
    alpha3Code: 'pry',
    // numericCode: 600,
    // ccTld: 'py',
  },
  {
    label: 'Peru',
    value: 'pe',
    // alpha2Code: 'pe',
    alpha3Code: 'per',
    // numericCode: 604,
    // ccTld: 'pe',
  },
  {
    label: 'Philippines',
    value: 'ph',
    // alpha2Code: 'ph',
    alpha3Code: 'phl',
    // numericCode: 608,
    // ccTld: 'ph',
  },
  {
    label: 'Pitcairn',
    value: 'pn',
    // alpha2Code: 'pn',
    alpha3Code: 'pcn',
    // numericCode: 612,
    // ccTld: 'pn',
  },
  {
    label: 'Poland',
    value: 'pl',
    // alpha2Code: 'pl',
    alpha3Code: 'pol',
    // numericCode: 616,
    // ccTld: 'pl',
  },
  {
    label: 'Portugal',
    value: 'pt',
    // alpha2Code: 'pt',
    alpha3Code: 'prt',
    // numericCode: 620,
    // ccTld: 'pt',
  },
  {
    label: 'Guinea-Bissau',
    value: 'gw',
    // alpha2Code: 'gw',
    alpha3Code: 'gnb',
    // numericCode: 624,
    // ccTld: 'gw',
  },
  {
    label: 'Timor-Leste',
    value: 'tl',
    // alpha2Code: 'tl',
    alpha3Code: 'tls',
    // numericCode: 626,
    // ccTld: 'tl',
  },
  {
    label: 'Puerto Rico',
    value: 'pr',
    // alpha2Code: 'pr',
    alpha3Code: 'pri',
    // numericCode: 630,
    // ccTld: 'pr',
  },
  {
    label: 'Qatar',
    value: 'qa',
    // alpha2Code: 'qa',
    alpha3Code: 'qat',
    // numericCode: 634,
    // ccTld: 'qa',
  },
  {
    label: 'Réunion',
    value: 're',
    // alpha2Code: 're',
    alpha3Code: 'reu',
    // numericCode: 638,
    // ccTld: 're',
  },
  {
    label: 'Romania',
    value: 'ro',
    // alpha2Code: 'ro',
    alpha3Code: 'rou',
    // numericCode: 642,
    // ccTld: 'ro',
  },
  {
    label: 'Russian Federation (the)',
    value: 'ru',
    // alpha2Code: 'ru',
    alpha3Code: 'rus',
    // numericCode: 643,
    // ccTld: 'ru',
  },
  {
    label: 'Rwanda',
    value: 'rw',
    // alpha2Code: 'rw',
    alpha3Code: 'rwa',
    // numericCode: 646,
    // ccTld: 'rw',
  },
  {
    label: 'Saint Barthélemy',
    value: 'bl',
    // alpha2Code: 'bl',
    alpha3Code: 'blm',
    // numericCode: 652,
    // ccTld: 'bl',
  },
  {
    label: 'Saint Helena | Ascension Island | Tristan da Cunha',
    value: 'sh',
    // alpha2Code: 'sh',
    alpha3Code: 'shn',
    // numericCode: 654,
    // ccTld: 'sh',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'kn',
    // alpha2Code: 'kn',
    alpha3Code: 'kna',
    // numericCode: 659,
    // ccTld: 'kn',
  },
  {
    label: 'Anguilla',
    value: 'ai',
    // alpha2Code: 'ai',
    alpha3Code: 'aia',
    // numericCode: 660,
    // ccTld: 'ai',
  },
  {
    label: 'Saint Lucia',
    value: 'lc',
    // alpha2Code: 'lc',
    alpha3Code: 'lca',
    // numericCode: 662,
    // ccTld: 'lc',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'mf',
    // alpha2Code: 'mf',
    alpha3Code: 'maf',
    // numericCode: 663,
    // ccTld: 'mf',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'pm',
    // alpha2Code: 'pm',
    alpha3Code: 'spm',
    // numericCode: 666,
    // ccTld: 'pm',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'vc',
    // alpha2Code: 'vc',
    alpha3Code: 'vct',
    // numericCode: 670,
    // ccTld: 'vc',
  },
  {
    label: 'San Marino',
    value: 'sm',
    // alpha2Code: 'sm',
    alpha3Code: 'smr',
    // numericCode: 674,
    // ccTld: 'sm',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'st',
    // alpha2Code: 'st',
    alpha3Code: 'stp',
    // numericCode: 678,
    // ccTld: 'st',
  },
  {
    label: 'Saudi Arabia',
    value: 'sa',
    // alpha2Code: 'sa',
    alpha3Code: 'sau',
    // numericCode: 682,
    // ccTld: 'sa',
  },
  {
    label: 'Senegal',
    value: 'sn',
    // alpha2Code: 'sn',
    alpha3Code: 'sen',
    // numericCode: 686,
    // ccTld: 'sn',
  },
  {
    label: 'Serbia',
    value: 'rs',
    // alpha2Code: 'rs',
    alpha3Code: 'srb',
    // numericCode: 688,
    // ccTld: 'rs',
  },
  {
    label: 'Seychelles',
    value: 'sc',
    // alpha2Code: 'sc',
    alpha3Code: 'syc',
    // numericCode: 690,
    // ccTld: 'sc',
  },
  {
    label: 'Sierra Leone',
    value: 'sl',
    // alpha2Code: 'sl',
    alpha3Code: 'sle',
    // numericCode: 694,
    // ccTld: 'sl',
  },
  {
    label: 'Singapore',
    value: 'sg',
    // alpha2Code: 'sg',
    alpha3Code: 'sgp',
    // numericCode: 702,
    // ccTld: 'sg',
  },
  {
    label: 'Slovakia',
    value: 'sk',
    // alpha2Code: 'sk',
    alpha3Code: 'svk',
    // numericCode: 703,
    // ccTld: 'sk',
  },
  {
    label: 'Viet Nam',
    value: 'vn',
    // alpha2Code: 'vn',
    alpha3Code: 'vnm',
    // numericCode: 704,
    // ccTld: 'vn',
  },
  {
    label: 'Slovenia',
    value: 'si',
    // alpha2Code: 'si',
    alpha3Code: 'svn',
    // numericCode: 705,
    // ccTld: 'si',
  },
  {
    label: 'Somalia',
    value: 'so',
    // alpha2Code: 'so',
    alpha3Code: 'som',
    // numericCode: 706,
    // ccTld: 'so',
  },
  {
    label: 'South Africa',
    value: 'za',
    // alpha2Code: 'za',
    alpha3Code: 'zaf',
    // numericCode: 710,
    // ccTld: 'za',
  },
  {
    label: 'Zimbabwe',
    value: 'zw',
    // alpha2Code: 'zw',
    alpha3Code: 'zwe',
    // numericCode: 716,
    // ccTld: 'zw',
  },
  {
    label: 'Spain',
    value: 'es',
    // alpha2Code: 'es',
    alpha3Code: 'esp',
    // numericCode: 724,
    // ccTld: 'es',
  },
  {
    label: 'South Sudan',
    value: 'ss',
    // alpha2Code: 'ss',
    alpha3Code: 'ssd',
    // numericCode: 728,
    // ccTld: 'ss',
  },
  {
    label: 'Sudan',
    value: 'sd',
    // alpha2Code: 'sd',
    alpha3Code: 'sdn',
    // numericCode: 729,
    // ccTld: 'sd',
  },
  {
    label: 'Western Sahara',
    value: 'eh',
    // alpha2Code: 'eh',
    alpha3Code: 'esh',
    // numericCode: 732,
    // ccTld: '',
  },
  {
    label: 'Suriname',
    value: 'sr',
    // alpha2Code: 'sr',
    alpha3Code: 'sur',
    // numericCode: 740,
    // ccTld: 'sr',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'sj',
    // alpha2Code: 'sj',
    alpha3Code: 'sjm',
    // numericCode: 744,
    // ccTld: '',
  },
  {
    label: 'Eswatini',
    value: 'sz',
    // alpha2Code: 'sz',
    alpha3Code: 'swz',
    // numericCode: 748,
    // ccTld: 'sz',
  },
  {
    label: 'Sweden',
    value: 'se',
    // alpha2Code: 'se',
    alpha3Code: 'swe',
    // numericCode: 752,
    // ccTld: 'se',
  },
  {
    label: 'Switzerland',
    value: 'ch',
    // alpha2Code: 'ch',
    alpha3Code: 'che',
    // numericCode: 756,
    // ccTld: 'ch',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'sy',
    // alpha2Code: 'sy',
    alpha3Code: 'syr',
    // numericCode: 760,
    // ccTld: 'sy',
  },
  {
    label: 'Tajikistan',
    value: 'tj',
    // alpha2Code: 'tj',
    alpha3Code: 'tjk',
    // numericCode: 762,
    // ccTld: 'tj',
  },
  {
    label: 'Thailand',
    value: 'th',
    // alpha2Code: 'th',
    alpha3Code: 'tha',
    // numericCode: 764,
    // ccTld: 'th',
  },
  {
    label: 'Togo',
    value: 'tg',
    // alpha2Code: 'tg',
    alpha3Code: 'tgo',
    // numericCode: 768,
    // ccTld: 'tg',
  },
  {
    label: 'Tokelau',
    value: 'tk',
    // alpha2Code: 'tk',
    alpha3Code: 'tkl',
    // numericCode: 772,
    // ccTld: 'tk',
  },
  {
    label: 'Tonga',
    value: 'to',
    // alpha2Code: 'to',
    alpha3Code: 'ton',
    // numericCode: 776,
    // ccTld: 'to',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'tt',
    // alpha2Code: 'tt',
    alpha3Code: 'tto',
    // numericCode: 780,
    // ccTld: 'tt',
  },
  {
    label: 'United Arab Emirates',
    value: 'ae',
    // alpha2Code: 'ae',
    alpha3Code: 'are',
    // numericCode: 784,
    // ccTld: 'ae',
  },
  {
    label: 'Tunisia',
    value: 'tn',
    // alpha2Code: 'tn',
    alpha3Code: 'tun',
    // numericCode: 788,
    // ccTld: 'tn',
  },
  {
    label: 'Turkey',
    value: 'tr',
    // alpha2Code: 'tr',
    alpha3Code: 'tur',
    // numericCode: 792,
    // ccTld: 'tr',
  },
  {
    label: 'Turkmenistan',
    value: 'tm',
    // alpha2Code: 'tm',
    alpha3Code: 'tkm',
    // numericCode: 795,
    // ccTld: 'tm',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'tc',
    // alpha2Code: 'tc',
    alpha3Code: 'tca',
    // numericCode: 796,
    // ccTld: 'tc',
  },
  {
    label: 'Tuvalu',
    value: 'tv',
    // alpha2Code: 'tv',
    alpha3Code: 'tuv',
    // numericCode: 798,
    // ccTld: 'tv',
  },
  {
    label: 'Uganda',
    value: 'ug',
    // alpha2Code: 'ug',
    alpha3Code: 'uga',
    // numericCode: 800,
    // ccTld: 'ug',
  },
  {
    label: 'Ukraine',
    value: 'ua',
    // alpha2Code: 'ua',
    alpha3Code: 'ukr',
    // numericCode: 804,
    // ccTld: 'ua',
  },
  {
    label: 'North Macedonia',
    value: 'mk',
    // alpha2Code: 'mk',
    alpha3Code: 'mkd',
    // numericCode: 807,
    // ccTld: 'mk',
  },
  {
    label: 'Egypt',
    value: 'eg',
    // alpha2Code: 'eg',
    alpha3Code: 'egy',
    // numericCode: 818,
    // ccTld: 'eg',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'gb',
    // alpha2Code: 'gb',
    alpha3Code: 'gbr',
    // numericCode: 826,
    // ccTld: 'uk',
  },
  {
    label: 'Guernsey',
    value: 'gg',
    // alpha2Code: 'gg',
    alpha3Code: 'ggy',
    // numericCode: 831,
    // ccTld: 'gg',
  },
  {
    label: 'Jersey',
    value: 'je',
    // alpha2Code: 'je',
    alpha3Code: 'jey',
    // numericCode: 832,
    // ccTld: 'je',
  },
  {
    label: 'Isle of Man',
    value: 'im',
    // alpha2Code: 'im',
    alpha3Code: 'imn',
    // numericCode: 833,
    // ccTld: 'im',
  },
  {
    label: 'Tanzania, the United Republic of',
    value: 'tz',
    // alpha2Code: 'tz',
    alpha3Code: 'tza',
    // numericCode: 834,
    // ccTld: 'tz',
  },
  {
    label: 'United States of America',
    value: 'us',
    // alpha2Code: 'us',
    alpha3Code: 'usa',
    // numericCode: 840,
    // ccTld: 'us',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'vi',
    // alpha2Code: 'vi',
    alpha3Code: 'vir',
    // numericCode: 850,
    // ccTld: 'vi',
  },
  {
    label: 'Burkina Faso',
    value: 'bf',
    // alpha2Code: 'bf',
    alpha3Code: 'bfa',
    // numericCode: 854,
    // ccTld: 'bf',
  },
  {
    label: 'Uruguay',
    value: 'uy',
    // alpha2Code: 'uy',
    alpha3Code: 'ury',
    // numericCode: 858,
    // ccTld: 'uy',
  },
  {
    label: 'Uzbekistan',
    value: 'uz',
    // alpha2Code: 'uz',
    alpha3Code: 'uzb',
    // numericCode: 860,
    // ccTld: 'uz',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 've',
    // alpha2Code: 've',
    alpha3Code: 'ven',
    // numericCode: 862,
    // ccTld: 've',
  },
  {
    label: 'Wallis and Futuna',
    value: 'wf',
    // alpha2Code: 'wf',
    alpha3Code: 'wlf',
    // numericCode: 876,
    // ccTld: 'wf',
  },
  {
    label: 'Samoa',
    value: 'ws',
    // alpha2Code: 'ws',
    alpha3Code: 'wsm',
    // numericCode: 882,
    // ccTld: 'ws',
  },
  {
    label: 'Yemen',
    value: 'ye',
    // alpha2Code: 'ye',
    alpha3Code: 'yem',
    // numericCode: 887,
    // ccTld: 'ye',
  },
  {
    label: 'Zambia',
    value: 'zm',
    // alpha2Code: 'zm',
    alpha3Code: 'zmb',
    // numericCode: 894,
    // ccTld: 'zm',
  },
]

export function formatCountry(country_code: string) {
  return formatOption(countryOptions, country_code)
}
