import * as Sentry from '@sentry/react'
// @ts-expect-error  // Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuidv4 } from 'uuid'

import { createGrpcWebTransport } from '@connectrpc/connect-web'
import { AccountsService } from '../../gen/nozzle/billing/v1/account_connectweb'
import { InvoicesService } from '../../gen/nozzle/billing/v1/invoice_connectweb'
import { OveragesService } from '../../gen/nozzle/billing/v1/overages_connectweb'
import { PaymentsService } from '../../gen/nozzle/billing/v1/payment_connectweb'
import { ClustersService } from '../../gen/nozzle/reports/v1/cluster_connectweb'
import { SerpwooService } from '../../gen/nozzle/workspaces/v1/serpwoo_connectweb'
import { PlansService } from '../../gen/nozzle/billing/v1/plan_connectweb'
import { SubscriptionsService } from '../../gen/nozzle/billing/v1/subscription_connectweb'
import { UsageService } from '../../gen/nozzle/billing/v1/usage_connectweb'
import { KeywordSourcesService } from '../../gen/nozzle/workspaces/v1/keyword_sources_connectweb'
import { RankingsService } from '../../gen/nozzle/workspaces/v1/ranking_connectweb'
import { PageService } from '../../gen/nozzle/urls/v1/page_connectweb'
import { TopicsService } from '../../gen/nozzle/workspaces/v1/topic_connectweb'
import { MonitoredUrlsService } from '../../gen/nozzle/workspaces/v1/monitored_url_connectweb'
import { KeywordsService } from '../../gen/nozzle/workspaces/v1/keyword_connectweb'
import { Interceptor, createPromiseClient } from '@connectrpc/connect'
import { getAccessToken } from './Api'

export {
  Account as AccountPb,
  AccountView as AccountViewPb,
  AddressValidationState as AddressValidationStatePb,
  BillingDetails as BillingDetailsPb,
  Contact as ContactPb,
  CreateAccountRequest as CreateAccountRequestPb,
  CreateAccountResponse as CreateAccountResponsePb,
  CreateContactRequest as CreateContactRequestPb,
  CreateContactResponse as CreateContactResponsePb,
  DeleteContactRequest as DeleteContactRequestPb,
  DeleteContactResponse as DeleteContactResponsePb,
  GetAccountRequest as GetAccountRequestPb,
  GetAccountResponse as GetAccountResponsePb,
  ListTaxIdTypesRequest as ListTaxIdTypesRequestPb,
  ListTaxIdTypesResponse as ListTaxIdTypesResponsePb,
  SyncAccountRequest as SyncAccountRequestPb,
  SyncAccountResponse as SyncAccountResponsePb,
  TaxIdType as TaxIdTypePb,
  UpdateBillingDetailsRequest as UpdateBillingDetailsRequestPb,
  UpdateBillingDetailsResponse as UpdateBillingDetailsResponsePb,
  UpdateContactRequest as UpdateContactRequestPb,
  UpdateContactResponse as UpdateContactResponsePb,
} from '../../gen/nozzle/billing/v1/account_pb'
export {
  CouponCode as CouponCodePb,
  CouponCodeState as CouponCodeStatePb,
  Coupon as CouponPb,
} from '../../gen/nozzle/billing/v1/coupon_pb'
export {
  DunningState as DunningStatePb,
  GetInvoicePdfRequest as GetInvoicePdfRequestPb,
  GetInvoicePdfResponse as GetInvoicePdfResponsePb,
  GetInvoiceRequest as GetInvoiceRequestPb,
  GetInvoiceResponse as GetInvoiceResponsePb,
  Invoice as InvoicePb,
  InvoiceState as InvoiceStatePb,
  ListInvoicesRequest as ListInvoicesRequestPb,
  ListInvoicesResponse as ListInvoicesResponsePb,
} from '../../gen/nozzle/billing/v1/invoice_pb'
export {
  AddCardByTokenRequest as AddCardByTokenRequestPb,
  AddCardByTokenResponse as AddCardByTokenResponsePb,
  Card as CardPb,
  CardType as CardTypePb,
  PaymentMethod as PaymentMethodPb,
  PaymentMethodState as PaymentMethodStatePb,
} from '../../gen/nozzle/billing/v1/payment_pb'
export {
  ListPlansRequest as ListPlansRequestPb,
  ListPlansResponse as ListPlansResponsePb,
  ListPublicPlansRequest as ListPublicPlansRequestPb,
  ListPublicPlansResponse as ListPublicPlansResponsePb,
  MeteredPlan as MeteredPlanPb,
  Period as PeriodPb,
  PeriodPlan as PeriodPlanPb,
  Plan as PlanPb,
  UpdatePlanRequest as UpdatePlanRequestPb,
  UpdatePlanResponse as UpdatePlanResponsePb,
} from '../../gen/nozzle/billing/v1/plan_pb'
export {
  CancelReason as CancelReasonPb,
  CancelSubscriptionRequest as CancelSubscriptionRequestPb,
  CancelSubscriptionResponse as CancelSubscriptionResponsePb,
  ChangeSubscriptionRequest as ChangeSubscriptionRequestPb,
  ChangeSubscriptionResponse as ChangeSubscriptionResponsePb,
  ChangeTiming as ChangeTimingPb,
  CreateSubscriptionRequest as CreateSubscriptionRequestPb,
  CreateSubscriptionResponse as CreateSubscriptionResponsePb,
  EstimateChangeSubscriptionRequest as EstimateChangeSubscriptionRequestPb,
  EstimateChangeSubscriptionResponse as EstimateChangeSubscriptionResponsePb,
  MeteredSubscription as MeteredSubscriptionPb,
  ModifyTrialRequest as ModifyTrialRequestPb,
  ModifyTrialResponse as ModifyTrialResponsePb,
  Subscription as SubscriptionPb,
  SubscriptionState as SubscriptionStatePb,
} from '../../gen/nozzle/billing/v1/subscription_pb'
export {
  GetUsageRequest as GetUsageRequestPb,
  GetUsageResponse as GetUsageResponsePb,
  ProductFamily as ProductFamilyPb,
  UpdateUsageStateRequest as UpdateUsageStateRequestPb,
  UpdateUsageStateResponse as UpdateUsageStateResponsePb,
  UsageByProject as UsageByProjectPb,
  UsageByWorkspace as UsageByWorkspacePb,
  UsageEvent as UsageEventPb,
  UsageEventType as UsageEventTypePb,
  Usage as UsagePb,
  UsageState as UsageStatePb,
  UsageTotal as UsageTotalPb,
  UsageType as UsageTypePb,
  UsageView as UsageViewPb,
} from '../../gen/nozzle/billing/v1/usage_pb'
export {
  Device as DevicePb,
  Engine as EnginePb,
  Keyword as KeywordPb,
  Location as LocationPb,
  LocationType as LocationTypePb,
} from '../../gen/nozzle/keywords/v1/keywords_pb'
export {
  Cluster as ClusterPb,
  ClusterUrl as ClusterUrlPb,
  GetClusterRequest as GetClusterRequestPb,
  GetClusterResponse as GetClusterResponsePb,
  ListClustersRequest as ListClustersRequestPb,
  ListClustersResponse as ListClustersResponsePb,
  MetricsByRelatedPhraseType as MetricsByRelatedPhraseTypePb,
  ListClustersFilters as ListClustersFiltersPb,
  ListClustersFacetsRequest as ListClustersFacetsRequestPb,
  ListClustersFacetsResponse as ListClustersFacetsResponsePb,
  UpdateClustersRequest as UpdateClustersRequestPb,
  UpdateClustersResponse as UpdateClustersResponsePb,
  ClusterView as ClusterViewPb,
} from '../../gen/nozzle/reports/v1/cluster_pb'
export {
  MetricAggregation as MetricAggregationPb,
  Metric as MetricPb,
  ResultMetrics as ResultMetricsPb,
  RollupMetrics as RollupMetricsPb,
  SerpMetrics as SerpMetricsPb,
  KeywordMetrics as KeywordMetricsPb,
  CategoryMetrics as CategoryMetricsPb,
  EntityMetrics as EntityMetricsPb,
  Facet as FacetPb,
  FacetValue as FacetValuePb,
} from '../../gen/nozzle/reports/v1/metrics_pb'
export {
  LighthouseError as LighthouseErrorPb,
  LighthouseResult as LighthouseResultPb,
  LighthouseResult_Environment as LighthouseResult_EnvironmentPb,
  LighthouseResult_RuntimeError as LighthouseResult_RuntimeErrorPb,
  LighthouseResult_ConfigSettings as LighthouseResult_ConfigSettingsPb,
  LighthouseResult_ConfigSettings_EmulatedFormFactor as LighthouseResult_ConfigSettings_EmulatedFormFactorPb,
  LighthouseResult_ConfigSettings_ThrottlingSettings as LighthouseResult_ConfigSettings_ThrottlingSettingsPb,
  LighthouseResult_ConfigSettings_ScreenEmulation as LighthouseResult_ConfigSettings_ScreenEmulationPb,
  LighthouseResult_Timing as LighthouseResult_TimingPb,
  LighthouseResult_Timing_PerformanceEntry as LighthouseResult_Timing_PerformanceEntryPb,
  CategoryGroup as CategoryGroupPb,
  LhrCategory as LhrCategoryPb,
  LhrCategory_AuditRef as LhrCategory_AuditRefPb,
  AuditResult as AuditResultPb,
  AuditResult_ScoreDisplayMode as AuditResult_ScoreDisplayModePb,
  I18n as I18nPb,
  I18n_RendererFormattedStrings as I18n_RendererFormattedStringsPb,
  StackPack as StackPackPb,
  LhrEntity as LhrEntityPb,
} from '../../gen/nozzle/urls/v1/lighthouse-result_pb'
export {
  ClassificationCategory as ClassificationCategoryPb,
  EntityMention as EntityMentionPb,
  EntityMention_Type as EntityMention_TypePb,
  Entity as EntityPb,
  Entity_Type as Entity_TypePb,
  Sentiment as SentimentPb,
  TextSpan as TextSpanPb,
} from '../../gen/nozzle/urls/v1/language_pb'
export {
  ListModelsRequest as ListModelsRequestPb,
  ListModelsResponse as ListModelsResponsePb,
  CreateModelRequest as CreateModelRequestPb,
  CreateModelResponse as CreateModelResponsePb,
  GetModelRequest as GetModelRequestPb,
  GetModelResponse as GetModelResponsePb,
  KeywordSourceDataModel as KeywordSourceDataModelPb,
  KeywordSourceDataModelValue as KeywordSourceDataModelValuePb,
  KeywordSourceDataModelValueValue as KeywordSourceDataModelValueValuePb,
  PhraseTemplate as PhraseTemplatePb,
  KeywordGroupTemplate as KeywordGroupTemplatePb,
  LocaleModel as LocaleModelPb,
  LocaleModelAlias as LocaleModelAliasPb,
  DeviceModel as DeviceModelPb,
  Schedule as SchedulePb,
  KeywordStats as KeywordStatsPb,
  DataModel2 as DataModel2Pb,
  DataModelHierarchy as DataModelHierarchyPb,
  DataModelEntry as DataModelEntryPb,
  DataModelEntryValue as DataModelEntryValuePb,
} from '../../gen/nozzle/workspaces/v1/data_model_pb'
export {
  KeywordSourceState as KeywordSourceStatePb,
  KeywordSourceConfigType as KeywordSourceConfigTypePb,
  GenerateKeywordsRequest as GenerateKeywordsRequestPb,
  GenerateKeywordsResponse as GenerateKeywordsResponsePb,
  GetKeywordSourceRequest as GetKeywordSourceRequestPb,
  GetKeywordSourceResponse as GetKeywordSourceResponsePb,
  CreateKeywordSourceRequest as CreateKeywordSourceRequestPb,
  CreateKeywordSourceResponse as CreateKeywordSourceResponsePb,
  ListKeywordSourcesRequest as ListKeywordSourcesRequestPb,
  ListKeywordSourcesResponse as ListKeywordSourcesResponsePb,
  UpdateKeywordSourceRequest as UpdateKeywordSourceRequestPb,
  UpdateKeywordSourceResponse as UpdateKeywordSourceResponsePb,
  ScheduledKeyword as ScheduledKeywordPb,
  ModelSource as ModelSourcePb,
  KeywordGroup as KeywordGroupPb,
  KeywordSource as KeywordSourcePb,
  KeywordSourceDataModelConfig as KeywordSourceDataModelConfigPb,
  KeywordSourceDataModelConfig2 as KeywordSourceDataModelConfig2Pb,
  RefreshKeywordSourceRequest as RefreshKeywordSourceRequestPb,
  RefreshKeywordSourceResponse as RefreshKeywordSourceResponsePb,
  RequestTiming as RequestTimingPb,
  SuggestKeywordsRequest as SuggestKeywordsRequestPb,
  SuggestKeywordsResponse as SuggestKeywordsResponsePb,
  SuggestedKeywordResult as SuggestedKeywordResultPb,
} from '../../gen/nozzle/workspaces/v1/keyword_sources_pb'
export {
  GradeLevel as GradeLevelPb,
  PageFetchType as PageFetchTypePb,
  PageFetchError as PageFetchErrorPb,
  RequestPageRequest as RequestPageRequestPb,
  RequestPageResponse as RequestPageResponsePb,
  BatchRequestPageRequest as BatchRequestPageRequestPb,
  BatchRequestPageResponse as BatchRequestPageResponsePb,
  GetPageRequest as GetPageRequestPb,
  GetPageResponse as GetPageResponsePb,
  BatchGetPageRequest as BatchGetPageRequestPb,
  BatchGetPageResponse as BatchGetPageResponsePb,
  Url as UrlPb,
  Page as PagePb,
  Heading as HeadingPb,
  BoldWord as BoldWordPb,
  ItalicizedWord as ItalicizedWordPb,
  Paragraph as ParagraphPb,
  Image as ImagePb,
  Link as LinkPb,
  TextStats as TextStatsPb,
  OpenGraph as OpenGraphPb,
  Robots as RobotsPb,
  Technology as TechnologyPb,
  TechnologyCategory as TechnologyCategoryPb,
} from '../../gen/nozzle/urls/v1/page_pb'
export {
  TopicView as TopicViewPb,
  UpdateTopicRequest as UpdateTopicRequestPb,
  UpdateTopicResponse as UpdateTopicResponsePb,
  GetTopicRequest as GetTopicRequestPb,
  GetTopicResponse as GetTopicResponsePb,
  ListTopicsRequest as ListTopicsRequestPb,
  ListTopicsResponse as ListTopicsResponsePb,
  DeleteTopicRequest as DeleteTopicRequestPb,
  DeleteTopicResponse as DeleteTopicResponsePb,
  Topic as TopicPb,
  MappedKeyword as MappedKeywordPb,
  MappedUrl as MappedUrlPb,
  BatchDeleteTopicsRequest as BatchDeleteTopicsRequestPb,
  BatchDeleteTopicsResponse as BatchDeleteTopicsResponsePb,
  BatchGetTopicsRequest as BatchGetTopicsRequestPb,
  BatchGetTopicsResponse as BatchGetTopicsResponsePb,
  BatchUpdateTopicsRequest as BatchUpdateTopicsRequestPb,
  BatchUpdateTopicsResponse as BatchUpdateTopicsResponsePb,
  MappedUrlsByBrand as MappedUrlsByBrandPb,
  TopicViewOptions as TopicViewOptionsPb,
} from '../../gen/nozzle/workspaces/v1/topic_pb'
export {
  MonitoredUrlView as MonitoredUrlViewPb,
  MonitoredUrlState as MonitoredUrlStatePb,
  UpdateMonitoredUrlRequest as UpdateMonitoredUrlRequestPb,
  UpdateMonitoredUrlResponse as UpdateMonitoredUrlResponsePb,
  BatchUpdateMonitoredUrlsRequest as BatchUpdateMonitoredUrlsRequestPb,
  BatchUpdateMonitoredUrlsResponse as BatchUpdateMonitoredUrlsResponsePb,
  UpdateMonitoredUrlsFromSitemapRequest as UpdateMonitoredUrlsFromSitemapRequestPb,
  UpdateMonitoredUrlsFromSitemapResponse as UpdateMonitoredUrlsFromSitemapResponsePb,
  GetMonitoredUrlRequest as GetMonitoredUrlRequestPb,
  GetMonitoredUrlResponse as GetMonitoredUrlResponsePb,
  ListMonitoredUrlsRequest as ListMonitoredUrlsRequestPb,
  ListMonitoredUrlsResponse as ListMonitoredUrlsResponsePb,
  DeleteMonitoredUrlRequest as DeleteMonitoredUrlRequestPb,
  DeleteMonitoredUrlResponse as DeleteMonitoredUrlResponsePb,
  MonitoredUrl as MonitoredUrlPb,
  MonitoredUrlSchedule as MonitoredUrlSchedulePb,
  BatchDeleteMonitoredUrlsRequest as BatchDeleteMonitoredUrlsRequestPb,
  BatchDeleteMonitoredUrlsResponse as BatchDeleteMonitoredUrlsResponsePb,
  BatchGetMonitoredUrlsRequest as BatchGetMonitoredUrlsRequestPb,
  BatchGetMonitoredUrlsResponse as BatchGetMonitoredUrlsResponsePb,
  CustomerJourneyStage as CustomerJourneyStagePb,
  SearchIntentType as SearchIntentTypePb,
  MonitoredUrlViewOptions as MonitoredUrlViewOptionsPb,
  AutoParentMonitoredUrlsRequest as AutoParentMonitoredUrlsRequestPb,
  AutoParentMonitoredUrlsResponse as AutoParentMonitoredUrlsResponsePb,
  ListMonitoredUrlsFilters as ListMonitoredUrlsFiltersPb,
} from '../../gen/nozzle/workspaces/v1/monitored_url_pb'
export {
  ImportSerpwooRequest as ImportSerpwooRequestPb,
  ImportSerpwooResponse as ImportSerpwooResponsePb,
} from '../../gen/nozzle/workspaces/v1/serpwoo_pb'
export {
  Keyword as WorkspaceKeywordPb,
  ListKeywordsFacetsRequest as ListKeywordsFacetsRequestPb,
  ListKeywordsFacetsResponse as ListKeywordsFacetsResponsePb,
  ListKeywordsFilters as ListKeywordsFiltersPb,
  ListKeywordsRequest as ListKeywordsRequestPb,
  ListKeywordsResponse as ListKeywordsResponsePb,
} from '../../gen/nozzle/workspaces/v1/keyword_pb'

export { MonitoredUrlsService } from '../../gen/nozzle/workspaces/v1/monitored_url_connectweb'
export { Money as MoneyPb } from '../../gen/google/type/money_pb'
export { Date as DatePb } from '../../gen/google/type/date_pb'
export type { PlainMessage, PartialMessage } from '@bufbuild/protobuf'

let latestNonce: any = {}
const sentryInterceptor: Interceptor = next => async req => {
  const nonce = {}
  latestNonce = nonce
  const requestId = uuidv4()
  req.header.set('x-request-id', requestId)
  Sentry.setTag('requestId', requestId)
  req.header.set('x-request-start', `${Date.now() / 1000}`)

  const res = await next(req)
  if (latestNonce === nonce) {
    Sentry.setTag('requestId', undefined)
  }
  return res
}

const authInterceptor: Interceptor = next => async req => {
  req.header.set('authorization', `Bearer ${await getAccessToken()}`)
  return await next(req)
}

const errorInterceptor: Interceptor = next => async req => {
  try {
    const res = await next(req)
    console.info({
      type: 'Buf Success',
      url: res.url,
      message: res.message,
      response: res,
      ...req,
    })
    return res
  } catch (err) {
    if (!import.meta.env.PROD) {
      console.log({
        type: 'Buf Error',
        url: err.url,
        message: err.message,
        response: JSON.parse(JSON.stringify(err)),
        ...req,
      })
    }
    throw err
  }
}

export const grpcWebTransport = createGrpcWebTransport({
  baseUrl: 'https://api.nozzle.io',
  // useBinaryFormat: false,
  interceptors: [sentryInterceptor, authInterceptor, errorInterceptor].filter(
    Boolean
  ),
})

export const PlansClient = createPromiseClient(PlansService, grpcWebTransport)
export const AccountsClient = createPromiseClient(
  AccountsService,
  grpcWebTransport
)
export const SubscriptionsClient = createPromiseClient(
  SubscriptionsService,
  grpcWebTransport
)
export const UsageClient = createPromiseClient(UsageService, grpcWebTransport)
export const InvoicesClient = createPromiseClient(
  InvoicesService,
  grpcWebTransport
)
export const PaymentsClient = createPromiseClient(
  PaymentsService,
  grpcWebTransport
)
export const OveragesClient = createPromiseClient(
  OveragesService,
  grpcWebTransport
)
export const RankingsClient = createPromiseClient(
  RankingsService,
  grpcWebTransport
)
export const KeywordSourcesClient = createPromiseClient(
  KeywordSourcesService,
  grpcWebTransport
)
export const ClusterClient = createPromiseClient(
  ClustersService,
  grpcWebTransport
)
export const PageClient = createPromiseClient(PageService, grpcWebTransport)
export const TopicsClient = createPromiseClient(TopicsService, grpcWebTransport)
export const MonitoredUrlsClient = createPromiseClient(
  MonitoredUrlsService,
  grpcWebTransport
)
export const SerpwooClient = createPromiseClient(
  SerpwooService,
  grpcWebTransport
)
export const KeywordsClient = createPromiseClient(
  KeywordsService,
  grpcWebTransport
)
