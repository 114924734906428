import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceByKeywordMetricsWidget,
  PerformanceByKeywordTopWidget,
  PerformanceByKeywordWinnersWidget,
  PerformanceByKeywordLosersWidget,
  PerformanceByKeywordKeywordsWidget,
} from '../../../containers/Dashboards/PerformanceByKeyword'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-by-keyword'
)({
  validateSearch: search =>
    search as {
      performanceByKeywordMetricsWidget?: PerformanceByKeywordMetricsWidget
      performanceByKeywordTopWidget?: PerformanceByKeywordTopWidget
      performanceByKeywordWinnersWidget?: PerformanceByKeywordWinnersWidget
      performanceByKeywordLosersWidget?: PerformanceByKeywordLosersWidget
      performanceByKeywordKeywordsWidget?: PerformanceByKeywordKeywordsWidget
      keywordIds?: string[]
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByKeywordMetricsWidget:
          search.performanceByKeywordMetricsWidget,
        performanceByKeywordTopWidget: search.performanceByKeywordTopWidget,
        performanceByKeywordWinnersWidget:
          search.performanceByKeywordWinnersWidget,
        performanceByKeywordLosersWidget:
          search.performanceByKeywordLosersWidget,
        performanceByKeywordKeywordsWidget:
          search.performanceByKeywordKeywordsWidget,
      }
    },
  ],
})
