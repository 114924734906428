// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/monitored_url.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Operation } from "../../../google/longrunning/operations_pb.js";
import { Facet } from "../../reports/v1/metrics_pb.js";
import { Page, Url } from "../../urls/v1/page_pb.js";
import { Label } from "../../protobuf/label_pb.js";

/**
 * MonitoredUrlView determines which fields of the MonitoredUrl are returned in the response.
 *
 * @generated from enum nozzle.workspaces.v1.MonitoredUrlView
 */
export enum MonitoredUrlView {
  /**
   * Will default to MONITORED_URL_VIEW_BASIC.
   *
   * @generated from enum value: MONITORED_URL_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns id and url
   *
   * @generated from enum value: MONITORED_URL_VIEW_SEARCH = 3;
   */
  SEARCH = 3,

  /**
   * This only returns the manually configured fields of the topic, not any reporting data
   *
   * @generated from enum value: MONITORED_URL_VIEW_FLAT = 1;
   */
  FLAT = 1,

  /**
   * This will populate the topic with all of the reporting and page data
   *
   * @generated from enum value: MONITORED_URL_VIEW_NESTED = 2;
   */
  NESTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(MonitoredUrlView)
proto3.util.setEnumType(MonitoredUrlView, "nozzle.workspaces.v1.MonitoredUrlView", [
  { no: 0, name: "MONITORED_URL_VIEW_UNSPECIFIED" },
  { no: 3, name: "MONITORED_URL_VIEW_SEARCH" },
  { no: 1, name: "MONITORED_URL_VIEW_FLAT" },
  { no: 2, name: "MONITORED_URL_VIEW_NESTED" },
]);

/**
 * MonitoredUrlState determines the current status of the MonitoredUrl.
 *
 * @generated from enum nozzle.workspaces.v1.MonitoredUrlState
 */
export enum MonitoredUrlState {
  /**
   * @generated from enum value: MONITORED_URL_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns the manually configured fields of the topic, not any reporting data
   *
   * @generated from enum value: MONITORED_URL_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * Temporarily paused, but expected to resume monitoring
   *
   * @generated from enum value: MONITORED_URL_STATE_PAUSED = 2;
   */
  PAUSED = 2,

  /**
   * The page on the site is either removed or abandoned, but we're keeping it around for historical purposes
   *
   * @generated from enum value: MONITORED_URL_STATE_ARCHIVED = 3;
   */
  ARCHIVED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(MonitoredUrlState)
proto3.util.setEnumType(MonitoredUrlState, "nozzle.workspaces.v1.MonitoredUrlState", [
  { no: 0, name: "MONITORED_URL_STATE_UNSPECIFIED" },
  { no: 1, name: "MONITORED_URL_STATE_ACTIVE" },
  { no: 2, name: "MONITORED_URL_STATE_PAUSED" },
  { no: 3, name: "MONITORED_URL_STATE_ARCHIVED" },
]);

/**
 * CustomerJourneyStage marks which stage of the buying journey a keyword / url targets.
 *
 * @generated from enum nozzle.workspaces.v1.CustomerJourneyStage
 */
export enum CustomerJourneyStage {
  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_AWARENESS = 1;
   */
  AWARENESS = 1,

  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_CONSIDERATION = 2;
   */
  CONSIDERATION = 2,

  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_CONVERSION = 3;
   */
  CONVERSION = 3,

  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_RETENTION = 4;
   */
  RETENTION = 4,

  /**
   * @generated from enum value: CUSTOMER_JOURNEY_STAGE_ADVOCACY = 5;
   */
  ADVOCACY = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(CustomerJourneyStage)
proto3.util.setEnumType(CustomerJourneyStage, "nozzle.workspaces.v1.CustomerJourneyStage", [
  { no: 0, name: "CUSTOMER_JOURNEY_STAGE_UNSPECIFIED" },
  { no: 1, name: "CUSTOMER_JOURNEY_STAGE_AWARENESS" },
  { no: 2, name: "CUSTOMER_JOURNEY_STAGE_CONSIDERATION" },
  { no: 3, name: "CUSTOMER_JOURNEY_STAGE_CONVERSION" },
  { no: 4, name: "CUSTOMER_JOURNEY_STAGE_RETENTION" },
  { no: 5, name: "CUSTOMER_JOURNEY_STAGE_ADVOCACY" },
]);

/**
 * SearchIntentType identifies the type of content available on a url.
 *
 * @generated from enum nozzle.workspaces.v1.SearchIntentType
 */
export enum SearchIntentType {
  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_INFORMATIONAL = 1;
   */
  INFORMATIONAL = 1,

  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_NAVIGATIONAL = 2;
   */
  NAVIGATIONAL = 2,

  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_TRANSACTIONAL = 3;
   */
  TRANSACTIONAL = 3,

  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_COMMERCIAL = 4;
   */
  COMMERCIAL = 4,

  /**
   * @generated from enum value: SEARCH_INTENT_TYPE_LOCAL = 5;
   */
  LOCAL = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SearchIntentType)
proto3.util.setEnumType(SearchIntentType, "nozzle.workspaces.v1.SearchIntentType", [
  { no: 0, name: "SEARCH_INTENT_TYPE_UNSPECIFIED" },
  { no: 1, name: "SEARCH_INTENT_TYPE_INFORMATIONAL" },
  { no: 2, name: "SEARCH_INTENT_TYPE_NAVIGATIONAL" },
  { no: 3, name: "SEARCH_INTENT_TYPE_TRANSACTIONAL" },
  { no: 4, name: "SEARCH_INTENT_TYPE_COMMERCIAL" },
  { no: 5, name: "SEARCH_INTENT_TYPE_LOCAL" },
]);

/**
 * UpdateMonitoredUrlRequest is the request for the UpdateMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateMonitoredUrlRequest
 */
export class UpdateMonitoredUrlRequest extends Message<UpdateMonitoredUrlRequest> {
  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrl monitored_url = 1;
   */
  monitoredUrl?: MonitoredUrl;

  /**
   * If true, the request will succeed even if the url does not exist. If false, the request will fail
   * if url_id is not set or if the referenced url_id does not exist.
   *
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  /**
   * If true, will attempt to classify the customer journey stage and search intent of the url, if unset
   *
   * @generated from field: bool auto_classify = 3;
   */
  autoClassify = false;

  constructor(data?: PartialMessage<UpdateMonitoredUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateMonitoredUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_url", kind: "message", T: MonitoredUrl },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "auto_classify", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMonitoredUrlRequest {
    return new UpdateMonitoredUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlRequest {
    return new UpdateMonitoredUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlRequest {
    return new UpdateMonitoredUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMonitoredUrlRequest | PlainMessage<UpdateMonitoredUrlRequest> | undefined, b: UpdateMonitoredUrlRequest | PlainMessage<UpdateMonitoredUrlRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMonitoredUrlRequest, a, b);
  }
}

/**
 * UpdateMonitoredUrlResponse is the response for the UpdateMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateMonitoredUrlResponse
 */
export class UpdateMonitoredUrlResponse extends Message<UpdateMonitoredUrlResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrl monitored_url = 1;
   */
  monitoredUrl?: MonitoredUrl;

  constructor(data?: PartialMessage<UpdateMonitoredUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateMonitoredUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_url", kind: "message", T: MonitoredUrl },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMonitoredUrlResponse {
    return new UpdateMonitoredUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlResponse {
    return new UpdateMonitoredUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlResponse {
    return new UpdateMonitoredUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMonitoredUrlResponse | PlainMessage<UpdateMonitoredUrlResponse> | undefined, b: UpdateMonitoredUrlResponse | PlainMessage<UpdateMonitoredUrlResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMonitoredUrlResponse, a, b);
  }
}

/**
 * BatchUpdateMonitoredUrlsRequest is the request for the BatchUpdateMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.BatchUpdateMonitoredUrlsRequest
 */
export class BatchUpdateMonitoredUrlsRequest extends Message<BatchUpdateMonitoredUrlsRequest> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl monitored_urls = 1;
   */
  monitoredUrls: MonitoredUrl[] = [];

  /**
   * If true, the request will succeed even if the url does not exist. If false, the request will fail
   * if url_id is not set or if the referenced url_id does not exist.
   *
   * @generated from field: bool allow_missing = 2;
   */
  allowMissing = false;

  /**
   * If true, will attempt to classify the customer journey stage and search intent of the url, if unset
   *
   * @generated from field: bool auto_classify = 3;
   */
  autoClassify = false;

  constructor(data?: PartialMessage<BatchUpdateMonitoredUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchUpdateMonitoredUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_urls", kind: "message", T: MonitoredUrl, repeated: true },
    { no: 2, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "auto_classify", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchUpdateMonitoredUrlsRequest {
    return new BatchUpdateMonitoredUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchUpdateMonitoredUrlsRequest {
    return new BatchUpdateMonitoredUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchUpdateMonitoredUrlsRequest {
    return new BatchUpdateMonitoredUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchUpdateMonitoredUrlsRequest | PlainMessage<BatchUpdateMonitoredUrlsRequest> | undefined, b: BatchUpdateMonitoredUrlsRequest | PlainMessage<BatchUpdateMonitoredUrlsRequest> | undefined): boolean {
    return proto3.util.equals(BatchUpdateMonitoredUrlsRequest, a, b);
  }
}

/**
 * BatchUpdateMonitoredUrlsResponse is the response for the BatchUpdateMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.BatchUpdateMonitoredUrlsResponse
 */
export class BatchUpdateMonitoredUrlsResponse extends Message<BatchUpdateMonitoredUrlsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl monitored_urls = 1;
   */
  monitoredUrls: MonitoredUrl[] = [];

  constructor(data?: PartialMessage<BatchUpdateMonitoredUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchUpdateMonitoredUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_urls", kind: "message", T: MonitoredUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchUpdateMonitoredUrlsResponse {
    return new BatchUpdateMonitoredUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchUpdateMonitoredUrlsResponse {
    return new BatchUpdateMonitoredUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchUpdateMonitoredUrlsResponse {
    return new BatchUpdateMonitoredUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchUpdateMonitoredUrlsResponse | PlainMessage<BatchUpdateMonitoredUrlsResponse> | undefined, b: BatchUpdateMonitoredUrlsResponse | PlainMessage<BatchUpdateMonitoredUrlsResponse> | undefined): boolean {
    return proto3.util.equals(BatchUpdateMonitoredUrlsResponse, a, b);
  }
}

/**
 * UpdateMonitoredUrlsFromSitemapRequest is the request for the UpdateMonitoredUrlsFromSitemap method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateMonitoredUrlsFromSitemapRequest
 */
export class UpdateMonitoredUrlsFromSitemapRequest extends Message<UpdateMonitoredUrlsFromSitemapRequest> {
  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrl monitored_url_template = 1;
   */
  monitoredUrlTemplate?: MonitoredUrl;

  /**
   * @generated from field: string sitemap_url = 2;
   */
  sitemapUrl = "";

  /**
   * If true, the request will succeed even if the url does not exist. If false, the request will fail
   * if url_id is not set or if the referenced url_id does not exist.
   *
   * @generated from field: bool allow_missing = 3;
   */
  allowMissing = false;

  constructor(data?: PartialMessage<UpdateMonitoredUrlsFromSitemapRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateMonitoredUrlsFromSitemapRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_url_template", kind: "message", T: MonitoredUrl },
    { no: 2, name: "sitemap_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "allow_missing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMonitoredUrlsFromSitemapRequest {
    return new UpdateMonitoredUrlsFromSitemapRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlsFromSitemapRequest {
    return new UpdateMonitoredUrlsFromSitemapRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlsFromSitemapRequest {
    return new UpdateMonitoredUrlsFromSitemapRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMonitoredUrlsFromSitemapRequest | PlainMessage<UpdateMonitoredUrlsFromSitemapRequest> | undefined, b: UpdateMonitoredUrlsFromSitemapRequest | PlainMessage<UpdateMonitoredUrlsFromSitemapRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMonitoredUrlsFromSitemapRequest, a, b);
  }
}

/**
 * UpdateMonitoredUrlsFromSitemapResponse is the response for the UpdateMonitoredUrlsFromSitemap method.
 *
 * @generated from message nozzle.workspaces.v1.UpdateMonitoredUrlsFromSitemapResponse
 */
export class UpdateMonitoredUrlsFromSitemapResponse extends Message<UpdateMonitoredUrlsFromSitemapResponse> {
  /**
   * @generated from field: google.longrunning.Operation operation = 1;
   */
  operation?: Operation;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl monitored_urls = 2;
   */
  monitoredUrls: MonitoredUrl[] = [];

  constructor(data?: PartialMessage<UpdateMonitoredUrlsFromSitemapResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.UpdateMonitoredUrlsFromSitemapResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "operation", kind: "message", T: Operation },
    { no: 2, name: "monitored_urls", kind: "message", T: MonitoredUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMonitoredUrlsFromSitemapResponse {
    return new UpdateMonitoredUrlsFromSitemapResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlsFromSitemapResponse {
    return new UpdateMonitoredUrlsFromSitemapResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMonitoredUrlsFromSitemapResponse {
    return new UpdateMonitoredUrlsFromSitemapResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMonitoredUrlsFromSitemapResponse | PlainMessage<UpdateMonitoredUrlsFromSitemapResponse> | undefined, b: UpdateMonitoredUrlsFromSitemapResponse | PlainMessage<UpdateMonitoredUrlsFromSitemapResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMonitoredUrlsFromSitemapResponse, a, b);
  }
}

/**
 * AutoParentMonitoredUrlsRequest is the request for the AutoParentMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.AutoParentMonitoredUrlsRequest
 */
export class AutoParentMonitoredUrlsRequest extends Message<AutoParentMonitoredUrlsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 brand_id = 3;
   */
  brandId = protoInt64.zero;

  /**
   * if set, will create virtual urls if an actual url doesn't exist in the url folder structure.
   * e.g. if /blog/categories/category-name exists, but /blog/categories does not, /blog/categories will
   * be created as a virtual url.
   *
   * @generated from field: bool create_virtual_urls = 4;
   */
  createVirtualUrls = false;

  /**
   * if create_virtual_urls is true, this helps us to determine the url path for the virtual url.
   * if true, will append a trailing slash to the url path, if false will trim it off. If unset,
   * it will attempt to infer the trailing slash from the existing urls.
   *
   * @generated from field: optional bool trailing_slash = 5;
   */
  trailingSlash?: boolean;

  /**
   * if true, will overwrite any existing parent_monitored_url_id values
   *
   * @generated from field: bool overwrite_existing_parents = 6;
   */
  overwriteExistingParents = false;

  constructor(data?: PartialMessage<AutoParentMonitoredUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.AutoParentMonitoredUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "create_virtual_urls", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "trailing_slash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "overwrite_existing_parents", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoParentMonitoredUrlsRequest {
    return new AutoParentMonitoredUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoParentMonitoredUrlsRequest {
    return new AutoParentMonitoredUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoParentMonitoredUrlsRequest {
    return new AutoParentMonitoredUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutoParentMonitoredUrlsRequest | PlainMessage<AutoParentMonitoredUrlsRequest> | undefined, b: AutoParentMonitoredUrlsRequest | PlainMessage<AutoParentMonitoredUrlsRequest> | undefined): boolean {
    return proto3.util.equals(AutoParentMonitoredUrlsRequest, a, b);
  }
}

/**
 * AutoParentMonitoredUrlsResponse is the response for the AutoParentMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.AutoParentMonitoredUrlsResponse
 */
export class AutoParentMonitoredUrlsResponse extends Message<AutoParentMonitoredUrlsResponse> {
  constructor(data?: PartialMessage<AutoParentMonitoredUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.AutoParentMonitoredUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutoParentMonitoredUrlsResponse {
    return new AutoParentMonitoredUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutoParentMonitoredUrlsResponse {
    return new AutoParentMonitoredUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutoParentMonitoredUrlsResponse {
    return new AutoParentMonitoredUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutoParentMonitoredUrlsResponse | PlainMessage<AutoParentMonitoredUrlsResponse> | undefined, b: AutoParentMonitoredUrlsResponse | PlainMessage<AutoParentMonitoredUrlsResponse> | undefined): boolean {
    return proto3.util.equals(AutoParentMonitoredUrlsResponse, a, b);
  }
}

/**
 * GetMonitoredUrlRequest is the request for the GetMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.GetMonitoredUrlRequest
 */
export class GetMonitoredUrlRequest extends Message<GetMonitoredUrlRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 monitored_url_id = 3;
   */
  monitoredUrlId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlView view = 4;
   */
  view = MonitoredUrlView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlViewOptions view_options = 5;
   */
  viewOptions?: MonitoredUrlViewOptions;

  constructor(data?: PartialMessage<GetMonitoredUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetMonitoredUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "monitored_url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(MonitoredUrlView) },
    { no: 5, name: "view_options", kind: "message", T: MonitoredUrlViewOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonitoredUrlRequest {
    return new GetMonitoredUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonitoredUrlRequest {
    return new GetMonitoredUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonitoredUrlRequest {
    return new GetMonitoredUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonitoredUrlRequest | PlainMessage<GetMonitoredUrlRequest> | undefined, b: GetMonitoredUrlRequest | PlainMessage<GetMonitoredUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetMonitoredUrlRequest, a, b);
  }
}

/**
 * GetMonitoredUrlResponse is the response for the GetMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.GetMonitoredUrlResponse
 */
export class GetMonitoredUrlResponse extends Message<GetMonitoredUrlResponse> {
  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrl monitored_url = 1;
   */
  monitoredUrl?: MonitoredUrl;

  constructor(data?: PartialMessage<GetMonitoredUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.GetMonitoredUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_url", kind: "message", T: MonitoredUrl },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMonitoredUrlResponse {
    return new GetMonitoredUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMonitoredUrlResponse {
    return new GetMonitoredUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMonitoredUrlResponse {
    return new GetMonitoredUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMonitoredUrlResponse | PlainMessage<GetMonitoredUrlResponse> | undefined, b: GetMonitoredUrlResponse | PlainMessage<GetMonitoredUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetMonitoredUrlResponse, a, b);
  }
}

/**
 * BatchGetMonitoredUrlsRequest is the request for the BatchGetMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.BatchGetMonitoredUrlsRequest
 */
export class BatchGetMonitoredUrlsRequest extends Message<BatchGetMonitoredUrlsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 monitored_url_ids = 3;
   */
  monitoredUrlIds: bigint[] = [];

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlView view = 4;
   */
  view = MonitoredUrlView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlViewOptions view_options = 5;
   */
  viewOptions?: MonitoredUrlViewOptions;

  constructor(data?: PartialMessage<BatchGetMonitoredUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchGetMonitoredUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(MonitoredUrlView) },
    { no: 5, name: "view_options", kind: "message", T: MonitoredUrlViewOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetMonitoredUrlsRequest {
    return new BatchGetMonitoredUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetMonitoredUrlsRequest {
    return new BatchGetMonitoredUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetMonitoredUrlsRequest {
    return new BatchGetMonitoredUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetMonitoredUrlsRequest | PlainMessage<BatchGetMonitoredUrlsRequest> | undefined, b: BatchGetMonitoredUrlsRequest | PlainMessage<BatchGetMonitoredUrlsRequest> | undefined): boolean {
    return proto3.util.equals(BatchGetMonitoredUrlsRequest, a, b);
  }
}

/**
 * BatchGetMonitoredUrlsResponse is the response for the BatchGetMonitoredUrls method. The order of the
 * urls in the response will match the order of the ids in the request. Any non-existent urls will cause
 * an error.
 *
 * @generated from message nozzle.workspaces.v1.BatchGetMonitoredUrlsResponse
 */
export class BatchGetMonitoredUrlsResponse extends Message<BatchGetMonitoredUrlsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl monitored_urls = 1;
   */
  monitoredUrls: MonitoredUrl[] = [];

  constructor(data?: PartialMessage<BatchGetMonitoredUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchGetMonitoredUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_urls", kind: "message", T: MonitoredUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchGetMonitoredUrlsResponse {
    return new BatchGetMonitoredUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchGetMonitoredUrlsResponse {
    return new BatchGetMonitoredUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchGetMonitoredUrlsResponse {
    return new BatchGetMonitoredUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchGetMonitoredUrlsResponse | PlainMessage<BatchGetMonitoredUrlsResponse> | undefined, b: BatchGetMonitoredUrlsResponse | PlainMessage<BatchGetMonitoredUrlsResponse> | undefined): boolean {
    return proto3.util.equals(BatchGetMonitoredUrlsResponse, a, b);
  }
}

/**
 * ListMonitoredUrlsRequest is the request for the ListMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.ListMonitoredUrlsRequest
 */
export class ListMonitoredUrlsRequest extends Message<ListMonitoredUrlsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlView view = 4;
   */
  view = MonitoredUrlView.UNSPECIFIED;

  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlViewOptions view_options = 5;
   */
  viewOptions?: MonitoredUrlViewOptions;

  /**
   * if set, will only return monitored urls without a parent_monitored_id
   *
   * @generated from field: bool root_only = 6;
   */
  rootOnly = false;

  /**
   * @generated from field: nozzle.workspaces.v1.ListMonitoredUrlsFilters filters = 7;
   */
  filters?: ListMonitoredUrlsFilters;

  /**
   * optional pagination
   *
   * @generated from field: string page_token = 8;
   */
  pageToken = "";

  /**
   * default page size is 100
   *
   * @generated from field: int32 page_size = 9;
   */
  pageSize = 0;

  constructor(data?: PartialMessage<ListMonitoredUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListMonitoredUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(MonitoredUrlView) },
    { no: 5, name: "view_options", kind: "message", T: MonitoredUrlViewOptions },
    { no: 6, name: "root_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "filters", kind: "message", T: ListMonitoredUrlsFilters },
    { no: 8, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMonitoredUrlsRequest {
    return new ListMonitoredUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMonitoredUrlsRequest {
    return new ListMonitoredUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMonitoredUrlsRequest {
    return new ListMonitoredUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMonitoredUrlsRequest | PlainMessage<ListMonitoredUrlsRequest> | undefined, b: ListMonitoredUrlsRequest | PlainMessage<ListMonitoredUrlsRequest> | undefined): boolean {
    return proto3.util.equals(ListMonitoredUrlsRequest, a, b);
  }
}

/**
 * ListMonitoredUrlsFilters is a set of available filters to apply to the list request.
 *
 * @generated from message nozzle.workspaces.v1.ListMonitoredUrlsFilters
 */
export class ListMonitoredUrlsFilters extends Message<ListMonitoredUrlsFilters> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  /**
   * @generated from field: repeated int64 include_monitored_url_ids = 20;
   */
  includeMonitoredUrlIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_monitored_url_ids = 21;
   */
  excludeMonitoredUrlIds: bigint[] = [];

  /**
   * to only include root monitored urls, use 0 as the parent id
   *
   * @generated from field: repeated int64 include_parent_monitored_url_ids = 22;
   */
  includeParentMonitoredUrlIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_parent_monitored_url_ids = 23;
   */
  excludeParentMonitoredUrlIds: bigint[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.SearchIntentType include_primary_search_intents = 2;
   */
  includePrimarySearchIntents: SearchIntentType[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.SearchIntentType exclude_primary_search_intents = 3;
   */
  excludePrimarySearchIntents: SearchIntentType[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.SearchIntentType include_secondary_search_intents = 4;
   */
  includeSecondarySearchIntents: SearchIntentType[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.SearchIntentType exclude_secondary_search_intents = 5;
   */
  excludeSecondarySearchIntents: SearchIntentType[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.CustomerJourneyStage include_customer_journey_stages = 6;
   */
  includeCustomerJourneyStages: CustomerJourneyStage[] = [];

  /**
   * @generated from field: repeated nozzle.workspaces.v1.CustomerJourneyStage exclude_customer_journey_stages = 7;
   */
  excludeCustomerJourneyStages: CustomerJourneyStage[] = [];

  /**
   * @generated from field: repeated int32 include_priorities = 8;
   */
  includePriorities: number[] = [];

  /**
   * @generated from field: repeated int32 exclude_priorities = 9;
   */
  excludePriorities: number[] = [];

  /**
   * @generated from field: repeated int64 include_brand_ids = 10;
   */
  includeBrandIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_brand_ids = 11;
   */
  excludeBrandIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 include_domain_ids = 12;
   */
  includeDomainIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_domain_ids = 13;
   */
  excludeDomainIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 include_url_ids = 14;
   */
  includeUrlIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_url_ids = 15;
   */
  excludeUrlIds: bigint[] = [];

  /**
   * @generated from field: repeated string url_contains = 16;
   */
  urlContains: string[] = [];

  /**
   * @generated from field: repeated string url_does_not_contain = 17;
   */
  urlDoesNotContain: string[] = [];

  /**
   * @generated from field: string url_regex_contains = 18;
   */
  urlRegexContains = "";

  /**
   * @generated from field: string url_regex_does_not_contain = 19;
   */
  urlRegexDoesNotContain = "";

  constructor(data?: PartialMessage<ListMonitoredUrlsFilters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListMonitoredUrlsFilters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "include_monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 21, name: "exclude_monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 22, name: "include_parent_monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 23, name: "exclude_parent_monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "include_primary_search_intents", kind: "enum", T: proto3.getEnumType(SearchIntentType), repeated: true },
    { no: 3, name: "exclude_primary_search_intents", kind: "enum", T: proto3.getEnumType(SearchIntentType), repeated: true },
    { no: 4, name: "include_secondary_search_intents", kind: "enum", T: proto3.getEnumType(SearchIntentType), repeated: true },
    { no: 5, name: "exclude_secondary_search_intents", kind: "enum", T: proto3.getEnumType(SearchIntentType), repeated: true },
    { no: 6, name: "include_customer_journey_stages", kind: "enum", T: proto3.getEnumType(CustomerJourneyStage), repeated: true },
    { no: 7, name: "exclude_customer_journey_stages", kind: "enum", T: proto3.getEnumType(CustomerJourneyStage), repeated: true },
    { no: 8, name: "include_priorities", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 9, name: "exclude_priorities", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "include_brand_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "exclude_brand_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 12, name: "include_domain_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 13, name: "exclude_domain_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 14, name: "include_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 15, name: "exclude_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 16, name: "url_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "url_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "url_regex_contains", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "url_regex_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMonitoredUrlsFilters {
    return new ListMonitoredUrlsFilters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFilters {
    return new ListMonitoredUrlsFilters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFilters {
    return new ListMonitoredUrlsFilters().fromJsonString(jsonString, options);
  }

  static equals(a: ListMonitoredUrlsFilters | PlainMessage<ListMonitoredUrlsFilters> | undefined, b: ListMonitoredUrlsFilters | PlainMessage<ListMonitoredUrlsFilters> | undefined): boolean {
    return proto3.util.equals(ListMonitoredUrlsFilters, a, b);
  }
}

/**
 * ListMonitoredUrlsResponse is the response for the ListMonitoredUrls method.
 *
 * @generated from message nozzle.workspaces.v1.ListMonitoredUrlsResponse
 */
export class ListMonitoredUrlsResponse extends Message<ListMonitoredUrlsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl monitored_urls = 1;
   */
  monitoredUrls: MonitoredUrl[] = [];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  /**
   * @generated from field: int32 total_size = 3;
   */
  totalSize = 0;

  constructor(data?: PartialMessage<ListMonitoredUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListMonitoredUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "monitored_urls", kind: "message", T: MonitoredUrl, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMonitoredUrlsResponse {
    return new ListMonitoredUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMonitoredUrlsResponse {
    return new ListMonitoredUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMonitoredUrlsResponse {
    return new ListMonitoredUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMonitoredUrlsResponse | PlainMessage<ListMonitoredUrlsResponse> | undefined, b: ListMonitoredUrlsResponse | PlainMessage<ListMonitoredUrlsResponse> | undefined): boolean {
    return proto3.util.equals(ListMonitoredUrlsResponse, a, b);
  }
}

/**
 * ListMonitoredUrlsFacetsRequest is the request for the ListMonitoredUrlsFacets method.
 *
 * @generated from message nozzle.workspaces.v1.ListMonitoredUrlsFacetsRequest
 */
export class ListMonitoredUrlsFacetsRequest extends Message<ListMonitoredUrlsFacetsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.ListMonitoredUrlsFilters filters = 7;
   */
  filters?: ListMonitoredUrlsFilters;

  constructor(data?: PartialMessage<ListMonitoredUrlsFacetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListMonitoredUrlsFacetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "filters", kind: "message", T: ListMonitoredUrlsFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMonitoredUrlsFacetsRequest {
    return new ListMonitoredUrlsFacetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFacetsRequest {
    return new ListMonitoredUrlsFacetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFacetsRequest {
    return new ListMonitoredUrlsFacetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListMonitoredUrlsFacetsRequest | PlainMessage<ListMonitoredUrlsFacetsRequest> | undefined, b: ListMonitoredUrlsFacetsRequest | PlainMessage<ListMonitoredUrlsFacetsRequest> | undefined): boolean {
    return proto3.util.equals(ListMonitoredUrlsFacetsRequest, a, b);
  }
}

/**
 * ListMonitoredUrlsFacetsResponse is the response for the ListMonitoredUrlsFacets method.
 *
 * @generated from message nozzle.workspaces.v1.ListMonitoredUrlsFacetsResponse
 */
export class ListMonitoredUrlsFacetsResponse extends Message<ListMonitoredUrlsFacetsResponse> {
  /**
   * @generated from field: nozzle.reports.v1.Facet primary_search_intents = 1;
   */
  primarySearchIntents?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet secondary_search_intents = 2;
   */
  secondarySearchIntents?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet customer_journey_stages = 3;
   */
  customerJourneyStages?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet priorities = 4;
   */
  priorities?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet brand_ids = 5;
   */
  brandIds?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet domain_ids = 6;
   */
  domainIds?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet url_ids = 7;
   */
  urlIds?: Facet;

  constructor(data?: PartialMessage<ListMonitoredUrlsFacetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListMonitoredUrlsFacetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "primary_search_intents", kind: "message", T: Facet },
    { no: 2, name: "secondary_search_intents", kind: "message", T: Facet },
    { no: 3, name: "customer_journey_stages", kind: "message", T: Facet },
    { no: 4, name: "priorities", kind: "message", T: Facet },
    { no: 5, name: "brand_ids", kind: "message", T: Facet },
    { no: 6, name: "domain_ids", kind: "message", T: Facet },
    { no: 7, name: "url_ids", kind: "message", T: Facet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMonitoredUrlsFacetsResponse {
    return new ListMonitoredUrlsFacetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFacetsResponse {
    return new ListMonitoredUrlsFacetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListMonitoredUrlsFacetsResponse {
    return new ListMonitoredUrlsFacetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListMonitoredUrlsFacetsResponse | PlainMessage<ListMonitoredUrlsFacetsResponse> | undefined, b: ListMonitoredUrlsFacetsResponse | PlainMessage<ListMonitoredUrlsFacetsResponse> | undefined): boolean {
    return proto3.util.equals(ListMonitoredUrlsFacetsResponse, a, b);
  }
}

/**
 * DeleteMonitoredUrlRequest is the request for the DeleteMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.DeleteMonitoredUrlRequest
 */
export class DeleteMonitoredUrlRequest extends Message<DeleteMonitoredUrlRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 monitored_url_id = 3;
   */
  monitoredUrlId = protoInt64.zero;

  constructor(data?: PartialMessage<DeleteMonitoredUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DeleteMonitoredUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "monitored_url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteMonitoredUrlRequest {
    return new DeleteMonitoredUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteMonitoredUrlRequest {
    return new DeleteMonitoredUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteMonitoredUrlRequest {
    return new DeleteMonitoredUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteMonitoredUrlRequest | PlainMessage<DeleteMonitoredUrlRequest> | undefined, b: DeleteMonitoredUrlRequest | PlainMessage<DeleteMonitoredUrlRequest> | undefined): boolean {
    return proto3.util.equals(DeleteMonitoredUrlRequest, a, b);
  }
}

/**
 * DeleteMonitoredUrlResponse is the response for the DeleteMonitoredUrl method.
 *
 * @generated from message nozzle.workspaces.v1.DeleteMonitoredUrlResponse
 */
export class DeleteMonitoredUrlResponse extends Message<DeleteMonitoredUrlResponse> {
  constructor(data?: PartialMessage<DeleteMonitoredUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.DeleteMonitoredUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteMonitoredUrlResponse {
    return new DeleteMonitoredUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteMonitoredUrlResponse {
    return new DeleteMonitoredUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteMonitoredUrlResponse {
    return new DeleteMonitoredUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteMonitoredUrlResponse | PlainMessage<DeleteMonitoredUrlResponse> | undefined, b: DeleteMonitoredUrlResponse | PlainMessage<DeleteMonitoredUrlResponse> | undefined): boolean {
    return proto3.util.equals(DeleteMonitoredUrlResponse, a, b);
  }
}

/**
 * BatchDeleteMonitoredUrlsRequest is the inbound request for the batch delete method.
 *
 * @generated from message nozzle.workspaces.v1.BatchDeleteMonitoredUrlsRequest
 */
export class BatchDeleteMonitoredUrlsRequest extends Message<BatchDeleteMonitoredUrlsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 monitored_url_ids = 2;
   */
  monitoredUrlIds: bigint[] = [];

  constructor(data?: PartialMessage<BatchDeleteMonitoredUrlsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchDeleteMonitoredUrlsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "monitored_url_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchDeleteMonitoredUrlsRequest {
    return new BatchDeleteMonitoredUrlsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchDeleteMonitoredUrlsRequest {
    return new BatchDeleteMonitoredUrlsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchDeleteMonitoredUrlsRequest {
    return new BatchDeleteMonitoredUrlsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchDeleteMonitoredUrlsRequest | PlainMessage<BatchDeleteMonitoredUrlsRequest> | undefined, b: BatchDeleteMonitoredUrlsRequest | PlainMessage<BatchDeleteMonitoredUrlsRequest> | undefined): boolean {
    return proto3.util.equals(BatchDeleteMonitoredUrlsRequest, a, b);
  }
}

/**
 * BatchDeleteMonitoredUrlsResponse is the response for the batch delete method.
 *
 * @generated from message nozzle.workspaces.v1.BatchDeleteMonitoredUrlsResponse
 */
export class BatchDeleteMonitoredUrlsResponse extends Message<BatchDeleteMonitoredUrlsResponse> {
  constructor(data?: PartialMessage<BatchDeleteMonitoredUrlsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.BatchDeleteMonitoredUrlsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchDeleteMonitoredUrlsResponse {
    return new BatchDeleteMonitoredUrlsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchDeleteMonitoredUrlsResponse {
    return new BatchDeleteMonitoredUrlsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchDeleteMonitoredUrlsResponse {
    return new BatchDeleteMonitoredUrlsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchDeleteMonitoredUrlsResponse | PlainMessage<BatchDeleteMonitoredUrlsResponse> | undefined, b: BatchDeleteMonitoredUrlsResponse | PlainMessage<BatchDeleteMonitoredUrlsResponse> | undefined): boolean {
    return proto3.util.equals(BatchDeleteMonitoredUrlsResponse, a, b);
  }
}

/**
 * MonitoredUrl is a url mapping and crawling frequency.
 *
 * @generated from message nozzle.workspaces.v1.MonitoredUrl
 */
export class MonitoredUrl extends Message<MonitoredUrl> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: int64 id = 3;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: int64 version_id = 4;
   */
  versionId = protoInt64.zero;

  /**
   * optional parent id for monitored urls, to enable hierarchical grouping. This will often
   * be auto-inferred from slashes in the url, but can be manually set if needed.
   *
   * @generated from field: int64 parent_monitored_url_id = 18;
   */
  parentMonitoredUrlId = protoInt64.zero;

  /**
   * returns all ancestors of this monitored url, not including the current monitored url, ordered from furthest to closest
   *
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl ancestors = 19;
   */
  ancestors: MonitoredUrl[] = [];

  /**
   * @generated from field: int32 ancestor_count = 23;
   */
  ancestorCount = 0;

  /**
   * may optionally contain all children of this monitored url, recursively to a max depth
   *
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrl children = 20;
   */
  children: MonitoredUrl[] = [];

  /**
   * @generated from field: int32 children_count = 24;
   */
  childrenCount = 0;

  /**
   * @generated from field: int32 grandchildren_count = 25;
   */
  grandchildrenCount = 0;

  /**
   * @generated from field: int32 descendant_count = 26;
   */
  descendantCount = 0;

  /**
   * every monitored url must be associated with a brand - all ancestor / descendent urls must be associated
   * with the same brand. We may choose to relax this in the future if a company like Apple or IBM wants
   * to represent sections of their site as different brands, but for now it's a hard requirement.
   *
   * @generated from field: int64 brand_id = 22;
   */
  brandId = protoInt64.zero;

  /**
   * the actual url details - this is required to be unique per project
   *
   * @generated from field: nozzle.urls.v1.Url url = 5;
   */
  url?: Url;

  /**
   * a virtual url is one that does not actually exist, but is used to group other urls together. This might
   * be for something like /blog/categories that isn't a page, but all individual category pages are located
   * at /blog/categories/category-name. This is primarily useful for reporting purposes.
   *
   * @generated from field: bool is_virtual = 21;
   */
  isVirtual = false;

  /**
   * full page details returned if requested
   *
   * @generated from field: nozzle.urls.v1.Page page = 6;
   */
  page?: Page;

  /**
   * not active from the beginning, but describes the schedule for fetching the url. It's repeated so that
   * we can have multiple schedules for the same url, e.g.
   * - check liveness every 5 minutes
   * - check content every 24 hours
   * - run lighthouse weekly
   *
   * the options will be tightly tied to what the PagesService can do.
   *
   * @generated from field: repeated nozzle.workspaces.v1.MonitoredUrlSchedule schedules = 9;
   */
  schedules: MonitoredUrlSchedule[] = [];

  /**
   * identify the primary search intent served by this url
   *
   * @generated from field: nozzle.workspaces.v1.SearchIntentType primary_search_intent = 14;
   */
  primarySearchIntent = SearchIntentType.UNSPECIFIED;

  /**
   * identify the secondary search intent, if any, served by this url, often expected to be unset
   *
   * @generated from field: nozzle.workspaces.v1.SearchIntentType secondary_search_intent = 15;
   */
  secondarySearchIntent = SearchIntentType.UNSPECIFIED;

  /**
   * mark which stage of the customer journey this url targets
   *
   * @generated from field: nozzle.workspaces.v1.CustomerJourneyStage customer_journey_stage = 16;
   */
  customerJourneyStage = CustomerJourneyStage.UNSPECIFIED;

  /**
   * arbitrary priority score between 1 and 5, with 1 being the highest priority and 5 being the lowest.
   * 0 is treated as unspecified.
   *
   * @generated from field: int32 priority = 17;
   */
  priority = 0;

  /**
   * like keyword groups / tags, this enables us to group urls arbitrarily, e.g.
   * Product: product1, product2, product3
   * Business Unit: business_unit1, business_unit2, business_unit3
   * Relevance: High, Medium, Low
   *
   * @generated from field: repeated nozzle.protobuf.Label labels = 10;
   */
  labels: Label[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 12;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp deleted_at = 13;
   */
  deletedAt?: Timestamp;

  constructor(data?: PartialMessage<MonitoredUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MonitoredUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "version_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 18, name: "parent_monitored_url_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 19, name: "ancestors", kind: "message", T: MonitoredUrl, repeated: true },
    { no: 23, name: "ancestor_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 20, name: "children", kind: "message", T: MonitoredUrl, repeated: true },
    { no: 24, name: "children_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 25, name: "grandchildren_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 26, name: "descendant_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "url", kind: "message", T: Url },
    { no: 21, name: "is_virtual", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "page", kind: "message", T: Page },
    { no: 9, name: "schedules", kind: "message", T: MonitoredUrlSchedule, repeated: true },
    { no: 14, name: "primary_search_intent", kind: "enum", T: proto3.getEnumType(SearchIntentType) },
    { no: 15, name: "secondary_search_intent", kind: "enum", T: proto3.getEnumType(SearchIntentType) },
    { no: 16, name: "customer_journey_stage", kind: "enum", T: proto3.getEnumType(CustomerJourneyStage) },
    { no: 17, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "labels", kind: "message", T: Label, repeated: true },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "updated_at", kind: "message", T: Timestamp },
    { no: 13, name: "deleted_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MonitoredUrl {
    return new MonitoredUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MonitoredUrl {
    return new MonitoredUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MonitoredUrl {
    return new MonitoredUrl().fromJsonString(jsonString, options);
  }

  static equals(a: MonitoredUrl | PlainMessage<MonitoredUrl> | undefined, b: MonitoredUrl | PlainMessage<MonitoredUrl> | undefined): boolean {
    return proto3.util.equals(MonitoredUrl, a, b);
  }
}

/**
 * MonitoredUrlSchedule contains a schedule name and rrule, with crawl configuration.
 *
 * @generated from message nozzle.workspaces.v1.MonitoredUrlSchedule
 */
export class MonitoredUrlSchedule extends Message<MonitoredUrlSchedule> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string rrule = 2;
   */
  rrule = "";

  /**
   * @generated from field: bool include_entities = 3;
   */
  includeEntities = false;

  /**
   * @generated from field: bool include_categories = 4;
   */
  includeCategories = false;

  /**
   * @generated from field: bool include_lighthouse = 5;
   */
  includeLighthouse = false;

  constructor(data?: PartialMessage<MonitoredUrlSchedule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MonitoredUrlSchedule";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rrule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "include_entities", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "include_categories", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "include_lighthouse", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MonitoredUrlSchedule {
    return new MonitoredUrlSchedule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MonitoredUrlSchedule {
    return new MonitoredUrlSchedule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MonitoredUrlSchedule {
    return new MonitoredUrlSchedule().fromJsonString(jsonString, options);
  }

  static equals(a: MonitoredUrlSchedule | PlainMessage<MonitoredUrlSchedule> | undefined, b: MonitoredUrlSchedule | PlainMessage<MonitoredUrlSchedule> | undefined): boolean {
    return proto3.util.equals(MonitoredUrlSchedule, a, b);
  }
}

/**
 * MonitoredUrlViewOptions sets recursive depth and limits for ancestors and descendents.
 *
 * @generated from message nozzle.workspaces.v1.MonitoredUrlViewOptions
 */
export class MonitoredUrlViewOptions extends Message<MonitoredUrlViewOptions> {
  /**
   * @generated from field: nozzle.workspaces.v1.MonitoredUrlView view = 1;
   */
  view = MonitoredUrlView.UNSPECIFIED;

  /**
   * if set, will include all ancestors of the monitored urls, recursively to the specified depth.
   * The default is 0, which will not include any ancestors, and 1 only includes the immediate parent.
   * If set to -1, will include all ancestors.
   *
   * @generated from field: int32 ancestor_depth = 2;
   */
  ancestorDepth = 0;

  /**
   * this is the maximum number of ancestors to return, regardless of depth.
   *
   * @generated from field: int32 ancestor_limit = 3;
   */
  ancestorLimit = 0;

  /**
   * if set, will include all children of the monitored urls, recursively to the specified depth.
   * The default is 0, which will not include any children, and 1 only includes the immediate children.
   * If set to -1, will include all children.
   *
   * @generated from field: int32 descendent_depth = 4;
   */
  descendentDepth = 0;

  /**
   * this is the maximum number of descendents to return, regardless of depth.
   *
   * @generated from field: int32 descendent_limit = 5;
   */
  descendentLimit = 0;

  constructor(data?: PartialMessage<MonitoredUrlViewOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.MonitoredUrlViewOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "view", kind: "enum", T: proto3.getEnumType(MonitoredUrlView) },
    { no: 2, name: "ancestor_depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "ancestor_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "descendent_depth", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "descendent_limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MonitoredUrlViewOptions {
    return new MonitoredUrlViewOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MonitoredUrlViewOptions {
    return new MonitoredUrlViewOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MonitoredUrlViewOptions {
    return new MonitoredUrlViewOptions().fromJsonString(jsonString, options);
  }

  static equals(a: MonitoredUrlViewOptions | PlainMessage<MonitoredUrlViewOptions> | undefined, b: MonitoredUrlViewOptions | PlainMessage<MonitoredUrlViewOptions> | undefined): boolean {
    return proto3.util.equals(MonitoredUrlViewOptions, a, b);
  }
}

