// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/subscription.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ProductFamily, UsageTotal, UsageType } from "./usage_pb.js";
import { Period } from "./plan_pb.js";
import { Date } from "../../../google/type/date_pb.js";
import { Money } from "../../../google/type/money_pb.js";
import { Coupon, CouponCode } from "./coupon_pb.js";

/**
 * SubscriptionState is the current state of a Subscription.
 *
 * @generated from enum nozzle.billing.v1.SubscriptionState
 */
export enum SubscriptionState {
  /**
   * @generated from enum value: SUBSCRIPTION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * The subscription is active and will be charged for automatically based on the items in it.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * The subscription is in trial.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_TRIAL = 2;
   */
  TRIAL = 2,

  /**
   * The subscription has been canceled and is no longer in service.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_CANCELED = 3;
   */
  CANCELED = 3,

  /**
   * The subscription is scheduled to start at a future date.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_FUTURE = 4;
   */
  FUTURE = 4,

  /**
   * The subscription is paused. The subscription will not renew while in this state.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_PAUSED = 5;
   */
  PAUSED = 5,

  /**
   * The subscription will be canceled at the end of the current term.
   *
   * @generated from enum value: SUBSCRIPTION_STATE_NON_RENEWING = 6;
   */
  NON_RENEWING = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(SubscriptionState)
proto3.util.setEnumType(SubscriptionState, "nozzle.billing.v1.SubscriptionState", [
  { no: 0, name: "SUBSCRIPTION_STATE_UNSPECIFIED" },
  { no: 1, name: "SUBSCRIPTION_STATE_ACTIVE" },
  { no: 2, name: "SUBSCRIPTION_STATE_TRIAL" },
  { no: 3, name: "SUBSCRIPTION_STATE_CANCELED" },
  { no: 4, name: "SUBSCRIPTION_STATE_FUTURE" },
  { no: 5, name: "SUBSCRIPTION_STATE_PAUSED" },
  { no: 6, name: "SUBSCRIPTION_STATE_NON_RENEWING" },
]);

/**
 * CancelReason is the reason the subscription was canceled.
 *
 * @generated from enum nozzle.billing.v1.CancelReason
 */
export enum CancelReason {
  /**
   * @generated from enum value: CANCEL_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CANCEL_REASON_NOT_PAID = 1;
   */
  NOT_PAID = 1,

  /**
   * @generated from enum value: CANCEL_REASON_NO_CARD = 2;
   */
  NO_CARD = 2,

  /**
   * @generated from enum value: CANCEL_REASON_FRAUD_REVIEW_FAILED = 3;
   */
  FRAUD_REVIEW_FAILED = 3,

  /**
   * @generated from enum value: CANCEL_REASON_NON_COMPLIANT_EU_CUSTOMER = 4;
   */
  NON_COMPLIANT_EU_CUSTOMER = 4,

  /**
   * @generated from enum value: CANCEL_REASON_TAX_CALCULATION_FAILED = 5;
   */
  TAX_CALCULATION_FAILED = 5,

  /**
   * @generated from enum value: CANCEL_REASON_CURRENCY_INCOMPATIBLE_WITH_GATEWAY = 6;
   */
  CURRENCY_INCOMPATIBLE_WITH_GATEWAY = 6,

  /**
   * @generated from enum value: CANCEL_REASON_NON_COMPLIANT_CUSTOMER = 7;
   */
  NON_COMPLIANT_CUSTOMER = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(CancelReason)
proto3.util.setEnumType(CancelReason, "nozzle.billing.v1.CancelReason", [
  { no: 0, name: "CANCEL_REASON_UNSPECIFIED" },
  { no: 1, name: "CANCEL_REASON_NOT_PAID" },
  { no: 2, name: "CANCEL_REASON_NO_CARD" },
  { no: 3, name: "CANCEL_REASON_FRAUD_REVIEW_FAILED" },
  { no: 4, name: "CANCEL_REASON_NON_COMPLIANT_EU_CUSTOMER" },
  { no: 5, name: "CANCEL_REASON_TAX_CALCULATION_FAILED" },
  { no: 6, name: "CANCEL_REASON_CURRENCY_INCOMPATIBLE_WITH_GATEWAY" },
  { no: 7, name: "CANCEL_REASON_NON_COMPLIANT_CUSTOMER" },
]);

/**
 * ChangeTiming describes when a subscription change will take effect.
 *
 * @generated from enum nozzle.billing.v1.ChangeTiming
 */
export enum ChangeTiming {
  /**
   * @generated from enum value: CHANGE_TIMING_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CHANGE_TIMING_IMMEDIATE = 1;
   */
  IMMEDIATE = 1,

  /**
   * @generated from enum value: CHANGE_TIMING_END_OF_TERM = 2;
   */
  END_OF_TERM = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ChangeTiming)
proto3.util.setEnumType(ChangeTiming, "nozzle.billing.v1.ChangeTiming", [
  { no: 0, name: "CHANGE_TIMING_UNSPECIFIED" },
  { no: 1, name: "CHANGE_TIMING_IMMEDIATE" },
  { no: 2, name: "CHANGE_TIMING_END_OF_TERM" },
]);

/**
 * CreateSubscriptionRequest is the request for CreateSubscription.
 *
 * @generated from message nozzle.billing.v1.CreateSubscriptionRequest
 */
export class CreateSubscriptionRequest extends Message<CreateSubscriptionRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = "";

  /**
   * @generated from field: nozzle.billing.v1.Period base_period = 4;
   */
  basePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.Period overage_period = 5;
   */
  overagePeriod = Period.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateSubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateSubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 5, name: "overage_period", kind: "enum", T: proto3.getEnumType(Period) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSubscriptionRequest {
    return new CreateSubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSubscriptionRequest {
    return new CreateSubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSubscriptionRequest {
    return new CreateSubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSubscriptionRequest | PlainMessage<CreateSubscriptionRequest> | undefined, b: CreateSubscriptionRequest | PlainMessage<CreateSubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(CreateSubscriptionRequest, a, b);
  }
}

/**
 * CreateSubscriptionResponse is the response for CreateSubscription.
 *
 * @generated from message nozzle.billing.v1.CreateSubscriptionResponse
 */
export class CreateSubscriptionResponse extends Message<CreateSubscriptionResponse> {
  constructor(data?: PartialMessage<CreateSubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CreateSubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateSubscriptionResponse {
    return new CreateSubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateSubscriptionResponse {
    return new CreateSubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateSubscriptionResponse {
    return new CreateSubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateSubscriptionResponse | PlainMessage<CreateSubscriptionResponse> | undefined, b: CreateSubscriptionResponse | PlainMessage<CreateSubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(CreateSubscriptionResponse, a, b);
  }
}

/**
 * ChangeSubscriptionRequest is the request for ChangeSubscription.
 *
 * @generated from message nozzle.billing.v1.ChangeSubscriptionRequest
 */
export class ChangeSubscriptionRequest extends Message<ChangeSubscriptionRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * If plan_id is not set, the current plan will remain unchanged.
   *
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = "";

  /**
   * @generated from field: nozzle.billing.v1.Period base_period = 4;
   */
  basePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.Period overage_period = 5;
   */
  overagePeriod = Period.UNSPECIFIED;

  /**
   * this is only valid to set for admin users for overriding default behavior
   *
   * @generated from field: nozzle.billing.v1.ChangeTiming change_timing = 8;
   */
  changeTiming = ChangeTiming.UNSPECIFIED;

  /**
   * this is only valid to set for admin users, to either backdate the start of a subscription,
   * or to set a future start date
   *
   * @generated from field: google.type.Date start_date = 11;
   */
  startDate?: Date;

  /**
   * if any of the supplied coupon codes are invalid, they will be removed before creating the
   * estimate. Each coupon code will be returned in the response showing the current state. Only
   * COUPON_CODE_STATE_NOT_REDEEMED codes will be applied.
   *
   * @generated from field: repeated string coupon_codes = 9;
   */
  couponCodes: string[] = [];

  /**
   * optional customer PO number for this subscription - will be shown on invoices
   *
   * @generated from field: string po_number = 10;
   */
  poNumber = "";

  constructor(data?: PartialMessage<ChangeSubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ChangeSubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 5, name: "overage_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 8, name: "change_timing", kind: "enum", T: proto3.getEnumType(ChangeTiming) },
    { no: 11, name: "start_date", kind: "message", T: Date },
    { no: 9, name: "coupon_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "po_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeSubscriptionRequest {
    return new ChangeSubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeSubscriptionRequest {
    return new ChangeSubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeSubscriptionRequest {
    return new ChangeSubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeSubscriptionRequest | PlainMessage<ChangeSubscriptionRequest> | undefined, b: ChangeSubscriptionRequest | PlainMessage<ChangeSubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(ChangeSubscriptionRequest, a, b);
  }
}

/**
 * ChangeSubscriptionResponse is the response for ChangeSubscription.
 *
 * @generated from message nozzle.billing.v1.ChangeSubscriptionResponse
 */
export class ChangeSubscriptionResponse extends Message<ChangeSubscriptionResponse> {
  constructor(data?: PartialMessage<ChangeSubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ChangeSubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeSubscriptionResponse {
    return new ChangeSubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeSubscriptionResponse {
    return new ChangeSubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeSubscriptionResponse {
    return new ChangeSubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeSubscriptionResponse | PlainMessage<ChangeSubscriptionResponse> | undefined, b: ChangeSubscriptionResponse | PlainMessage<ChangeSubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(ChangeSubscriptionResponse, a, b);
  }
}

/**
 * EstimateChangeSubscriptionRequest is the request for EstimateChangeSubscription.
 *
 * @generated from message nozzle.billing.v1.EstimateChangeSubscriptionRequest
 */
export class EstimateChangeSubscriptionRequest extends Message<EstimateChangeSubscriptionRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = "";

  /**
   * @generated from field: nozzle.billing.v1.Period base_period = 4;
   */
  basePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.Period overage_period = 5;
   */
  overagePeriod = Period.UNSPECIFIED;

  /**
   * this is only valid to set for admin users for overriding default behavior.
   * if the subscription is currently in a trial and this is set to IMMEDIATE, the timing
   * response will incorrectly show that the change will take effect after the trial, when
   * in fact it will take effect immediately when ChangeSubscription is called.
   *
   * @generated from field: nozzle.billing.v1.ChangeTiming change_timing = 6;
   */
  changeTiming = ChangeTiming.UNSPECIFIED;

  /**
   * if any of the supplied coupon codes are invalid, the first invalid code will be returned as an error
   *
   * @generated from field: repeated string coupon_codes = 7;
   */
  couponCodes: string[] = [];

  constructor(data?: PartialMessage<EstimateChangeSubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.EstimateChangeSubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 5, name: "overage_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 6, name: "change_timing", kind: "enum", T: proto3.getEnumType(ChangeTiming) },
    { no: 7, name: "coupon_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateChangeSubscriptionRequest {
    return new EstimateChangeSubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateChangeSubscriptionRequest {
    return new EstimateChangeSubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateChangeSubscriptionRequest {
    return new EstimateChangeSubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateChangeSubscriptionRequest | PlainMessage<EstimateChangeSubscriptionRequest> | undefined, b: EstimateChangeSubscriptionRequest | PlainMessage<EstimateChangeSubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(EstimateChangeSubscriptionRequest, a, b);
  }
}

/**
 * EstimateChangeSubscriptionResponse is the response for EstimateChangeSubscription.
 *
 * @generated from message nozzle.billing.v1.EstimateChangeSubscriptionResponse
 */
export class EstimateChangeSubscriptionResponse extends Message<EstimateChangeSubscriptionResponse> {
  /**
   * @generated from field: google.type.Date term_start = 1;
   */
  termStart?: Date;

  /**
   * @generated from field: google.type.Date term_end = 2;
   */
  termEnd?: Date;

  /**
   * @generated from field: google.type.Date next_billing_at = 3;
   */
  nextBillingAt?: Date;

  /**
   * @generated from field: google.type.Money prorated_credits = 4;
   */
  proratedCredits?: Money;

  /**
   * @generated from field: google.type.Money amount_due = 5;
   */
  amountDue?: Money;

  /**
   * @generated from field: repeated nozzle.billing.v1.CouponCode coupon_codes = 6;
   */
  couponCodes: CouponCode[] = [];

  constructor(data?: PartialMessage<EstimateChangeSubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.EstimateChangeSubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "term_start", kind: "message", T: Date },
    { no: 2, name: "term_end", kind: "message", T: Date },
    { no: 3, name: "next_billing_at", kind: "message", T: Date },
    { no: 4, name: "prorated_credits", kind: "message", T: Money },
    { no: 5, name: "amount_due", kind: "message", T: Money },
    { no: 6, name: "coupon_codes", kind: "message", T: CouponCode, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EstimateChangeSubscriptionResponse {
    return new EstimateChangeSubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EstimateChangeSubscriptionResponse {
    return new EstimateChangeSubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EstimateChangeSubscriptionResponse {
    return new EstimateChangeSubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EstimateChangeSubscriptionResponse | PlainMessage<EstimateChangeSubscriptionResponse> | undefined, b: EstimateChangeSubscriptionResponse | PlainMessage<EstimateChangeSubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(EstimateChangeSubscriptionResponse, a, b);
  }
}

/**
 * CancelSubscriptionRequest is the request for CancelSubscription.
 *
 * @generated from message nozzle.billing.v1.CancelSubscriptionRequest
 */
export class CancelSubscriptionRequest extends Message<CancelSubscriptionRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  constructor(data?: PartialMessage<CancelSubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CancelSubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelSubscriptionRequest {
    return new CancelSubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelSubscriptionRequest {
    return new CancelSubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelSubscriptionRequest {
    return new CancelSubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CancelSubscriptionRequest | PlainMessage<CancelSubscriptionRequest> | undefined, b: CancelSubscriptionRequest | PlainMessage<CancelSubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(CancelSubscriptionRequest, a, b);
  }
}

/**
 * CancelSubscriptionResponse is the response for CancelSubscription.
 *
 * @generated from message nozzle.billing.v1.CancelSubscriptionResponse
 */
export class CancelSubscriptionResponse extends Message<CancelSubscriptionResponse> {
  constructor(data?: PartialMessage<CancelSubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.CancelSubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelSubscriptionResponse {
    return new CancelSubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelSubscriptionResponse {
    return new CancelSubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelSubscriptionResponse {
    return new CancelSubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CancelSubscriptionResponse | PlainMessage<CancelSubscriptionResponse> | undefined, b: CancelSubscriptionResponse | PlainMessage<CancelSubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(CancelSubscriptionResponse, a, b);
  }
}

/**
 * ModifyTrialRequest is the request for ModifyTrial.
 *
 * @generated from message nozzle.billing.v1.ModifyTrialRequest
 */
export class ModifyTrialRequest extends Message<ModifyTrialRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * This sets the new trial end date and will reactivate a subscription if it isn't active.
   *
   * @generated from field: google.protobuf.Timestamp trial_end_date = 3;
   */
  trialEndDate?: Timestamp;

  /**
   * This will change trial quantities for a given product.
   *
   * @generated from field: repeated nozzle.billing.v1.UsageTotal trial_usage = 4;
   */
  trialUsage: UsageTotal[] = [];

  constructor(data?: PartialMessage<ModifyTrialRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ModifyTrialRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "trial_end_date", kind: "message", T: Timestamp },
    { no: 4, name: "trial_usage", kind: "message", T: UsageTotal, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyTrialRequest {
    return new ModifyTrialRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyTrialRequest {
    return new ModifyTrialRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyTrialRequest {
    return new ModifyTrialRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyTrialRequest | PlainMessage<ModifyTrialRequest> | undefined, b: ModifyTrialRequest | PlainMessage<ModifyTrialRequest> | undefined): boolean {
    return proto3.util.equals(ModifyTrialRequest, a, b);
  }
}

/**
 * ModifyTrialResponse is the response for ModifyTrial.
 *
 * @generated from message nozzle.billing.v1.ModifyTrialResponse
 */
export class ModifyTrialResponse extends Message<ModifyTrialResponse> {
  constructor(data?: PartialMessage<ModifyTrialResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.ModifyTrialResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ModifyTrialResponse {
    return new ModifyTrialResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ModifyTrialResponse {
    return new ModifyTrialResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ModifyTrialResponse {
    return new ModifyTrialResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ModifyTrialResponse | PlainMessage<ModifyTrialResponse> | undefined, b: ModifyTrialResponse | PlainMessage<ModifyTrialResponse> | undefined): boolean {
    return proto3.util.equals(ModifyTrialResponse, a, b);
  }
}

/**
 * SubscriptionItem represents a specific type of usage and behavior. There are two main types of
 * subscription items: metered and non-metered. Metered items are billed based on usage, while
 * non-metered items are billed based on a fixed price.
 *
 * @generated from message nozzle.billing.v1.Subscription
 */
export class Subscription extends Message<Subscription> {
  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 2;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: string plan_id = 3;
   */
  planId = "";

  /**
   * @generated from field: nozzle.billing.v1.Period base_period = 4;
   */
  basePeriod = Period.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.Period overage_period = 5;
   */
  overagePeriod = Period.UNSPECIFIED;

  /**
   * Business Pro (Display Name)
   *
   * @generated from field: string name = 6;
   */
  name = "";

  /**
   * @generated from field: google.type.Money base_price = 7;
   */
  basePrice?: Money;

  /**
   * @generated from field: google.type.Money max_overage_amount = 22;
   */
  maxOverageAmount?: Money;

  /**
   * @generated from field: google.type.Money current_overage_amount = 23;
   */
  currentOverageAmount?: Money;

  /**
   * @generated from field: nozzle.billing.v1.SubscriptionState state = 8;
   */
  state = SubscriptionState.UNSPECIFIED;

  /**
   * @generated from field: repeated nozzle.billing.v1.MeteredSubscription metered_subscriptions = 9;
   */
  meteredSubscriptions: MeteredSubscription[] = [];

  /**
   * Coupons are a superset of rewards. All rewards are expressed as coupons, but we will
   * also support coupons that are not directly tied to a reward, for marketing discounts,
   * for customer service, etc.
   *
   * @generated from field: repeated nozzle.billing.v1.Coupon coupons = 10;
   */
  coupons: Coupon[] = [];

  /**
   * @generated from field: google.type.Date trial_start = 11;
   */
  trialStart?: Date;

  /**
   * @generated from field: google.type.Date trial_end = 12;
   */
  trialEnd?: Date;

  /**
   * @generated from field: google.protobuf.Timestamp cancel_requested_at = 13;
   */
  cancelRequestedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp canceled_at = 14;
   */
  canceledAt?: Timestamp;

  /**
   * @generated from field: nozzle.billing.v1.CancelReason cancel_reason = 15;
   */
  cancelReason = CancelReason.UNSPECIFIED;

  /**
   * A future subscription will be populated in the event that the customer has taken action that
   * will be executed at the end of the current term, e.g. a downgrade. Only a small subset of the
   * fields in the future subscription are populated:
   * - item_price_id
   * - period
   * - is_metered
   * - base_price
   * - included_quantity
   * - overage_price
   * - current_term_start
   *
   * Future subscription is NOT populated for anticipated trial conversions. Upgrades are executed
   * immediately, so they will never be visible in the future subscription.
   *
   * @generated from field: nozzle.billing.v1.Subscription future_subscription = 16;
   */
  futureSubscription?: Subscription;

  /**
   * optional customer PO number for this subscription - will be shown on invoices
   *
   * @generated from field: string po_number = 21;
   */
  poNumber = "";

  /**
   * @generated from field: google.protobuf.Timestamp started_at = 17;
   */
  startedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp activated_at = 18;
   */
  activatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 19;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 20;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Subscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Subscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "base_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 5, name: "overage_period", kind: "enum", T: proto3.getEnumType(Period) },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "base_price", kind: "message", T: Money },
    { no: 22, name: "max_overage_amount", kind: "message", T: Money },
    { no: 23, name: "current_overage_amount", kind: "message", T: Money },
    { no: 8, name: "state", kind: "enum", T: proto3.getEnumType(SubscriptionState) },
    { no: 9, name: "metered_subscriptions", kind: "message", T: MeteredSubscription, repeated: true },
    { no: 10, name: "coupons", kind: "message", T: Coupon, repeated: true },
    { no: 11, name: "trial_start", kind: "message", T: Date },
    { no: 12, name: "trial_end", kind: "message", T: Date },
    { no: 13, name: "cancel_requested_at", kind: "message", T: Timestamp },
    { no: 14, name: "canceled_at", kind: "message", T: Timestamp },
    { no: 15, name: "cancel_reason", kind: "enum", T: proto3.getEnumType(CancelReason) },
    { no: 16, name: "future_subscription", kind: "message", T: Subscription },
    { no: 21, name: "po_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "started_at", kind: "message", T: Timestamp },
    { no: 18, name: "activated_at", kind: "message", T: Timestamp },
    { no: 19, name: "created_at", kind: "message", T: Timestamp },
    { no: 20, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Subscription {
    return new Subscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Subscription {
    return new Subscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Subscription {
    return new Subscription().fromJsonString(jsonString, options);
  }

  static equals(a: Subscription | PlainMessage<Subscription> | undefined, b: Subscription | PlainMessage<Subscription> | undefined): boolean {
    return proto3.util.equals(Subscription, a, b);
  }
}

/**
 * MeteredSubscription is the specific MeteredPlan for a specific UsageType.
 *
 * @generated from message nozzle.billing.v1.MeteredSubscription
 */
export class MeteredSubscription extends Message<MeteredSubscription> {
  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 2;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * @generated from field: google.type.Money unit_price = 3;
   */
  unitPrice?: Money;

  /**
   * The number of units that are included in the base price.
   *
   * @generated from field: int64 included_quantity = 4;
   */
  includedQuantity = protoInt64.zero;

  /**
   * If any coupons / rewards are applied, this is the number of units that can
   * be consumed without incurring any overage charges.
   *
   * @generated from field: int64 coupon_quantity = 5;
   */
  couponQuantity = protoInt64.zero;

  /**
   * This represents the current usage. This may lag behind the actual usage,
   * but typically no more than a few minutes.
   *
   * @generated from field: int64 consumed_quantity = 6;
   */
  consumedQuantity = protoInt64.zero;

  /**
   * max_overage_quantity is the maximum number of units of usage_type that can be used.
   * Defaults to 0, and there is no way to set this to be uncapped. You can however set
   * a high number to get the same effect.
   *
   * @generated from field: int64 max_overage_quantity = 7;
   */
  maxOverageQuantity = protoInt64.zero;

  /**
   * current_overage_quantity is the number of units of usage_type that have been used that
   * exceeds included_quantity + coupon_quantity during the current billing period.
   *
   * @generated from field: int64 current_overage_quantity = 8;
   */
  currentOverageQuantity = protoInt64.zero;

  constructor(data?: PartialMessage<MeteredSubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.MeteredSubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 3, name: "unit_price", kind: "message", T: Money },
    { no: 4, name: "included_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "coupon_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "consumed_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "max_overage_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "current_overage_quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MeteredSubscription {
    return new MeteredSubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MeteredSubscription {
    return new MeteredSubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MeteredSubscription {
    return new MeteredSubscription().fromJsonString(jsonString, options);
  }

  static equals(a: MeteredSubscription | PlainMessage<MeteredSubscription> | undefined, b: MeteredSubscription | PlainMessage<MeteredSubscription> | undefined): boolean {
    return proto3.util.equals(MeteredSubscription, a, b);
  }
}

