import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceOverviewMetricsWidget,
  PerformanceOverviewHistoryWidget,
  PerformanceOverviewSegmentsWidget,
  PerformanceOverviewKeywordGroupsWidget,
  PerformanceOverviewUrlsWidget,
  PerformanceOverviewKeywordsWidget,
} from '../../../containers/Dashboards/PerformanceOverview'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-overview'
)({
  validateSearch: search =>
    search as {
      performanceOverviewMetricsWidget?: PerformanceOverviewMetricsWidget
      performanceOverviewHistoryWidget?: PerformanceOverviewHistoryWidget
      performanceOverviewSegmentsWidget?: PerformanceOverviewSegmentsWidget
      performanceOverviewKeywordGroupsWidget?: PerformanceOverviewKeywordGroupsWidget
      performanceOverviewUrlsWidget?: PerformanceOverviewUrlsWidget
      performanceOverviewKeywordsWidget?: PerformanceOverviewKeywordsWidget
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceOverviewMetricsWidget:
          search.performanceOverviewMetricsWidget,
        performanceOverviewHistoryWidget:
          search.performanceOverviewHistoryWidget,
        performanceOverviewSegmentsWidget:
          search.performanceOverviewSegmentsWidget,
        performanceOverviewKeywordGroupsWidget:
          search.performanceOverviewKeywordGroupsWidget,
        performanceOverviewUrlsWidget: search.performanceOverviewUrlsWidget,
        performanceOverviewKeywordsWidget:
          search.performanceOverviewKeywordsWidget,
      }
    },
  ],
})
