import moment from 'moment'
import { someObj } from '../utils'

const currentYear = moment().year() - 2000
const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
const ebayRegex =
  /^https?:\/\/www\.ebay\.com\/str\/[-a-zA-Z0-9@:%._+~#=]{1,256}/
const amazonRegex =
  /^https?:\/\/www\.amazon\.com\/stores\/(eneloop\/)?page\/[-a-zA-Z0-9@:%._+~#=]{1,256}/
const walmartRegex =
  /^https?:\/\/www\.walmart\.com\/search\/\?query=&cat_id=0&facet=retailer:[-a-zA-Z0-9@:%._+~#=]{1,256}/
// const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/

export const forbiddenPhrases = [
  'cache:',
  'site:',
  'filetype:',
  'link:',
  'related:',
  'info:',
  'define:',
  'stocks:',
  'weather:',
  'music:',
  'movie:',
  'intitle:',
  'allintitle:',
  'intext:',
  'inurl:',
  'allinurl:',
  'allinanchor:',
  'inanchor:',
]

const Validate = {
  chain: (...args: any[]) => {
    return (value: any) => {
      for (let i = 0; i < args.length; i++) {
        const validator = args[i]
        if (validator) {
          const res = validator(value)
          if (res) {
            return res
          }
        }
      }
      return false
    }
  },

  required:
    (message = 'Required') =>
    (val: any) =>
      (Array.isArray(val) ? !val.length : !val) ? message : false,

  minItems:
    (message: any, len = 1) =>
    (value: any) =>
      value.length >= len ? false : message,

  minLength: (len: any) => (value: any) =>
    value.length >= len ? false : `Must be at least ${len} characters.`,
  maxLength: (len: any) => (value: any) =>
    value.length <= len ? false : `Must be at most ${len} characters.`,

  creditCard:
    () =>
    (num = '') => {
      num = String(num)
      num = num.replace(/[^0-9]/g, '')

      if (!num) {
        return 'A valid credit card is required'
      }

      if (
        num.substring(0, 1) === '3' &&
        (num.substring(1, 2) === '4' || num.substring(1, 2) === '7')
      ) {
        if (num.length !== 15) {
          return 'An American Express card must have exactly 15 digits'
        }

        return false
      }

      if (num.length !== 16) {
        return 'Credit card number must have exactly 16 digits'
      }

      return false
    },

  creditCardExpYear: () => (year: any) => {
    year = Number(year)
    return (
      !year ||
      year < currentYear ||
      (year > currentYear + 10 &&
        `Year must be between '${currentYear} and '${currentYear + 10}`) ||
      false
    )
  },

  email:
    () =>
    (val = '') => {
      if (!val) {
        return 'A valid email is required'
      }
      if (!emailRegex.test(val)) {
        return 'A valid email is required'
      }
      return false
    },

  ebay:
    () =>
    (val = '') => {
      if (!val) {
        return 'A valid url is required.'
      }
      if (!ebayRegex.test(val)) {
        return 'A valid url is required. Example: "https://www.ebay.com/str/USERNAME"'
      }
      return false
    },

  amazon:
    () =>
    (val = '') => {
      if (!val) {
        return 'A valid url is required.'
      }
      if (!amazonRegex.test(val)) {
        return 'A valid url is required. Example: "https://www.amazon.com/stores/page/PAGE_ID"'
      }
      return false
    },

  walmart:
    () =>
    (val = '') => {
      if (!val) {
        return 'A valid url is required.'
      }
      if (!walmartRegex.test(val)) {
        return 'A valid url is required. Example: "https://www.walmart.com/search/?query=&cat_id=0&facet=retailer:MARKETPLACE%20NAME"'
      }
      return false
    },

  url:
    () =>
    (val = '') => {
      if (!val) {
        return 'A valid url is required.'
      }
      try {
        new URL(val)
        return false
      } catch (err) {
        return 'A valid url is required. Example: "https://www.nozzle.io"'
      }
    },

  creditCardCVC: () => (val: any) => {
    if (!val) {
      return 'A CVC is required'
    }
    if (String(val).length > 4) {
      return 'A valid CVC is required'
    }

    return false
  },

  conditionSimpleVal: () => (val: any) => {
    if (!val || !val.length) {
      return 'At least one value is required'
    }

    return false
  },

  lowercase: () => (val: any) => {
    const split = val.split('')
    return split.some((d: any) => d.toLowerCase() !== d)
      ? 'Must only contain lower case letters.'
      : false
  },

  slug: () =>
    Validate.chain(
      Validate.minLength(3),
      (val: any) => {
        const rx = /[^a-z0-9]/gm
        return rx.test(val)
          ? 'May only contain lower case letters/numbers.'
          : false
      },
      Validate.maxLength(14)
    ),

  // Compound

  userName: () => Validate.required('A user name is required.'),
  projectName: () => Validate.required('A project name is required.'),
  projectDefaultSchedule: () =>
    Validate.required('A default schedule is required.'),
  projectSlug: () =>
    Validate.chain(
      Validate.required('A project slug is required.'),
      Validate.slug()
    ),

  workspaceName: () => Validate.required('A workspace name is required.'),
  workspaceSlug: () =>
    Validate.chain(
      Validate.required('A workspace slug is required.'),
      Validate.slug()
    ),

  segmentName: () => Validate.required(`A segment name is required.`),
  segmentDescription: () =>
    Validate.required(`A segment description is required.`),
  keywordSourceSchedule: () => Validate.required('A schedule is required.'),
  keywordSourcePhraseGroups: () => (phraseGroups: any) => {
    return !phraseGroups || !phraseGroups.length
      ? 'At least one keyword phrase is required.'
      : false
  },
  keywordSourcePhrases: (required?: boolean) => (keywords: any) =>
    required && !keywords?.length
      ? 'At least one keyword phrase is required.'
      : forbiddenPhrases.filter(phrase => keywords?.includes(phrase)).length
      ? `You cannot use "${
          forbiddenPhrases.filter(phrase => keywords?.includes(phrase))[0]
        }" in your phrases. Please remove this to continue.`
      : false,
}

export default Validate

export const validateBrandName = () =>
  Validate.required(`A brand name is required.`)
export const validateBrandType = () =>
  Validate.required(`A brand type is required.`)

export const validateConditionErrors = () => (conditionsErrors: any) => {
  if (!conditionsErrors) {
    return
  }

  if (someObj(conditionsErrors, d => d)) {
    return 'All conditions must be valid.'
  }

  if (!Object.keys(conditionsErrors).length) {
    return 'At least one condition is required.'
  }
}

export const validateCondition = () => (condition: any) => {
  const errors: {
    matchType?: string
    columnId?: string | false
    operator?: string | false
    values?: string | false
    conditions?: string
  } = {}

  if (!condition) {
    errors.matchType = 'A condition is required'
  } else if (!condition.matchType) {
    errors.matchType = 'A condition match type is required'
  } else if (condition.matchType === 'expression') {
    errors.columnId = Validate.required('A column is required')(
      condition.columnId
    )
    errors.operator = Validate.required('An operator is required')(
      condition.operator
    )
    errors.values = Validate.required('At least one value is required')(
      condition.values?.length
    )
  } else if (!condition.conditions?.length) {
    errors.conditions = 'At least one sub condition is required'
  } else {
    errors.conditions = condition.conditions.map((condition: any) =>
      validateCondition()(condition)
    )

    if (!someObj(errors.conditions, d => d)) {
      delete errors.conditions
    }
  }

  if (someObj(errors, d => d)) {
    return errors
  }
}

export const validateDomain = () => (value: any) => {
  return /^(?!https?:\/\/)(?!www\.)[a-zA-Z0-9.\-/]+$/.test(value)
    ? ''
    : 'Please enter a valid domain. Example: "nozzle.io"';
}

export function validateNumber(message = 'A valid number is required/') {
  return (val: any) => {
    if (!val) {
      return false
    }

    return !/^[0-9]+$/.test(val) ? message : false;
  };
}
