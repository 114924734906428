// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/billing/v1/usage.proto (package nozzle.billing.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Date } from "../../../google/type/date_pb.js";

/**
 * UsageView determines which fields of the Usage are returned in the response.
 *
 * @generated from enum nozzle.billing.v1.UsageView
 */
export enum UsageView {
  /**
   * Will default to USAGE_VIEW_BASIC.
   *
   * @generated from enum value: USAGE_VIEW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * This only returns the usage totals, not the daily breakdowns.
   *
   * @generated from enum value: USAGE_VIEW_BASIC = 1;
   */
  BASIC = 1,

  /**
   * This returns all the data, including daily breakdowns.
   *
   * @generated from enum value: USAGE_VIEW_FULL = 2;
   */
  FULL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UsageView)
proto3.util.setEnumType(UsageView, "nozzle.billing.v1.UsageView", [
  { no: 0, name: "USAGE_VIEW_UNSPECIFIED" },
  { no: 1, name: "USAGE_VIEW_BASIC" },
  { no: 2, name: "USAGE_VIEW_FULL" },
]);

/**
 * UsageType is used to identify what type of usage is being reported.
 *
 * @generated from enum nozzle.billing.v1.UsageType
 */
export enum UsageType {
  /**
   * @generated from enum value: USAGE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USAGE_TYPE_SERP = 1;
   */
  SERP = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(UsageType)
proto3.util.setEnumType(UsageType, "nozzle.billing.v1.UsageType", [
  { no: 0, name: "USAGE_TYPE_UNSPECIFIED" },
  { no: 1, name: "USAGE_TYPE_SERP" },
]);

/**
 * UsageState represents the current usage status.
 *
 * @generated from enum nozzle.billing.v1.UsageState
 */
export enum UsageState {
  /**
   * @generated from enum value: USAGE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USAGE_STATE_OK = 1;
   */
  OK = 1,

  /**
   * @generated from enum value: USAGE_STATE_WARNING = 2;
   */
  WARNING = 2,

  /**
   * @generated from enum value: USAGE_STATE_EXHAUSTED = 3;
   */
  EXHAUSTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(UsageState)
proto3.util.setEnumType(UsageState, "nozzle.billing.v1.UsageState", [
  { no: 0, name: "USAGE_STATE_UNSPECIFIED" },
  { no: 1, name: "USAGE_STATE_OK" },
  { no: 2, name: "USAGE_STATE_WARNING" },
  { no: 3, name: "USAGE_STATE_EXHAUSTED" },
]);

/**
 * UsageEventType represents the type of event.
 *
 * @generated from enum nozzle.billing.v1.UsageEventType
 */
export enum UsageEventType {
  /**
   * @generated from enum value: USAGE_EVENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_RESET = 1;
   */
  RESET = 1,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_EIGHTY_PERCENT_INCLUDED_PLUS_COUPON = 2;
   */
  EIGHTY_PERCENT_INCLUDED_PLUS_COUPON = 2,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_HUNDRED_PERCENT_INCLUDED_PLUS_COUPON = 3;
   */
  HUNDRED_PERCENT_INCLUDED_PLUS_COUPON = 3,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_EIGHTY_PERCENT_MAX_OVERAGE = 4;
   */
  EIGHTY_PERCENT_MAX_OVERAGE = 4,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_HUNDRED_PERCENT_MAX_OVERAGE = 5;
   */
  HUNDRED_PERCENT_MAX_OVERAGE = 5,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_EIGHTY_PERCENT_MAX_TOTAL = 6;
   */
  EIGHTY_PERCENT_MAX_TOTAL = 6,

  /**
   * @generated from enum value: USAGE_EVENT_TYPE_HUNDRED_PERCENT_MAX_TOTAL = 7;
   */
  HUNDRED_PERCENT_MAX_TOTAL = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(UsageEventType)
proto3.util.setEnumType(UsageEventType, "nozzle.billing.v1.UsageEventType", [
  { no: 0, name: "USAGE_EVENT_TYPE_UNSPECIFIED" },
  { no: 1, name: "USAGE_EVENT_TYPE_RESET" },
  { no: 2, name: "USAGE_EVENT_TYPE_EIGHTY_PERCENT_INCLUDED_PLUS_COUPON" },
  { no: 3, name: "USAGE_EVENT_TYPE_HUNDRED_PERCENT_INCLUDED_PLUS_COUPON" },
  { no: 4, name: "USAGE_EVENT_TYPE_EIGHTY_PERCENT_MAX_OVERAGE" },
  { no: 5, name: "USAGE_EVENT_TYPE_HUNDRED_PERCENT_MAX_OVERAGE" },
  { no: 6, name: "USAGE_EVENT_TYPE_EIGHTY_PERCENT_MAX_TOTAL" },
  { no: 7, name: "USAGE_EVENT_TYPE_HUNDRED_PERCENT_MAX_TOTAL" },
]);

/**
 * ProductFamily represents a specific product line/family.
 *
 * @generated from enum nozzle.billing.v1.ProductFamily
 */
export enum ProductFamily {
  /**
   * @generated from enum value: PRODUCT_FAMILY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_FAMILY_STANDARD = 1;
   */
  STANDARD = 1,

  /**
   * @generated from enum value: PRODUCT_FAMILY_AUTO = 2;
   */
  AUTO = 2,

  /**
   * @generated from enum value: PRODUCT_FAMILY_NEWS = 3;
   */
  NEWS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductFamily)
proto3.util.setEnumType(ProductFamily, "nozzle.billing.v1.ProductFamily", [
  { no: 0, name: "PRODUCT_FAMILY_UNSPECIFIED" },
  { no: 1, name: "PRODUCT_FAMILY_STANDARD" },
  { no: 2, name: "PRODUCT_FAMILY_AUTO" },
  { no: 3, name: "PRODUCT_FAMILY_NEWS" },
]);

/**
 * GetUsageRequest is the request for GetUsage.
 *
 * @generated from message nozzle.billing.v1.GetUsageRequest
 */
export class GetUsageRequest extends Message<GetUsageRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: google.type.Date start = 2;
   */
  start?: Date;

  /**
   * @generated from field: google.type.Date end = 3;
   */
  end?: Date;

  /**
   * @generated from field: nozzle.billing.v1.UsageView view = 4;
   */
  view = UsageView.UNSPECIFIED;

  constructor(data?: PartialMessage<GetUsageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetUsageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "start", kind: "message", T: Date },
    { no: 3, name: "end", kind: "message", T: Date },
    { no: 4, name: "view", kind: "enum", T: proto3.getEnumType(UsageView) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageRequest {
    return new GetUsageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined, b: GetUsageRequest | PlainMessage<GetUsageRequest> | undefined): boolean {
    return proto3.util.equals(GetUsageRequest, a, b);
  }
}

/**
 * GetUsageResponse is the response for GetUsage.
 *
 * @generated from message nozzle.billing.v1.GetUsageResponse
 */
export class GetUsageResponse extends Message<GetUsageResponse> {
  /**
   * @generated from field: nozzle.billing.v1.UsageByWorkspace usage_by_workspace = 1;
   */
  usageByWorkspace?: UsageByWorkspace;

  constructor(data?: PartialMessage<GetUsageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.GetUsageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usage_by_workspace", kind: "message", T: UsageByWorkspace },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUsageResponse {
    return new GetUsageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined, b: GetUsageResponse | PlainMessage<GetUsageResponse> | undefined): boolean {
    return proto3.util.equals(GetUsageResponse, a, b);
  }
}

/**
 * UpdateUsageStateRequest is the request for UpdateUsageState.
 *
 * @generated from message nozzle.billing.v1.UpdateUsageStateRequest
 */
export class UpdateUsageStateRequest extends Message<UpdateUsageStateRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: google.type.Date date = 2;
   */
  date?: Date;

  /**
   * force_update if true, retrieves the most recent pricing data, otherwise
   * uses the cached pricing data. If there is no cached pricing data, it will
   * retrieve the most recent pricing data, same as if force_update was true.
   *
   * @generated from field: bool force_update = 3;
   */
  forceUpdate = false;

  constructor(data?: PartialMessage<UpdateUsageStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateUsageStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "date", kind: "message", T: Date },
    { no: 3, name: "force_update", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUsageStateRequest {
    return new UpdateUsageStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUsageStateRequest {
    return new UpdateUsageStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUsageStateRequest {
    return new UpdateUsageStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUsageStateRequest | PlainMessage<UpdateUsageStateRequest> | undefined, b: UpdateUsageStateRequest | PlainMessage<UpdateUsageStateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUsageStateRequest, a, b);
  }
}

/**
 * UpdateUsageStateResponse is the response for UpdateUsageState.
 *
 * @generated from message nozzle.billing.v1.UpdateUsageStateResponse
 */
export class UpdateUsageStateResponse extends Message<UpdateUsageStateResponse> {
  constructor(data?: PartialMessage<UpdateUsageStateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UpdateUsageStateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUsageStateResponse {
    return new UpdateUsageStateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUsageStateResponse {
    return new UpdateUsageStateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUsageStateResponse {
    return new UpdateUsageStateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUsageStateResponse | PlainMessage<UpdateUsageStateResponse> | undefined, b: UpdateUsageStateResponse | PlainMessage<UpdateUsageStateResponse> | undefined): boolean {
    return proto3.util.equals(UpdateUsageStateResponse, a, b);
  }
}

/**
 * UsageByWorkspace stores the usage breakdown by workspace and project per day.
 *
 * @generated from message nozzle.billing.v1.UsageByWorkspace
 */
export class UsageByWorkspace extends Message<UsageByWorkspace> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * usage_totals has a single usage record for each UsageType, summing all the usage
   * across the time range.
   *
   * @generated from field: repeated nozzle.billing.v1.UsageTotal usage_totals = 2;
   */
  usageTotals: UsageTotal[] = [];

  /**
   * usage_by_date has a single record for every date * UsageType,
   * ordered by UsageType, then by date.
   *
   * @generated from field: repeated nozzle.billing.v1.Usage usage_by_date = 3;
   */
  usageByDate: Usage[] = [];

  /**
   * @generated from field: repeated nozzle.billing.v1.UsageByProject usage_by_project = 4;
   */
  usageByProject: UsageByProject[] = [];

  constructor(data?: PartialMessage<UsageByWorkspace>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UsageByWorkspace";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "usage_totals", kind: "message", T: UsageTotal, repeated: true },
    { no: 3, name: "usage_by_date", kind: "message", T: Usage, repeated: true },
    { no: 4, name: "usage_by_project", kind: "message", T: UsageByProject, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsageByWorkspace {
    return new UsageByWorkspace().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsageByWorkspace {
    return new UsageByWorkspace().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsageByWorkspace {
    return new UsageByWorkspace().fromJsonString(jsonString, options);
  }

  static equals(a: UsageByWorkspace | PlainMessage<UsageByWorkspace> | undefined, b: UsageByWorkspace | PlainMessage<UsageByWorkspace> | undefined): boolean {
    return proto3.util.equals(UsageByWorkspace, a, b);
  }
}

/**
 * UsageByProject stores the usage breakdown by project per day.
 *
 * @generated from message nozzle.billing.v1.UsageByProject
 */
export class UsageByProject extends Message<UsageByProject> {
  /**
   * @generated from field: int64 project_id = 1;
   */
  projectId = protoInt64.zero;

  /**
   * usage_totals has a single usage record for each UsageType, summing all the usage
   * across the time range.
   *
   * @generated from field: repeated nozzle.billing.v1.UsageTotal usage_totals = 2;
   */
  usageTotals: UsageTotal[] = [];

  /**
   * usage_by_date has a single record for every date * UsageType,
   * ordered by UsageType, then by date.
   *
   * @generated from field: repeated nozzle.billing.v1.Usage usage_by_date = 3;
   */
  usageByDate: Usage[] = [];

  constructor(data?: PartialMessage<UsageByProject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UsageByProject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "usage_totals", kind: "message", T: UsageTotal, repeated: true },
    { no: 3, name: "usage_by_date", kind: "message", T: Usage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsageByProject {
    return new UsageByProject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsageByProject {
    return new UsageByProject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsageByProject {
    return new UsageByProject().fromJsonString(jsonString, options);
  }

  static equals(a: UsageByProject | PlainMessage<UsageByProject> | undefined, b: UsageByProject | PlainMessage<UsageByProject> | undefined): boolean {
    return proto3.util.equals(UsageByProject, a, b);
  }
}

/**
 * UsageTotal is used for aggregating usage across a time range.
 *
 * @generated from message nozzle.billing.v1.UsageTotal
 */
export class UsageTotal extends Message<UsageTotal> {
  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 3;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 1;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * @generated from field: int64 quantity = 2;
   */
  quantity = protoInt64.zero;

  constructor(data?: PartialMessage<UsageTotal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UsageTotal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 1, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 2, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsageTotal {
    return new UsageTotal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsageTotal {
    return new UsageTotal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsageTotal {
    return new UsageTotal().fromJsonString(jsonString, options);
  }

  static equals(a: UsageTotal | PlainMessage<UsageTotal> | undefined, b: UsageTotal | PlainMessage<UsageTotal> | undefined): boolean {
    return proto3.util.equals(UsageTotal, a, b);
  }
}

/**
 * Usage represents a single usage type and the quantity.
 *
 * @generated from message nozzle.billing.v1.Usage
 */
export class Usage extends Message<Usage> {
  /**
   * @generated from field: int64 usage_id = 1;
   */
  usageId = protoInt64.zero;

  /**
   * @generated from field: google.type.Date date = 2;
   */
  date?: Date;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 7;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 3;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * @generated from field: int64 quantity = 4;
   */
  quantity = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Usage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.Usage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usage_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "date", kind: "message", T: Date },
    { no: 7, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 3, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 4, name: "quantity", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Usage {
    return new Usage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Usage {
    return new Usage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Usage {
    return new Usage().fromJsonString(jsonString, options);
  }

  static equals(a: Usage | PlainMessage<Usage> | undefined, b: Usage | PlainMessage<Usage> | undefined): boolean {
    return proto3.util.equals(Usage, a, b);
  }
}

/**
 * UsageEvent represents a single workspace usage event.
 *
 * @generated from message nozzle.billing.v1.UsageEvent
 */
export class UsageEvent extends Message<UsageEvent> {
  /**
   * @generated from field: int64 event_id = 1;
   */
  eventId = protoInt64.zero;

  /**
   * @generated from field: int64 workspace_id = 2;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.billing.v1.ProductFamily product_family = 3;
   */
  productFamily = ProductFamily.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.UsageType usage_type = 4;
   */
  usageType = UsageType.UNSPECIFIED;

  /**
   * @generated from field: nozzle.billing.v1.UsageEventType event_type = 5;
   */
  eventType = UsageEventType.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<UsageEvent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.billing.v1.UsageEvent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "product_family", kind: "enum", T: proto3.getEnumType(ProductFamily) },
    { no: 4, name: "usage_type", kind: "enum", T: proto3.getEnumType(UsageType) },
    { no: 5, name: "event_type", kind: "enum", T: proto3.getEnumType(UsageEventType) },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsageEvent {
    return new UsageEvent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsageEvent {
    return new UsageEvent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsageEvent {
    return new UsageEvent().fromJsonString(jsonString, options);
  }

  static equals(a: UsageEvent | PlainMessage<UsageEvent> | undefined, b: UsageEvent | PlainMessage<UsageEvent> | undefined): boolean {
    return proto3.util.equals(UsageEvent, a, b);
  }
}

