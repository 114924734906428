export const locationTypeOptions = [
  { label: 'Airport', value: 'Airport', locationType: 1 },
  {
    label: 'Autonomous Community',
    value: 'Autonomous Community',
    locationType: 2,
  },
  { label: 'Borough', value: 'Borough', locationType: 3 },
  { label: 'Canton', value: 'Canton', locationType: 4 },
  { label: 'City', value: 'City', locationType: 5 },
  { label: 'City Region', value: 'City Region', locationType: 6 },
  {
    label: 'Congressional District',
    value: 'Congressional District',
    locationType: 7,
  },
  { label: 'Country', value: 'Country', locationType: 8 },
  { label: 'County', value: 'County', locationType: 9 },
  { label: 'Department', value: 'Department', locationType: 10 },
  { label: 'District', value: 'District', locationType: 11 },
  { label: 'Governorate', value: 'Governorate', locationType: 12 },
  { label: 'Municipality', value: 'Municipality', locationType: 13 },
  { label: 'National Park', value: 'National Park', locationType: 14 },
  { label: 'Neighborhood', value: 'Neighborhood', locationType: 15 },
  { label: 'Okrug', value: 'Okrug', locationType: 16 },
  { label: 'Other', value: 'Other', locationType: 17 },
  { label: 'Postal Code', value: 'Postal Code', locationType: 18 },
  { label: 'Prefecture', value: 'Prefecture', locationType: 19 },
  { label: 'Province', value: 'Province', locationType: 20 },
  { label: 'Region', value: 'Region', locationType: 21 },
  { label: 'State', value: 'State', locationType: 22 },
  { label: 'Territory', value: 'Territory', locationType: 23 },
  { label: 'TV Region', value: 'TV Region', locationType: 24 },
  { label: 'Union Territory', value: 'Union Territory', locationType: 25 },
  { label: 'University', value: 'University', locationType: 26 },
]

export function formatLocationTypePb(locationType: number) {
  return locationTypeOptions.find(
    option => option.locationType === locationType
  )?.label
}
