import { useNavigate, useRouter, useSearch } from '@tanstack/react-router'
import * as React from 'react'
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { Workspace } from '../../openapi'
import useLoading from '../hooks/useLoading'
import useToast from '../hooks/useToast'
import { last, sortBy } from '../utils'
import {
  deleteWorkspace,
  patchWorkspace,
  postWorkspace,
  setDebugWorkspace,
} from '../utils/Api'
import { queryKeyWorkspaces } from '../utils/Constants'
import LocalStorage from '../utils/LocalStorage'
import { useProfileQuery } from './profile'
import useErrorPopup from './useErrorPopup'
import usePopup from './usePopup'
import useZustand from './useZustand'

export function useWorkspacesQuery() {
  const profileQuery = useProfileQuery()

  return React.useMemo(
    () => ({
      ...profileQuery,
      data: profileQuery.data?.userWorkspaces.map(d => d.workspace),
    }),
    [profileQuery]
  ) as UseQueryResult<Workspace[], unknown>
}

export function useWorkspace({ id }: any) {
  const profileQuery = useProfileQuery()

  return {
    ...profileQuery,
    data: profileQuery.data?.userWorkspaces.find(d => d.workspace.id === id)
      ?.workspace,
  }
}

//

export function useWorkspaceOptions() {
  const profileQuery = useProfileQuery()
  const userWorkspaces = profileQuery.data?.userWorkspaces

  return React.useMemo(() => {
    return sortBy(
      (userWorkspaces ?? []).map(d => ({
        value: d.workspace,
        label: `${d.workspace.name} (${d.workspace.slug})`,
      })),
      d => d.label.toLowerCase()
    )
  }, [userWorkspaces])
}

export function useActiveWorkspace() {
  const { workspaceSlug, workspaceId } = useSearch<any, any, any, any>({
    strict: false,
  })
  const profileQuery = useProfileQuery()

  const [lastWorkspaceId, setStore] = useZustand(state => state.lastWorkspaceId)

  const matches: Record<
    string,
    [string, Workspace] | undefined | [undefined, undefined]
  > = {
    workspaceId: undefined,
    workspaceSlug: undefined,
    localStorage: undefined,
    server: undefined,
  }

  const activeWorkspaceQuery = useQuery({
    queryKey: [
      'activeWorkspace',
      {
        profileUpdatedAt: profileQuery.dataUpdatedAt,
        workspaceId,
        workspaceSlug,
        lastWorkspaceId,
      },
    ],
    queryFn: () => {
      profileQuery.data?.userWorkspaces.forEach(d => {
        if (d.workspace.id === workspaceId) {
          matches.workspaceId = [d.workspace.id, d.workspace]
        }
        if (d.workspace.slug === workspaceSlug) {
          matches.workspaceSlug = [d.workspace.slug, d.workspace]
        }
        if (d.workspace.id === lastWorkspaceId) {
          matches.localStorage = [d.workspace.id, d.workspace]
        }
        if (d.workspace.id === profileQuery.data.lastWorkspaceId) {
          matches.server = [d.workspace.id, d.workspace]
        }
      })

      const [activeWorkspaceId, activeWorkspace] = matches.workspaceId ||
        matches.workspaceSlug ||
        matches.localStorage ||
        matches.server || [undefined, undefined]

      return [activeWorkspaceId, activeWorkspace] as const
    },
  })

  const [activeWorkspaceId, activeWorkspace] = activeWorkspaceQuery.data || [
    undefined,
    undefined,
  ]

  // Syncs last workspace slug
  React.useEffect(() => {
    if (!activeWorkspace) {
      return
    }

    if (lastWorkspaceId !== activeWorkspace.id) {
      // queryClient.invalidateQueries()

      setStore(old => {
        old.lastWorkspaceId = activeWorkspace.id
      })

      LocalStorage.set('lastWorkspaceId', activeWorkspace.id)

      setDebugWorkspace(activeWorkspace)
    }
  }, [activeWorkspace, lastWorkspaceId, setStore])

  const defaultWorkspace = React.useMemo(
    () => ({
      id:
        activeWorkspaceId ||
        workspaceId ||
        lastWorkspaceId ||
        profileQuery.data?.lastWorkspaceId,
      slug: '',
      name: '',
    }),
    [
      activeWorkspaceId,
      lastWorkspaceId,
      profileQuery.data?.lastWorkspaceId,
      workspaceId,
    ]
  )

  return activeWorkspace || defaultWorkspace
}

export function useEnsureWorkspace() {
  const { workspace, workspaceId } = useSearch({ strict: false })
  const router = useRouter()
  const navigate = useNavigate()
  const match = last(router.state.matches)
  const workspaceSlug = match?.params?.workspaceSlug

  const activeWorkspace = useActiveWorkspace()

  React.useEffect(() => {
    if (!activeWorkspace) {
      return
    }

    if (!workspaceId) {
      navigate({
        search: prev => {
          return {
            ...prev,
            // The 'workspace' param is decorative only. It's provided only to
            // make the URL's workspace more apparent to anyone looking at it.
            workspace: activeWorkspace.slug || undefined,
            workspaceId: activeWorkspace.id,
          }
        },
        replace: true,
      })
      return
    }

    if (!workspace) {
      navigate({
        search: prev => {
          return {
            ...prev,
            workspace: activeWorkspace.slug || undefined,
          }
        },
        replace: true,
      })
      return
    }
  }, [
    activeWorkspace,
    navigate,
    router.state.location.pathname,
    workspace,
    workspaceId,
    workspaceSlug,
  ])

  return activeWorkspace
}

export function useActiveWorkspaceId() {
  return useActiveWorkspace().id!
}

//

export function useSaveWorkspace() {
  const mutation = useMutation(patchWorkspace, {
    onMutate: () => {
      setLoading(true)
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries(queryKeyWorkspaces)
      queryClient.setQueryData([queryKeyWorkspaces, data.id], data)
    },
  })

  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const queryClient = useQueryClient()

  return React.useCallback(
    async (newWorkspace: Workspace) => {
      try {
        setLoading(true)
        const workspace = await mutation.mutateAsync(newWorkspace)
        toast({
          message: 'Workspace Saved',
          color: 'green-500',
        })
        return workspace
      } catch (err) {
        console.error(err)
        errorPopup('Failed to save workspace.')
      } finally {
        setLoading(false)
      }
    },
    [mutation, errorPopup, setLoading, toast]
  )
}

export function useCreateWorkspace(options: { silent?: boolean } = {}) {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const [, setLoading] = useLoading()
  const popup = usePopup()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const { mutateAsync: createWorkspace } = useMutation(
    (params: { name: string; slug: string; hubspotListIds: number[] }) => {
      const appsumoLicenseId = LocalStorage.get('pop_appsumoLicenseId')
      const appsumoActivationEmail = LocalStorage.get(
        'pop_appsumoActivationEmail'
      )

      // @ts-expect-error  // Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
      return postWorkspace(params, {
        appsumoLicenseId,
        appsumoActivationEmail,
      })
    },
    {
      onMutate: () => {
        setLoading(true)
      },
      onSuccess: async () => {
        LocalStorage.set('pop_appsumoLicenseId', undefined)
        LocalStorage.set('pop_appsumoActivationEmail', undefined)

        if (!options.silent)
          toast({
            color: 'green-500',
            message: 'Workspace Created',
          })

        queryClient.clear()

        await queryClient.invalidateQueries(queryKeyWorkspaces)
      },
      onError: (err: any) => {
        console.error(err)

        if (!options.silent) {
          if (err.response.status === 409) {
            return popup({
              color: 'red-500',
              title: 'Workspace Already Exists!',
              message:
                'Failed to Create Workspace. Please try a different Workspace Name/Workspace Slug.',
            })
          }

          if (
            err.response.data?.errors?.[0]?.field_violations?.[0]?.field ===
            'licenseUUID'
          ) {
            const code =
              err.response.data?.errors?.[0]?.field_violations?.[0]?.description
            const codes = {
              'license not found': [
                'AppSumo License Not Found',
                `We detected an invalid AppSumo license on your machine! Please check your activation link and try again.`,
              ],
              'license has been refunded': [
                'AppSumo License Was Refunded',
                `We detected an AppSumo license on your machine that has been refunded! Please purchase a new AppSumo license, or create a new standard workspace.`,
              ],
              'license already activated': [
                'AppSumo License Already Activated',
                `We detected an AppSumo license on your machine that has already been used! We'll clear it from memory and any future workspaces you create will not be associated with this license.`,
              ],
            }

            return (async () => {
              await popup({
                color: 'blue-500',
                // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                title: codes[code]?.[0],
                // @ts-expect-error  // Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                message: codes[code]?.[1],
              })

              LocalStorage.set('pop_appsumoLicenseId', undefined)
              LocalStorage.set('pop_appsumoActivationEmail', undefined)

              navigate({
                search: (old: any) => {
                  delete old.appsumoLicenseId
                  delete old.appsumoActivationEmail

                  return old
                },
                replace: true,
              })
            })()
          }

          errorPopup('Failed to create workspace.')
        }

        throw err
      },
      onSettled: () => {
        setLoading(false)
      },
    }
  )

  return createWorkspace
}

export function useRemoveWorkspaceById() {
  const toast = useToast()
  const errorPopup = useErrorPopup()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(deleteWorkspace, {
    onSuccess: async () => {
      toast({
        message: 'Workspace Deleted',
        color: 'green-500',
      })
      await queryClient.invalidateQueries(queryKeyWorkspaces)
    },
    onError: err => {
      console.error(err)
      errorPopup('Failed to remove workspace.')
    },
  })

  return mutateAsync
}
