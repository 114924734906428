// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/keywords/v1/keywords.proto (package nozzle.keywords.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * A Device is a supported device target for a keyword.
 *
 * @generated from enum nozzle.keywords.v1.Device
 */
export enum Device {
  /**
   * @generated from enum value: DEVICE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEVICE_DESKTOP_WINDOWS = 1;
   */
  DESKTOP_WINDOWS = 1,

  /**
   * @generated from enum value: DEVICE_TABLET_ANDROID = 2;
   */
  TABLET_ANDROID = 2,

  /**
   * @generated from enum value: DEVICE_TABLET_IOS = 3;
   */
  TABLET_IOS = 3,

  /**
   * @generated from enum value: DEVICE_MOBILE_ANDROID = 4;
   */
  MOBILE_ANDROID = 4,

  /**
   * @generated from enum value: DEVICE_MOBILE_IOS = 5;
   */
  MOBILE_IOS = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Device)
proto3.util.setEnumType(Device, "nozzle.keywords.v1.Device", [
  { no: 0, name: "DEVICE_UNSPECIFIED" },
  { no: 1, name: "DEVICE_DESKTOP_WINDOWS" },
  { no: 2, name: "DEVICE_TABLET_ANDROID" },
  { no: 3, name: "DEVICE_TABLET_IOS" },
  { no: 4, name: "DEVICE_MOBILE_ANDROID" },
  { no: 5, name: "DEVICE_MOBILE_IOS" },
]);

/**
 * An Engine represents a supported keyword search engine.
 *
 * @generated from enum nozzle.keywords.v1.Engine
 */
export enum Engine {
  /**
   * @generated from enum value: ENGINE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ENGINE_GOOGLE = 1;
   */
  GOOGLE = 1,

  /**
   * @generated from enum value: ENGINE_BING = 2;
   */
  BING = 2,

  /**
   * @generated from enum value: ENGINE_YAHOO = 3;
   */
  YAHOO = 3,

  /**
   * @generated from enum value: ENGINE_BAIDU = 4;
   */
  BAIDU = 4,

  /**
   * @generated from enum value: ENGINE_YANDEX = 5;
   */
  YANDEX = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Engine)
proto3.util.setEnumType(Engine, "nozzle.keywords.v1.Engine", [
  { no: 0, name: "ENGINE_UNSPECIFIED" },
  { no: 1, name: "ENGINE_GOOGLE" },
  { no: 2, name: "ENGINE_BING" },
  { no: 3, name: "ENGINE_YAHOO" },
  { no: 4, name: "ENGINE_BAIDU" },
  { no: 5, name: "ENGINE_YANDEX" },
]);

/**
 * LocationType is the type assigned by the Google AdWords Geotargets list.
 *
 * @generated from enum nozzle.keywords.v1.LocationType
 */
export enum LocationType {
  /**
   * @generated from enum value: LOCATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: LOCATION_TYPE_AIRPORT = 1;
   */
  AIRPORT = 1,

  /**
   * @generated from enum value: LOCATION_TYPE_AUTONOMOUS_COMMUNITY = 2;
   */
  AUTONOMOUS_COMMUNITY = 2,

  /**
   * @generated from enum value: LOCATION_TYPE_BOROUGH = 3;
   */
  BOROUGH = 3,

  /**
   * @generated from enum value: LOCATION_TYPE_CANTON = 4;
   */
  CANTON = 4,

  /**
   * @generated from enum value: LOCATION_TYPE_CITY = 5;
   */
  CITY = 5,

  /**
   * @generated from enum value: LOCATION_TYPE_CITY_REGION = 6;
   */
  CITY_REGION = 6,

  /**
   * @generated from enum value: LOCATION_TYPE_CONGRESSIONAL_DISTRICT = 7;
   */
  CONGRESSIONAL_DISTRICT = 7,

  /**
   * @generated from enum value: LOCATION_TYPE_COUNTRY = 8;
   */
  COUNTRY = 8,

  /**
   * @generated from enum value: LOCATION_TYPE_COUNTY = 9;
   */
  COUNTY = 9,

  /**
   * @generated from enum value: LOCATION_TYPE_DEPARTMENT = 10;
   */
  DEPARTMENT = 10,

  /**
   * @generated from enum value: LOCATION_TYPE_DISTRICT = 11;
   */
  DISTRICT = 11,

  /**
   * @generated from enum value: LOCATION_TYPE_GOVERNORATE = 12;
   */
  GOVERNORATE = 12,

  /**
   * @generated from enum value: LOCATION_TYPE_MUNICIPALITY = 13;
   */
  MUNICIPALITY = 13,

  /**
   * @generated from enum value: LOCATION_TYPE_NATIONAL_PARK = 14;
   */
  NATIONAL_PARK = 14,

  /**
   * @generated from enum value: LOCATION_TYPE_NEIGHBORHOOD = 15;
   */
  NEIGHBORHOOD = 15,

  /**
   * @generated from enum value: LOCATION_TYPE_OKRUG = 16;
   */
  OKRUG = 16,

  /**
   * @generated from enum value: LOCATION_TYPE_OTHER = 17;
   */
  OTHER = 17,

  /**
   * @generated from enum value: LOCATION_TYPE_POSTAL_CODE = 18;
   */
  POSTAL_CODE = 18,

  /**
   * @generated from enum value: LOCATION_TYPE_PREFECTURE = 19;
   */
  PREFECTURE = 19,

  /**
   * @generated from enum value: LOCATION_TYPE_PROVINCE = 20;
   */
  PROVINCE = 20,

  /**
   * @generated from enum value: LOCATION_TYPE_REGION = 21;
   */
  REGION = 21,

  /**
   * @generated from enum value: LOCATION_TYPE_STATE = 22;
   */
  STATE = 22,

  /**
   * @generated from enum value: LOCATION_TYPE_TERRITORY = 23;
   */
  TERRITORY = 23,

  /**
   * @generated from enum value: LOCATION_TYPE_TV_REGION = 24;
   */
  TV_REGION = 24,

  /**
   * @generated from enum value: LOCATION_TYPE_UNION_TERRITORY = 25;
   */
  UNION_TERRITORY = 25,

  /**
   * @generated from enum value: LOCATION_TYPE_UNIVERSITY = 26;
   */
  UNIVERSITY = 26,
}
// Retrieve enum metadata with: proto3.getEnumType(LocationType)
proto3.util.setEnumType(LocationType, "nozzle.keywords.v1.LocationType", [
  { no: 0, name: "LOCATION_TYPE_UNSPECIFIED" },
  { no: 1, name: "LOCATION_TYPE_AIRPORT" },
  { no: 2, name: "LOCATION_TYPE_AUTONOMOUS_COMMUNITY" },
  { no: 3, name: "LOCATION_TYPE_BOROUGH" },
  { no: 4, name: "LOCATION_TYPE_CANTON" },
  { no: 5, name: "LOCATION_TYPE_CITY" },
  { no: 6, name: "LOCATION_TYPE_CITY_REGION" },
  { no: 7, name: "LOCATION_TYPE_CONGRESSIONAL_DISTRICT" },
  { no: 8, name: "LOCATION_TYPE_COUNTRY" },
  { no: 9, name: "LOCATION_TYPE_COUNTY" },
  { no: 10, name: "LOCATION_TYPE_DEPARTMENT" },
  { no: 11, name: "LOCATION_TYPE_DISTRICT" },
  { no: 12, name: "LOCATION_TYPE_GOVERNORATE" },
  { no: 13, name: "LOCATION_TYPE_MUNICIPALITY" },
  { no: 14, name: "LOCATION_TYPE_NATIONAL_PARK" },
  { no: 15, name: "LOCATION_TYPE_NEIGHBORHOOD" },
  { no: 16, name: "LOCATION_TYPE_OKRUG" },
  { no: 17, name: "LOCATION_TYPE_OTHER" },
  { no: 18, name: "LOCATION_TYPE_POSTAL_CODE" },
  { no: 19, name: "LOCATION_TYPE_PREFECTURE" },
  { no: 20, name: "LOCATION_TYPE_PROVINCE" },
  { no: 21, name: "LOCATION_TYPE_REGION" },
  { no: 22, name: "LOCATION_TYPE_STATE" },
  { no: 23, name: "LOCATION_TYPE_TERRITORY" },
  { no: 24, name: "LOCATION_TYPE_TV_REGION" },
  { no: 25, name: "LOCATION_TYPE_UNION_TERRITORY" },
  { no: 26, name: "LOCATION_TYPE_UNIVERSITY" },
]);

/**
 * Keywords are important
 *
 * @generated from message nozzle.keywords.v1.Keyword
 */
export class Keyword extends Message<Keyword> {
  /**
   * @generated from field: int64 keyword_id = 1;
   */
  keywordId = protoInt64.zero;

  /**
   * @generated from field: int64 phrase_id = 2;
   */
  phraseId = protoInt64.zero;

  /**
   * @generated from field: string phrase = 3;
   */
  phrase = "";

  /**
   * @generated from field: nozzle.keywords.v1.Device device = 4;
   */
  device = Device.UNSPECIFIED;

  /**
   * locale fields
   *
   * @generated from field: int64 locale_id = 5;
   */
  localeId = protoInt64.zero;

  /**
   * @generated from field: nozzle.keywords.v1.Engine engine = 6;
   */
  engine = Engine.UNSPECIFIED;

  /**
   * @generated from field: string language_code = 7;
   */
  languageCode = "";

  /**
   * location fields
   *
   * @generated from field: nozzle.keywords.v1.Location location = 8;
   */
  location?: Location;

  constructor(data?: PartialMessage<Keyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.keywords.v1.Keyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "phrase_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "phrase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "device", kind: "enum", T: proto3.getEnumType(Device) },
    { no: 5, name: "locale_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "engine", kind: "enum", T: proto3.getEnumType(Engine) },
    { no: 7, name: "language_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "location", kind: "message", T: Location },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Keyword {
    return new Keyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Keyword {
    return new Keyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Keyword {
    return new Keyword().fromJsonString(jsonString, options);
  }

  static equals(a: Keyword | PlainMessage<Keyword> | undefined, b: Keyword | PlainMessage<Keyword> | undefined): boolean {
    return proto3.util.equals(Keyword, a, b);
  }
}

/**
 * A Location includes all the details for a specific geo-location.
 *
 * @generated from message nozzle.keywords.v1.Location
 */
export class Location extends Message<Location> {
  /**
   * @generated from field: int64 location_id = 1;
   */
  locationId = protoInt64.zero;

  /**
   * @generated from field: nozzle.keywords.v1.LocationType type = 2;
   */
  type = LocationType.UNSPECIFIED;

  /**
   * Best available English name of the geo target.
   *
   * @generated from field: string location = 3;
   */
  location = "";

  /**
   * ISO-3166-1 alpha-2 country code that is associated with the target
   *
   * @generated from field: string country_code = 4;
   */
  countryCode = "";

  /**
   * @generated from field: int64 google_criteria_id = 5;
   */
  googleCriteriaId = protoInt64.zero;

  /**
   * @generated from field: int32 population = 6;
   */
  population = 0;

  constructor(data?: PartialMessage<Location>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.keywords.v1.Location";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "location_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(LocationType) },
    { no: 3, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "google_criteria_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "population", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Location {
    return new Location().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJsonString(jsonString, options);
  }

  static equals(a: Location | PlainMessage<Location> | undefined, b: Location | PlainMessage<Location> | undefined): boolean {
    return proto3.util.equals(Location, a, b);
  }
}

