// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/urls/v1/lighthouse-result.proto (package nozzle.urls.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { DoubleValue, ListValue, Message, proto3, protoInt64, Struct, Timestamp, Value } from "@bufbuild/protobuf";

/**
 * Canonical list of errors created by Lighthouse.
 *
 * @generated from enum nozzle.urls.v1.LighthouseError
 */
export enum LighthouseError {
  /**
   * No error in Lighthouse; the results are reliable.
   *
   * @generated from enum value: NO_ERROR = 0;
   */
  NO_ERROR = 0,

  /**
   * An uncategorized error occurred, likely a JavaScript exception.
   *
   * @generated from enum value: UNKNOWN_ERROR = 1;
   */
  UNKNOWN_ERROR = 1,

  /**
   * The trace did not contain any screenshot events.
   *
   * @generated from enum value: NO_SPEEDLINE_FRAMES = 2;
   */
  NO_SPEEDLINE_FRAMES = 2,

  /**
   * No visual change between the beginning and end of load.
   *
   * @generated from enum value: SPEEDINDEX_OF_ZERO = 3;
   */
  SPEEDINDEX_OF_ZERO = 3,

  /**
   * The trace did not contain any screenshot events.
   *
   * @generated from enum value: NO_SCREENSHOTS = 4;
   */
  NO_SCREENSHOTS = 4,

  /**
   * The computed speedindex results are non-finite.
   *
   * @generated from enum value: INVALID_SPEEDLINE = 5;
   */
  INVALID_SPEEDLINE = 5,

  /**
   * The trace did not contain a TracingStartedInPage event.
   *
   * @generated from enum value: NO_TRACING_STARTED = 6;
   */
  NO_TRACING_STARTED = 6,

  /**
   * The trace did not contain a navigationStart event.
   *
   * @generated from enum value: NO_NAVSTART = 7;
   */
  NO_NAVSTART = 7,

  /**
   * The trace did not contain a firstContentfulPaint event.
   *
   * @generated from enum value: NO_FCP = 8;
   */
  NO_FCP = 8,

  /**
   * The trace did not contain a domContentLoaded event.
   *
   * @generated from enum value: NO_DCL = 9;
   */
  NO_DCL = 9,

  /**
   * No network request could be identified as the primary HTML document.
   *
   * @generated from enum value: NO_DOCUMENT_REQUEST = 10;
   */
  NO_DOCUMENT_REQUEST = 10,

  /**
   * The HTML document's network request failed due to Chrome-internal reasons
   * (canceled, blocked, etc).
   *
   * @generated from enum value: FAILED_DOCUMENT_REQUEST = 11;
   */
  FAILED_DOCUMENT_REQUEST = 11,

  /**
   * The HTML document's network request completed, but returned an HTTP status
   * code of 4xx or 5xx.
   *
   * @generated from enum value: ERRORED_DOCUMENT_REQUEST = 12;
   */
  ERRORED_DOCUMENT_REQUEST = 12,

  /**
   * Chromium's tracing controller did not manage to begin tracing across
   * processes. Typically fixed by restarting Chromium.
   *
   * @generated from enum value: TRACING_ALREADY_STARTED = 13;
   */
  TRACING_ALREADY_STARTED = 13,

  /**
   * The trace data wasn't parsed correctly.
   *
   * @generated from enum value: PARSING_PROBLEM = 14;
   */
  PARSING_PROBLEM = 14,

  /**
   * The trace data failed to stream over the protocol.
   *
   * @generated from enum value: READ_FAILED = 15;
   */
  READ_FAILED = 15,

  /**
   * Used when security error prevents page load.
   *
   * @generated from enum value: INSECURE_DOCUMENT_REQUEST = 16;
   */
  INSECURE_DOCUMENT_REQUEST = 16,

  /**
   * Used when protocol command times out.
   *
   * @generated from enum value: PROTOCOL_TIMEOUT = 17;
   */
  PROTOCOL_TIMEOUT = 17,

  /**
   * Used when the page is not responding after maxWaitForLoad.
   *
   * @generated from enum value: PAGE_HUNG = 18;
   */
  PAGE_HUNG = 18,

  /**
   * DNS failure on main document (no resolution, timed out, etc)
   *
   * @generated from enum value: DNS_FAILURE = 19;
   */
  DNS_FAILURE = 19,

  /**
   * A timeout in the initial connection to the debugger protocol.
   *
   * @generated from enum value: CRI_TIMEOUT = 20;
   */
  CRI_TIMEOUT = 20,

  /**
   * The page requested was not HTML.
   *
   * @generated from enum value: NOT_HTML = 21;
   */
  NOT_HTML = 21,

  /**
   * The trace did not contain a ResourceSendRequest event.
   *
   * @generated from enum value: NO_RESOURCE_REQUEST = 22;
   */
  NO_RESOURCE_REQUEST = 22,
}
// Retrieve enum metadata with: proto3.getEnumType(LighthouseError)
proto3.util.setEnumType(LighthouseError, "nozzle.urls.v1.LighthouseError", [
  { no: 0, name: "NO_ERROR" },
  { no: 1, name: "UNKNOWN_ERROR" },
  { no: 2, name: "NO_SPEEDLINE_FRAMES" },
  { no: 3, name: "SPEEDINDEX_OF_ZERO" },
  { no: 4, name: "NO_SCREENSHOTS" },
  { no: 5, name: "INVALID_SPEEDLINE" },
  { no: 6, name: "NO_TRACING_STARTED" },
  { no: 7, name: "NO_NAVSTART" },
  { no: 8, name: "NO_FCP" },
  { no: 9, name: "NO_DCL" },
  { no: 10, name: "NO_DOCUMENT_REQUEST" },
  { no: 11, name: "FAILED_DOCUMENT_REQUEST" },
  { no: 12, name: "ERRORED_DOCUMENT_REQUEST" },
  { no: 13, name: "TRACING_ALREADY_STARTED" },
  { no: 14, name: "PARSING_PROBLEM" },
  { no: 15, name: "READ_FAILED" },
  { no: 16, name: "INSECURE_DOCUMENT_REQUEST" },
  { no: 17, name: "PROTOCOL_TIMEOUT" },
  { no: 18, name: "PAGE_HUNG" },
  { no: 19, name: "DNS_FAILURE" },
  { no: 20, name: "CRI_TIMEOUT" },
  { no: 21, name: "NOT_HTML" },
  { no: 22, name: "NO_RESOURCE_REQUEST" },
]);

/**
 * The overarching Lighthouse Response object (LHR)
 * https://github.com/GoogleChrome/lighthouse/blob/main/types/lhr.d.ts
 *
 * @generated from message nozzle.urls.v1.LighthouseResult
 */
export class LighthouseResult extends Message<LighthouseResult> {
  /**
   * The timestamp of when the results were generated.
   *
   * @generated from field: google.protobuf.Timestamp fetch_time = 1;
   */
  fetchTime?: Timestamp;

  /**
   * The url requested to lightrider.
   *
   * @generated from field: string requested_url = 2;
   */
  requestedUrl = "";

  /**
   * The final analyzed URL, differs from requested_url when there were
   * redirects.
   *
   * @generated from field: string final_url = 3;
   */
  finalUrl = "";

  /**
   * The version of Lighthouse with which these results were generated.
   *
   * @generated from field: string lighthouse_version = 4;
   */
  lighthouseVersion = "";

  /**
   * The environment that the audit was run in
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.Environment environment = 5;
   */
  environment?: LighthouseResult_Environment;

  /**
   * The user agent that was used to run the audit
   *
   * @generated from field: string user_agent = 6;
   */
  userAgent = "";

  /**
   * Any top level warnings that this run encountered
   * default is [] hence ListValue
   *
   * @generated from field: google.protobuf.ListValue run_warnings = 7;
   */
  runWarnings?: ListValue;

  /**
   * A runtime error that was encountered during the run
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.RuntimeError runtime_error = 8;
   */
  runtimeError?: LighthouseResult_RuntimeError;

  /**
   * A map containing the results of the audits, keyed by audit id. Audits may
   * be referenced within more than one category.
   *
   * @generated from field: map<string, nozzle.urls.v1.AuditResult> audits = 9;
   */
  audits: { [key: string]: AuditResult } = {};

  /**
   * A map containing the categories of audits, keyed by category id.
   *
   * @generated from field: map<string, nozzle.urls.v1.LhrCategory> categories = 10;
   */
  categories: { [key: string]: LhrCategory } = {};

  /**
   * A map containing groups that categories can belong to, keyed by group id
   *
   * @generated from field: map<string, nozzle.urls.v1.CategoryGroup> category_groups = 11;
   */
  categoryGroups: { [key: string]: CategoryGroup } = {};

  /**
   * The settings that were used to run this audit
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.ConfigSettings config_settings = 12;
   */
  configSettings?: LighthouseResult_ConfigSettings;

  /**
   * i18n info in version 1 message format
   *
   * @generated from field: nozzle.urls.v1.I18n i18n = 13;
   */
  i18n?: I18n;

  /**
   * The performance timing data for the Lighthouse run
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.Timing timing = 14;
   */
  timing?: LighthouseResult_Timing;

  /**
   * The Stack Pack data used by audits in this run.
   *
   * @generated from field: repeated nozzle.urls.v1.StackPack stack_packs = 15;
   */
  stackPacks: StackPack[] = [];

  /**
   * Gather mode used to collect artifacts.
   *
   * @generated from field: string gather_mode = 16;
   */
  gatherMode = "";

  /**
   * URL of the main document request of the final navigation.
   *
   * @generated from field: string main_document_url = 17;
   */
  mainDocumentUrl = "";

  /**
   * URL displayed on the page after Lighthouse finishes.
   *
   * @generated from field: string final_displayed_url = 18;
   */
  finalDisplayedUrl = "";

  /**
   * Screenshot data of the full page, along with node rects relevant to the audit results.
   *
   * @generated from field: google.protobuf.Value full_page_screenshot = 19;
   */
  fullPageScreenshot?: Value;

  /**
   * Entity classification data.
   *
   * @generated from field: repeated nozzle.urls.v1.LhrEntity entities = 20;
   */
  entities: LhrEntity[] = [];

  constructor(data?: PartialMessage<LighthouseResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fetch_time", kind: "message", T: Timestamp },
    { no: 2, name: "requested_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "final_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lighthouse_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "environment", kind: "message", T: LighthouseResult_Environment },
    { no: 6, name: "user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "run_warnings", kind: "message", T: ListValue },
    { no: 8, name: "runtime_error", kind: "message", T: LighthouseResult_RuntimeError },
    { no: 9, name: "audits", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AuditResult} },
    { no: 10, name: "categories", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: LhrCategory} },
    { no: 11, name: "category_groups", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: CategoryGroup} },
    { no: 12, name: "config_settings", kind: "message", T: LighthouseResult_ConfigSettings },
    { no: 13, name: "i18n", kind: "message", T: I18n },
    { no: 14, name: "timing", kind: "message", T: LighthouseResult_Timing },
    { no: 15, name: "stack_packs", kind: "message", T: StackPack, repeated: true },
    { no: 16, name: "gather_mode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "main_document_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "final_displayed_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "full_page_screenshot", kind: "message", T: Value },
    { no: 20, name: "entities", kind: "message", T: LhrEntity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult {
    return new LighthouseResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult {
    return new LighthouseResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult {
    return new LighthouseResult().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult | PlainMessage<LighthouseResult> | undefined, b: LighthouseResult | PlainMessage<LighthouseResult> | undefined): boolean {
    return proto3.util.equals(LighthouseResult, a, b);
  }
}

/**
 * Message containing environment configuration for a LH run
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.Environment
 */
export class LighthouseResult_Environment extends Message<LighthouseResult_Environment> {
  /**
   * The user agent that was used by the network
   *
   * @generated from field: string network_user_agent = 1;
   */
  networkUserAgent = "";

  /**
   * The user agent used by the host
   *
   * @generated from field: string host_user_agent = 2;
   */
  hostUserAgent = "";

  /**
   * The benchmark index that indicates rough device class
   *
   * @generated from field: google.protobuf.DoubleValue benchmark_index = 3;
   */
  benchmarkIndex?: number;

  /**
   * The version of libraries with which these results were generated. Ex:
   * axe-core.
   *
   * @generated from field: map<string, string> credits = 4;
   */
  credits: { [key: string]: string } = {};

  constructor(data?: PartialMessage<LighthouseResult_Environment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.Environment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network_user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "host_user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "benchmark_index", kind: "message", T: DoubleValue },
    { no: 4, name: "credits", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_Environment {
    return new LighthouseResult_Environment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_Environment {
    return new LighthouseResult_Environment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_Environment {
    return new LighthouseResult_Environment().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_Environment | PlainMessage<LighthouseResult_Environment> | undefined, b: LighthouseResult_Environment | PlainMessage<LighthouseResult_Environment> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_Environment, a, b);
  }
}

/**
 * Message containing a runtime error config
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.RuntimeError
 */
export class LighthouseResult_RuntimeError extends Message<LighthouseResult_RuntimeError> {
  /**
   * The text repr of the error type
   *
   * @generated from field: nozzle.urls.v1.LighthouseError code = 1;
   */
  code = LighthouseError.NO_ERROR;

  /**
   * The error explanitory message
   *
   * @generated from field: string message = 2;
   */
  message = "";

  constructor(data?: PartialMessage<LighthouseResult_RuntimeError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.RuntimeError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "enum", T: proto3.getEnumType(LighthouseError) },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_RuntimeError {
    return new LighthouseResult_RuntimeError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_RuntimeError {
    return new LighthouseResult_RuntimeError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_RuntimeError {
    return new LighthouseResult_RuntimeError().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_RuntimeError | PlainMessage<LighthouseResult_RuntimeError> | undefined, b: LighthouseResult_RuntimeError | PlainMessage<LighthouseResult_RuntimeError> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_RuntimeError, a, b);
  }
}

/**
 * Message containing the configuration settings for the LH run
 * Next ID: 10
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.ConfigSettings
 */
export class LighthouseResult_ConfigSettings extends Message<LighthouseResult_ConfigSettings> {
  /**
   * Removed in v7. Do not use.
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.ConfigSettings.EmulatedFormFactor emulated_form_factor = 1 [deprecated = true];
   * @deprecated
   */
  emulatedFormFactor = LighthouseResult_ConfigSettings_EmulatedFormFactor.UNKNOWN_FORM_FACTOR;

  /**
   * How Lighthouse should interpret this run in regards to scoring
   * performance metrics and skipping mobile-only tests in desktop.
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.ConfigSettings.EmulatedFormFactor form_factor = 5;
   */
  formFactor = LighthouseResult_ConfigSettings_EmulatedFormFactor.UNKNOWN_FORM_FACTOR;

  /**
   * The locale that was active during the audit
   *
   * @generated from field: string locale = 2;
   */
  locale = "";

  /**
   * List of the categories that were run, empty if all were run
   * nullable list of strings
   *
   * @generated from field: google.protobuf.Value only_categories = 3;
   */
  onlyCategories?: Value;

  /**
   * How Lighthouse was run, e.g. from the Chrome extension or from the npm
   * module
   *
   * @generated from field: string channel = 4;
   */
  channel = "";

  /**
   * The throttling config settings.
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.ConfigSettings.ThrottlingSettings throttling = 7;
   */
  throttling?: LighthouseResult_ConfigSettings_ThrottlingSettings;

  /**
   * The method used to throttle the network.
   *
   * @generated from field: string throttling_method = 8;
   */
  throttlingMethod = "";

  /**
   * Screen emulation properties (width, height, dpr, mobile viewport) to apply or an object of `{disabled: true}` if Lighthouse should avoid applying screen emulation. If either emulation is applied outside of Lighthouse, or it's being run on a mobile device, it typically should be set to disabled. For desktop, we recommend applying consistent desktop screen emulation.
   *
   * @generated from field: nozzle.urls.v1.LighthouseResult.ConfigSettings.ScreenEmulation screen_emulation = 9;
   */
  screenEmulation?: LighthouseResult_ConfigSettings_ScreenEmulation;

  /**
   * @generated from field: string output = 10;
   */
  output = "";

  /**
   * @generated from field: int64 max_wait_for_fcp = 11;
   */
  maxWaitForFcp = protoInt64.zero;

  /**
   * @generated from field: int64 max_wait_for_load = 12;
   */
  maxWaitForLoad = protoInt64.zero;

  /**
   * @generated from field: int64 pause_after_fcp_ms = 13;
   */
  pauseAfterFcpMs = protoInt64.zero;

  /**
   * @generated from field: int64 pause_after_load_ms = 14;
   */
  pauseAfterLoadMs = protoInt64.zero;

  /**
   * @generated from field: int64 network_quiet_threshold_ms = 15;
   */
  networkQuietThresholdMs = protoInt64.zero;

  /**
   * @generated from field: int64 cpu_quiet_threshold_ms = 16;
   */
  cpuQuietThresholdMs = protoInt64.zero;

  /**
   * @generated from field: string emulated_user_agent = 17;
   */
  emulatedUserAgent = "";

  /**
   * @generated from field: bool audit_mode = 18;
   */
  auditMode = false;

  /**
   * @generated from field: bool gather_mode = 19;
   */
  gatherMode = false;

  /**
   * @generated from field: bool disable_storage_reset = 20;
   */
  disableStorageReset = false;

  /**
   * @generated from field: bool debug_navigation = 21;
   */
  debugNavigation = false;

  /**
   * @generated from field: bool use_passive_gathering = 22;
   */
  usePassiveGathering = false;

  /**
   * @generated from field: bool disable_full_page_screenshot = 23;
   */
  disableFullPageScreenshot = false;

  /**
   * @generated from field: bool skip_about_blank = 24;
   */
  skipAboutBlank = false;

  /**
   * @generated from field: string blank_page = 25;
   */
  blankPage = "";

  /**
   * @generated from field: repeated string budgets = 26;
   */
  budgets: string[] = [];

  /**
   * @generated from field: repeated string blocked_url_patterns = 27;
   */
  blockedUrlPatterns: string[] = [];

  /**
   * @generated from field: repeated string additional_trace_categories = 28;
   */
  additionalTraceCategories: string[] = [];

  /**
   * @generated from field: repeated string extra_headers = 29;
   */
  extraHeaders: string[] = [];

  /**
   * @generated from field: repeated string precomputed_lantern_data = 30;
   */
  precomputedLanternData: string[] = [];

  /**
   * @generated from field: repeated string only_audits = 31;
   */
  onlyAudits: string[] = [];

  /**
   * @generated from field: repeated string skip_audits = 32;
   */
  skipAudits: string[] = [];

  constructor(data?: PartialMessage<LighthouseResult_ConfigSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.ConfigSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emulated_form_factor", kind: "enum", T: proto3.getEnumType(LighthouseResult_ConfigSettings_EmulatedFormFactor) },
    { no: 5, name: "form_factor", kind: "enum", T: proto3.getEnumType(LighthouseResult_ConfigSettings_EmulatedFormFactor) },
    { no: 2, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "only_categories", kind: "message", T: Value },
    { no: 4, name: "channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "throttling", kind: "message", T: LighthouseResult_ConfigSettings_ThrottlingSettings },
    { no: 8, name: "throttling_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "screen_emulation", kind: "message", T: LighthouseResult_ConfigSettings_ScreenEmulation },
    { no: 10, name: "output", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "max_wait_for_fcp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "max_wait_for_load", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "pause_after_fcp_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "pause_after_load_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "network_quiet_threshold_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "cpu_quiet_threshold_ms", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 17, name: "emulated_user_agent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "audit_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "gather_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "disable_storage_reset", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "debug_navigation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "use_passive_gathering", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "disable_full_page_screenshot", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "skip_about_blank", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "blank_page", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "budgets", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 27, name: "blocked_url_patterns", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 28, name: "additional_trace_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 29, name: "extra_headers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 30, name: "precomputed_lantern_data", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 31, name: "only_audits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "skip_audits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_ConfigSettings {
    return new LighthouseResult_ConfigSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings {
    return new LighthouseResult_ConfigSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings {
    return new LighthouseResult_ConfigSettings().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_ConfigSettings | PlainMessage<LighthouseResult_ConfigSettings> | undefined, b: LighthouseResult_ConfigSettings | PlainMessage<LighthouseResult_ConfigSettings> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_ConfigSettings, a, b);
  }
}

/**
 * The possible form factors an audit can be run in.
 * This enum served the emulated_form_factor field, but in v7, that field
 * was deprecated. Meanwhile in v7, the form_factor field was added and has
 * a mobile/desktop enum, so we simplify reuse, minus the `none` option.
 * See
 * https://github.com/GoogleChrome/lighthouse/blob/main/docs/emulation.md#changes-made-in-v7
 *
 * @generated from enum nozzle.urls.v1.LighthouseResult.ConfigSettings.EmulatedFormFactor
 */
export enum LighthouseResult_ConfigSettings_EmulatedFormFactor {
  /**
   * @generated from enum value: UNKNOWN_FORM_FACTOR = 0;
   */
  UNKNOWN_FORM_FACTOR = 0,

  /**
   * @generated from enum value: mobile = 1;
   */
  mobile = 1,

  /**
   * @generated from enum value: desktop = 2;
   */
  desktop = 2,

  /**
   * @generated from enum value: none = 3 [deprecated = true];
   * @deprecated
   */
  none = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LighthouseResult_ConfigSettings_EmulatedFormFactor)
proto3.util.setEnumType(LighthouseResult_ConfigSettings_EmulatedFormFactor, "nozzle.urls.v1.LighthouseResult.ConfigSettings.EmulatedFormFactor", [
  { no: 0, name: "UNKNOWN_FORM_FACTOR" },
  { no: 1, name: "mobile" },
  { no: 2, name: "desktop" },
  { no: 3, name: "none" },
]);

/**
 * Next ID: 7
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.ConfigSettings.ThrottlingSettings
 */
export class LighthouseResult_ConfigSettings_ThrottlingSettings extends Message<LighthouseResult_ConfigSettings_ThrottlingSettings> {
  /**
   * The round trip time in milliseconds.
   *
   * @generated from field: double rtt_ms = 1;
   */
  rttMs = 0;

  /**
   * The network throughput in kilobits per second.
   *
   * @generated from field: double throughput_kbps = 2;
   */
  throughputKbps = 0;

  /**
   * The network request latency in milliseconds.
   *
   * @generated from field: double request_latency_ms = 3;
   */
  requestLatencyMs = 0;

  /**
   * The network download throughput in kilobits per second.
   *
   * @generated from field: double download_throughput_kbps = 4;
   */
  downloadThroughputKbps = 0;

  /**
   * The network upload throughput in kilobits per second.
   *
   * @generated from field: double upload_throughput_kbps = 5;
   */
  uploadThroughputKbps = 0;

  /**
   * The amount of slowdown applied to the cpu (1/<cpuSlowdownMultiplier>).
   *
   * @generated from field: double cpu_slowdown_multiplier = 6;
   */
  cpuSlowdownMultiplier = 0;

  constructor(data?: PartialMessage<LighthouseResult_ConfigSettings_ThrottlingSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.ConfigSettings.ThrottlingSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rtt_ms", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "throughput_kbps", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "request_latency_ms", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "download_throughput_kbps", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "upload_throughput_kbps", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "cpu_slowdown_multiplier", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_ConfigSettings_ThrottlingSettings {
    return new LighthouseResult_ConfigSettings_ThrottlingSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings_ThrottlingSettings {
    return new LighthouseResult_ConfigSettings_ThrottlingSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings_ThrottlingSettings {
    return new LighthouseResult_ConfigSettings_ThrottlingSettings().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_ConfigSettings_ThrottlingSettings | PlainMessage<LighthouseResult_ConfigSettings_ThrottlingSettings> | undefined, b: LighthouseResult_ConfigSettings_ThrottlingSettings | PlainMessage<LighthouseResult_ConfigSettings_ThrottlingSettings> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_ConfigSettings_ThrottlingSettings, a, b);
  }
}

/**
 * @generated from message nozzle.urls.v1.LighthouseResult.ConfigSettings.ScreenEmulation
 */
export class LighthouseResult_ConfigSettings_ScreenEmulation extends Message<LighthouseResult_ConfigSettings_ScreenEmulation> {
  /**
   * Overriding width value in pixels (minimum 0, maximum 10000000). 0 disables the override.
   *
   * @generated from field: double width = 1;
   */
  width = 0;

  /**
   * Overriding height value in pixels (minimum 0, maximum 10000000). 0 disables the override.
   *
   * @generated from field: double height = 2;
   */
  height = 0;

  /**
   * Overriding device scale factor value. 0 disables the override.
   *
   * @generated from field: double deviceScaleFactor = 3;
   */
  deviceScaleFactor = 0;

  /**
   * Whether to emulate mobile device. This includes viewport meta tag, overlay scrollbars, text autosizing and more.
   *
   * @generated from field: bool mobile = 4;
   */
  mobile = false;

  /**
   * Whether screen emulation is disabled. If true, the other emulation settings are ignored.
   *
   * @generated from field: bool disabled = 5;
   */
  disabled = false;

  constructor(data?: PartialMessage<LighthouseResult_ConfigSettings_ScreenEmulation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.ConfigSettings.ScreenEmulation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "height", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "deviceScaleFactor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "mobile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_ConfigSettings_ScreenEmulation {
    return new LighthouseResult_ConfigSettings_ScreenEmulation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings_ScreenEmulation {
    return new LighthouseResult_ConfigSettings_ScreenEmulation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_ConfigSettings_ScreenEmulation {
    return new LighthouseResult_ConfigSettings_ScreenEmulation().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_ConfigSettings_ScreenEmulation | PlainMessage<LighthouseResult_ConfigSettings_ScreenEmulation> | undefined, b: LighthouseResult_ConfigSettings_ScreenEmulation | PlainMessage<LighthouseResult_ConfigSettings_ScreenEmulation> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_ConfigSettings_ScreenEmulation, a, b);
  }
}

/**
 * Message containing the performance timing data for the Lighthouse run
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.Timing
 */
export class LighthouseResult_Timing extends Message<LighthouseResult_Timing> {
  /**
   * The total duration of Lighthouse's run
   *
   * @generated from field: google.protobuf.DoubleValue total = 1;
   */
  total?: number;

  /**
   * Corresponds to:
   * https://www.w3.org/TR/performance-timeline-2/#idl-def-performanceentrylist
   *
   * @generated from field: repeated nozzle.urls.v1.LighthouseResult.Timing.PerformanceEntry entries = 2;
   */
  entries: LighthouseResult_Timing_PerformanceEntry[] = [];

  constructor(data?: PartialMessage<LighthouseResult_Timing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.Timing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "message", T: DoubleValue },
    { no: 2, name: "entries", kind: "message", T: LighthouseResult_Timing_PerformanceEntry, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_Timing {
    return new LighthouseResult_Timing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_Timing {
    return new LighthouseResult_Timing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_Timing {
    return new LighthouseResult_Timing().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_Timing | PlainMessage<LighthouseResult_Timing> | undefined, b: LighthouseResult_Timing | PlainMessage<LighthouseResult_Timing> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_Timing, a, b);
  }
}

/**
 * Corresponds to:
 * https://www.w3.org/TR/performance-timeline-2/#dom-performanceentry
 *
 * @generated from message nozzle.urls.v1.LighthouseResult.Timing.PerformanceEntry
 */
export class LighthouseResult_Timing_PerformanceEntry extends Message<LighthouseResult_Timing_PerformanceEntry> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string entry_type = 2;
   */
  entryType = "";

  /**
   * @generated from field: google.protobuf.DoubleValue start_time = 3;
   */
  startTime?: number;

  /**
   * @generated from field: google.protobuf.DoubleValue duration = 4;
   */
  duration?: number;

  /**
   * Whether timing entry was collected during artifact gathering.
   *
   * @generated from field: bool gather = 5;
   */
  gather = false;

  constructor(data?: PartialMessage<LighthouseResult_Timing_PerformanceEntry>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LighthouseResult.Timing.PerformanceEntry";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start_time", kind: "message", T: DoubleValue },
    { no: 4, name: "duration", kind: "message", T: DoubleValue },
    { no: 5, name: "gather", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseResult_Timing_PerformanceEntry {
    return new LighthouseResult_Timing_PerformanceEntry().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseResult_Timing_PerformanceEntry {
    return new LighthouseResult_Timing_PerformanceEntry().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseResult_Timing_PerformanceEntry {
    return new LighthouseResult_Timing_PerformanceEntry().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseResult_Timing_PerformanceEntry | PlainMessage<LighthouseResult_Timing_PerformanceEntry> | undefined, b: LighthouseResult_Timing_PerformanceEntry | PlainMessage<LighthouseResult_Timing_PerformanceEntry> | undefined): boolean {
    return proto3.util.equals(LighthouseResult_Timing_PerformanceEntry, a, b);
  }
}

/**
 * Message containing a category
 *
 * @generated from message nozzle.urls.v1.CategoryGroup
 */
export class CategoryGroup extends Message<CategoryGroup> {
  /**
   * The human readable title of the group
   *
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * The description of what the category is grouping
   *
   * @generated from field: string description = 2;
   */
  description = "";

  constructor(data?: PartialMessage<CategoryGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.CategoryGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryGroup {
    return new CategoryGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryGroup {
    return new CategoryGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryGroup {
    return new CategoryGroup().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryGroup | PlainMessage<CategoryGroup> | undefined, b: CategoryGroup | PlainMessage<CategoryGroup> | undefined): boolean {
    return proto3.util.equals(CategoryGroup, a, b);
  }
}

/**
 * Message containing a category of audits and their combined weighted score.
 *
 * @generated from message nozzle.urls.v1.LhrCategory
 */
export class LhrCategory extends Message<LhrCategory> {
  /**
   * the internal id of the category
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The human-friendly name of the category.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * A description of what this category is about (e.g. these help you validate
   * your PWA).
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * The overall score of the category, the weighted average of all its audits,
   * from 0-1.
   * This value is nullable, so is a `Value` type
   *
   * @generated from field: google.protobuf.Value score = 4;
   */
  score?: Value;

  /**
   * An description for manual audits within this category.
   *
   * @generated from field: string manual_description = 5;
   */
  manualDescription = "";

  /**
   * References to all the audit members and their weight in this category.
   *
   * @generated from field: repeated nozzle.urls.v1.LhrCategory.AuditRef audit_refs = 6;
   */
  auditRefs: LhrCategory_AuditRef[] = [];

  /**
   * List of gather modes this category is available in.
   *
   * @generated from field: repeated string supported_modes = 7;
   */
  supportedModes: string[] = [];

  constructor(data?: PartialMessage<LhrCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LhrCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score", kind: "message", T: Value },
    { no: 5, name: "manual_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "audit_refs", kind: "message", T: LhrCategory_AuditRef, repeated: true },
    { no: 7, name: "supported_modes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LhrCategory {
    return new LhrCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LhrCategory {
    return new LhrCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LhrCategory {
    return new LhrCategory().fromJsonString(jsonString, options);
  }

  static equals(a: LhrCategory | PlainMessage<LhrCategory> | undefined, b: LhrCategory | PlainMessage<LhrCategory> | undefined): boolean {
    return proto3.util.equals(LhrCategory, a, b);
  }
}

/**
 * A Category's reference to an AuditResult, with a weight for category
 * scoring.
 *
 * @generated from message nozzle.urls.v1.LhrCategory.AuditRef
 */
export class LhrCategory_AuditRef extends Message<LhrCategory_AuditRef> {
  /**
   * Matches a key in the top-level `audits` map.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The weight of the audit's score in the overall category score.
   *
   * @generated from field: google.protobuf.DoubleValue weight = 2;
   */
  weight?: number;

  /**
   * The category group that the audit belongs to.
   *
   * @generated from field: string group = 3;
   */
  group = "";

  /**
   * The conventional acronym for the audit/metric.
   *
   * @generated from field: string acronym = 4;
   */
  acronym = "";

  /**
   * Any audit IDs closely relevant to this one.
   *
   * @generated from field: repeated string relevant_audits = 5;
   */
  relevantAudits: string[] = [];

  constructor(data?: PartialMessage<LhrCategory_AuditRef>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LhrCategory.AuditRef";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "weight", kind: "message", T: DoubleValue },
    { no: 3, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "acronym", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "relevant_audits", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LhrCategory_AuditRef {
    return new LhrCategory_AuditRef().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LhrCategory_AuditRef {
    return new LhrCategory_AuditRef().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LhrCategory_AuditRef {
    return new LhrCategory_AuditRef().fromJsonString(jsonString, options);
  }

  static equals(a: LhrCategory_AuditRef | PlainMessage<LhrCategory_AuditRef> | undefined, b: LhrCategory_AuditRef | PlainMessage<LhrCategory_AuditRef> | undefined): boolean {
    return proto3.util.equals(LhrCategory_AuditRef, a, b);
  }
}

/**
 * Message containing the result of an individual Lighthouse audit.
 *
 * @generated from message nozzle.urls.v1.AuditResult
 */
export class AuditResult extends Message<AuditResult> {
  /**
   * The internal audit id
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * A brief description of the audit. The text can change depending on if the
   * audit passed or failed.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * A more detailed description that describes why the audit is important and
   * links to Lighthouse documentation on the audit; markdown links supported.
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * The scored value determined by the audit, in the range `0-1`, or NaN if
   * `score_display_mode` indicates not scored.
   * This value is nullable, so is a `Value` type
   *
   * @generated from field: google.protobuf.Value score = 4;
   */
  score?: Value;

  /**
   * The mode for how the score should be interpreted.
   *
   * @generated from field: nozzle.urls.v1.AuditResult.ScoreDisplayMode score_display_mode = 5;
   */
  scoreDisplayMode = AuditResult_ScoreDisplayMode.SCORE_DISPLAY_MODE_UNSPECIFIED;

  /**
   * The human readable value that is displayed as the audit's result
   *
   * @generated from field: string display_value = 6;
   */
  displayValue = "";

  /**
   * An explanation of audit-related issues encountered on the test page.
   *
   * @generated from field: string explanation = 7;
   */
  explanation = "";

  /**
   * Error message from any exception thrown while running this audit.
   *
   * @generated from field: string error_message = 8;
   */
  errorMessage = "";

  /**
   * Extra information provided by some types of audits.  Given as an arbitrary
   * object.
   *
   * @generated from field: google.protobuf.Struct details = 9;
   */
  details?: Struct;

  /**
   * List of warnings associated with this audit
   * type of `Value` since this can be null
   *
   * @generated from field: google.protobuf.Value warnings = 10;
   */
  warnings?: Value;

  /**
   * A numeric value that has a meaning specific to the audit, e.g. the number
   * of nodes in the DOM or the timestamp of a specific load event. More
   * information can be found in the audit details, if present.
   *
   * @generated from field: google.protobuf.DoubleValue numeric_value = 11;
   */
  numericValue?: number;

  /**
   * The unit of the numeric_value field. Used to format the numeric value for
   * display.
   *
   * @generated from field: string numeric_unit = 12;
   */
  numericUnit = "";

  constructor(data?: PartialMessage<AuditResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.AuditResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "score", kind: "message", T: Value },
    { no: 5, name: "score_display_mode", kind: "enum", T: proto3.getEnumType(AuditResult_ScoreDisplayMode) },
    { no: 6, name: "display_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "explanation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "details", kind: "message", T: Struct },
    { no: 10, name: "warnings", kind: "message", T: Value },
    { no: 11, name: "numeric_value", kind: "message", T: DoubleValue },
    { no: 12, name: "numeric_unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuditResult {
    return new AuditResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuditResult {
    return new AuditResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuditResult {
    return new AuditResult().fromJsonString(jsonString, options);
  }

  static equals(a: AuditResult | PlainMessage<AuditResult> | undefined, b: AuditResult | PlainMessage<AuditResult> | undefined): boolean {
    return proto3.util.equals(AuditResult, a, b);
  }
}

/**
 * The ways an audit score should be interpreted:
 *
 * @generated from enum nozzle.urls.v1.AuditResult.ScoreDisplayMode
 */
export enum AuditResult_ScoreDisplayMode {
  /**
   * Unknown mode. This should not be used.
   *
   * @generated from enum value: SCORE_DISPLAY_MODE_UNSPECIFIED = 0;
   */
  SCORE_DISPLAY_MODE_UNSPECIFIED = 0,

  /**
   * Pass/fail audit (0 and 1 are the only possible scores).
   *
   * @generated from enum value: binary = 1;
   */
  binary = 1,

  /**
   * Scores of 0-1, inclusive.
   *
   * @generated from enum value: numeric = 2;
   */
  numeric = 2,

  /**
   * The audit is an FYI only, and can't be interpreted as pass/fail. Score is
   * NaN and should be ignored.
   *
   * @generated from enum value: informative = 3;
   */
  informative = 3,

  /**
   * The audit turned out to not apply to the page. Score is NaN and should be
   * ignored.
   *
   * @generated from enum value: not_applicable = 4;
   */
  not_applicable = 4,

  /**
   * @generated from enum value: notApplicable = 7;
   */
  notApplicable = 7,

  /**
   * The audit exists only to tell you to review something yourself. Score is
   * NaN and should be ignored
   *
   * @generated from enum value: manual = 5;
   */
  manual = 5,

  /**
   * There was an error while running the audit (check `error_message` for
   * details). Score is NaN and should be ignored.
   *
   * @generated from enum value: error = 6;
   */
  error = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(AuditResult_ScoreDisplayMode)
proto3.util.setEnumType(AuditResult_ScoreDisplayMode, "nozzle.urls.v1.AuditResult.ScoreDisplayMode", [
  { no: 0, name: "SCORE_DISPLAY_MODE_UNSPECIFIED" },
  { no: 1, name: "binary" },
  { no: 2, name: "numeric" },
  { no: 3, name: "informative" },
  { no: 4, name: "not_applicable" },
  { no: 7, name: "notApplicable" },
  { no: 5, name: "manual" },
  { no: 6, name: "error" },
]);

/**
 * Message containing the i18n data for the LHR - Version 1
 *
 * @generated from message nozzle.urls.v1.I18n
 */
export class I18n extends Message<I18n> {
  /**
   * The message holding all formatted strings used in the renderer.
   *
   * @generated from field: nozzle.urls.v1.I18n.RendererFormattedStrings renderer_formatted_strings = 1;
   */
  rendererFormattedStrings?: I18n_RendererFormattedStrings;

  /**
   * Holds all message paths used. The locale of the report has already be used to translated
   * the strings in this LighthouseResult, but this field can be used to translate into another
   * language.
   *
   * @generated from field: map<string, google.protobuf.ListValue> icu_message_paths = 2;
   */
  icuMessagePaths: { [key: string]: ListValue } = {};

  constructor(data?: PartialMessage<I18n>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.I18n";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "renderer_formatted_strings", kind: "message", T: I18n_RendererFormattedStrings },
    { no: 2, name: "icu_message_paths", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ListValue} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): I18n {
    return new I18n().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): I18n {
    return new I18n().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): I18n {
    return new I18n().fromJsonString(jsonString, options);
  }

  static equals(a: I18n | PlainMessage<I18n> | undefined, b: I18n | PlainMessage<I18n> | undefined): boolean {
    return proto3.util.equals(I18n, a, b);
  }
}

/**
 * Message holding the formatted strings used in the renderer
 *
 * @generated from message nozzle.urls.v1.I18n.RendererFormattedStrings
 */
export class I18n_RendererFormattedStrings extends Message<I18n_RendererFormattedStrings> {
  /**
   * The disclaimer shown below a performance metric value
   *
   * @generated from field: string variance_disclaimer = 1;
   */
  varianceDisclaimer = "";

  /**
   * The heading for the estimated page load savings opportunity of an audit
   *
   * @generated from field: string opportunity_resource_column_label = 2;
   */
  opportunityResourceColumnLabel = "";

  /**
   * The heading for the estimated page load savings of opportunitu audits
   *
   * @generated from field: string opportunity_savings_column_label = 3;
   */
  opportunitySavingsColumnLabel = "";

  /**
   * The error string shown next to an erroring audit
   *
   * @generated from field: string error_missing_audit_info = 4;
   */
  errorMissingAuditInfo = "";

  /**
   * The label shown next to an audit or metric that has had an error
   *
   * @generated from field: string error_label = 5;
   */
  errorLabel = "";

  /**
   * The label shown above a bulleted list of warnings
   *
   * @generated from field: string warning_header = 6;
   */
  warningHeader = "";

  /**
   * The tooltip text on an expandable chevron icon
   *
   * @generated from field: string audit_group_expand_tooltip = 7;
   */
  auditGroupExpandTooltip = "";

  /**
   * The heading that is shown above a list of audits that are passing
   *
   * @generated from field: string passed_audits_group_title = 8;
   */
  passedAuditsGroupTitle = "";

  /**
   * The heading shown above a list of audits that do not apply to a page
   *
   * @generated from field: string not_applicable_audits_group_title = 9;
   */
  notApplicableAuditsGroupTitle = "";

  /**
   * The heading shown above a list of audits that were not computerd in the
   * run
   *
   * @generated from field: string manual_audits_group_title = 10;
   */
  manualAuditsGroupTitle = "";

  /**
   * The label shown preceding important warnings that may have invalidated an
   * entire report
   *
   * @generated from field: string toplevel_warnings_message = 11;
   */
  toplevelWarningsMessage = "";

  /**
   * The label that explains the score gauges scale (0-49, 50-89, 90-100)
   *
   * @generated from field: string scorescale_label = 12;
   */
  scorescaleLabel = "";

  /**
   * The label for values shown in the summary of critical request chains
   *
   * @generated from field: string crc_longest_duration_label = 13;
   */
  crcLongestDurationLabel = "";

  /**
   * The label for the initial request in a critical request chain
   *
   * @generated from field: string crc_initial_navigation = 14;
   */
  crcInitialNavigation = "";

  /**
   * The disclaimer shown under performance explaining that the network can
   * vary
   *
   * @generated from field: string ls_performance_category_description = 15;
   */
  lsPerformanceCategoryDescription = "";

  /**
   * The title of the lab data performance category
   *
   * @generated from field: string lab_data_title = 16;
   */
  labDataTitle = "";

  /**
   * The heading that is shown above a list of audits that have warnings
   *
   * @generated from field: string warning_audits_group_title = 17;
   */
  warningAuditsGroupTitle = "";

  /**
   * The label for the button to show all lines of a snippet
   *
   * @generated from field: string snippet_expand_button_label = 18;
   */
  snippetExpandButtonLabel = "";

  /**
   * The label for the button to show only a few lines of a snippet
   *
   * @generated from field: string snippet_collapse_button_label = 19;
   */
  snippetCollapseButtonLabel = "";

  /**
   * This label is for a filter checkbox above a table of items
   *
   * @generated from field: string third_party_resources_label = 20;
   */
  thirdPartyResourcesLabel = "";

  /**
   * Descriptive explanation for emulation setting when emulating a generic
   * desktop form factor, as opposed to a mobile-device like form factor.
   *
   * @generated from field: string runtime_desktop_emulation = 21;
   */
  runtimeDesktopEmulation = "";

  /**
   * Descriptive explanation for emulation setting when emulating a Nexus 5X
   * mobile device.
   *
   * @generated from field: string runtime_mobile_emulation = 22;
   */
  runtimeMobileEmulation = "";

  /**
   * Descriptive explanation for emulation setting when no device emulation is
   * set.
   *
   * @generated from field: string runtime_no_emulation = 23;
   */
  runtimeNoEmulation = "";

  /**
   * Label for a row in a table that shows the estimated CPU power of the
   * machine running Lighthouse. Example row values: 532, 1492, 783.
   *
   * @generated from field: string runtime_settings_benchmark = 24;
   */
  runtimeSettingsBenchmark = "";

  /**
   * Label for a row in a table that describes the CPU throttling conditions
   * that were used during a Lighthouse run, if any.
   *
   * @generated from field: string runtime_settings_CPU_throttling = 25;
   */
  runtimeSettingsCPUThrottling = "";

  /**
   * Label for a row in a table that describes the kind of device that was
   * emulated for the Lighthouse run.  Example values for row elements: 'No
   * Emulation', 'Emulated Desktop', etc.
   *
   * @generated from field: string runtime_settings_device = 26;
   */
  runtimeSettingsDevice = "";

  /**
   * Label for a row in a table that shows the time at which a Lighthouse run
   * was conducted; formatted as a timestamp, e.g. Jan 1, 1970 12:00 AM UTC.
   *
   * @generated from field: string runtime_settings_fetch_time = 27 [deprecated = true];
   * @deprecated
   */
  runtimeSettingsFetchTime = "";

  /**
   * Label for a row in a table that describes the network throttling
   * conditions that were used during a Lighthouse run, if any.
   *
   * @generated from field: string runtime_settings_network_throttling = 28;
   */
  runtimeSettingsNetworkThrottling = "";

  /**
   * Title of the Runtime settings table in a Lighthouse report.  Runtime
   * settings are the environment configurations that a specific report used
   * at auditing time.
   *
   * @generated from field: string runtime_settings_title = 29 [deprecated = true];
   * @deprecated
   */
  runtimeSettingsTitle = "";

  /**
   * Label for a row in a table that shows the User Agent that was detected on
   * the Host machine that ran Lighthouse.
   *
   * @generated from field: string runtime_settings_UA = 30 [deprecated = true];
   * @deprecated
   */
  runtimeSettingsUA = "";

  /**
   * Label for a row in a table that shows the User Agent that was used to
   * send out all network requests during the Lighthouse run.
   *
   * @generated from field: string runtime_settings_UA_network = 31;
   */
  runtimeSettingsUANetwork = "";

  /**
   * Label for a row in a table that shows the URL that was audited during a
   * Lighthouse run.
   *
   * @generated from field: string runtime_settings_Url = 32 [deprecated = true];
   * @deprecated
   */
  runtimeSettingsUrl = "";

  /**
   * Descriptive explanation for a runtime setting that is set to an unknown
   * value.
   *
   * @generated from field: string runtime_unknown = 33;
   */
  runtimeUnknown = "";

  /**
   * Option in a dropdown menu that copies the Lighthouse JSON object to the
   * system clipboard.
   *
   * @generated from field: string dropdown_copy_JSON = 34;
   */
  dropdownCopyJSON = "";

  /**
   * Option in a dropdown menu that toggles the themeing of the report between
   * Light(default) and Dark themes.
   *
   * @generated from field: string dropdown_dark_theme = 35;
   */
  dropdownDarkTheme = "";

  /**
   * Option in a dropdown menu that opens a full Lighthouse report in a print
   * dialog.
   *
   * @generated from field: string dropdown_print_expanded = 36;
   */
  dropdownPrintExpanded = "";

  /**
   * Option in a dropdown menu that opens a small, summary report in a print
   * dialog.
   *
   * @generated from field: string dropdown_print_summary = 37;
   */
  dropdownPrintSummary = "";

  /**
   * Option in a dropdown menu that saves the current report as a new GitHub
   * Gist.
   *
   * @generated from field: string dropdown_save_gist = 38;
   */
  dropdownSaveGist = "";

  /**
   * Option in a dropdown menu that saves the Lighthouse report HTML locally
   * to the system as a '.html' file.
   *
   * @generated from field: string dropdown_save_HTML = 39;
   */
  dropdownSaveHTML = "";

  /**
   * Option in a dropdown menu that saves the Lighthouse JSON object to the
   * local system as a '.json' file.
   *
   * @generated from field: string dropdown_save_JSON = 40;
   */
  dropdownSaveJSON = "";

  /**
   * Option in a dropdown menu that opens the current report in the Lighthouse
   * Viewer Application.
   *
   * @generated from field: string dropdown_viewer = 41;
   */
  dropdownViewer = "";

  /**
   * Label for button to create an issue against the Lighthouse GitHub
   * project.
   *
   * @generated from field: string footer_issue = 42;
   */
  footerIssue = "";

  /**
   * Descriptive explanation for environment throttling that was provided by
   * the runtime environment instead of provided by Lighthouse throttling.
   *
   * @generated from field: string throttling_provided = 43;
   */
  throttlingProvided = "";

  /**
   * Label for a row in a table that shows in what tool Lighthouse is being
   * run (e.g. The lighthouse CLI, Chrome DevTools, Lightrider, WebPageTest,
   * etc).
   *
   * @generated from field: string runtime_settings_channel = 44 [deprecated = true];
   * @deprecated
   */
  runtimeSettingsChannel = "";

  /**
   * Text link pointing to the Lighthouse scoring calculator. This link
   * immediately follows a sentence stating the performance score is
   * calculated from the perf metrics.
   *
   * @generated from field: string calculator_link = 45;
   */
  calculatorLink = "";

  /**
   * Label for a row in a table that shows the version of the Axe library used
   *
   * @generated from field: string runtime_settings_axe_version = 46;
   */
  runtimeSettingsAxeVersion = "";

  /**
   * Label for a button that opens the Treemap App
   *
   * @generated from field: string view_treemap_label = 47;
   */
  viewTreemapLabel = "";

  /**
   * Label preceding a radio control for filtering the list of audits. The
   * radio choices are various performance metrics (FCP, LCP, TBT), and if
   * chosen, the audits in the report are hidden if they are not relevant to
   * the selected metric.
   *
   * @generated from field: string show_relevant_audits = 48;
   */
  showRelevantAudits = "";

  /**
   * Descriptive label that this analysis run was from a single pageload
   * of a browser (not a summary of hundreds of loads)
   *
   * @generated from field: string runtime_single_load = 49;
   */
  runtimeSingleLoad = "";

  /**
   * Descriptive explanation that this analysis run was from a single pageload
   * of a browser, whereas field data often summarizes hundreds+ of page loads
   *
   * @generated from field: string runtime_single_load_tooltip = 50;
   */
  runtimeSingleLoadTooltip = "";

  /**
   * Descriptive label that this analysis only considers the initial load of the page,
   * and no interaction beyond when the page had "fully loaded"
   *
   * @generated from field: string runtime_analysis_window = 51;
   */
  runtimeAnalysisWindow = "";

  /**
   * Label for an interactive control that will reveal or hide a group of content.
   * This control toggles between the text 'Show' and 'Hide'.
   *
   * @generated from field: string show = 52;
   */
  show = "";

  /**
   * Label for an interactive control that will reveal or hide a group of content.
   * This control toggles between the text 'Show' and 'Hide'.
   *
   * @generated from field: string hide = 53;
   */
  hide = "";

  /**
   * Label for an interactive control that will reveal or hide a group of content.
   * This control toggles between the text 'Expand view' and 'Collapse view'.
   *
   * @generated from field: string expand_view = 54;
   */
  expandView = "";

  /**
   * Label for an interactive control that will reveal or hide a group of content.
   * This control toggles between the text 'Expand view' and 'Collapse view'.
   *
   * @generated from field: string collapse_view = 55;
   */
  collapseView = "";

  /**
   * Label indicating that Lighthouse throttled the page to emulate a slow 4G network connection.
   *
   * @generated from field: string runtime_slow_4g = 56;
   */
  runtimeSlow4g = "";

  /**
   * Label indicating that Lighthouse throttled the page using custom throttling settings.
   *
   * @generated from field: string runtime_custom = 57;
   */
  runtimeCustom = "";

  /**
   * This label is for a button that will show the user a trace of the page.
   *
   * @generated from field: string view_trace_label = 58;
   */
  viewTraceLabel = "";

  /**
   * This label is for a button that will show the user a trace of the page.
   *
   * @generated from field: string view_original_trace_label = 59;
   */
  viewOriginalTraceLabel = "";

  /**
   * Label for a row in a table that shows the screen emulation used
   *
   * @generated from field: string runtime_settings_screen_emulation = 60;
   */
  runtimeSettingsScreenEmulation = "";

  /**
   * Label for a row decorative chip indiciating entity is first-party.
   *
   * @generated from field: string first_party_chip_label = 61;
   */
  firstPartyChipLabel = "";

  /**
   * Label for a link tooltip indicating that it will be opened in a new tab of the browser.
   *
   * @generated from field: string open_in_a_new_tab_tooltip = 62;
   */
  openInANewTabTooltip = "";

  /**
   * Label for a generic category for all resources that could not be attributed against a 1st or 3rd party entity.
   *
   * @generated from field: string unattributable = 63;
   */
  unattributable = "";

  constructor(data?: PartialMessage<I18n_RendererFormattedStrings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.I18n.RendererFormattedStrings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variance_disclaimer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "opportunity_resource_column_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "opportunity_savings_column_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "error_missing_audit_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "error_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "warning_header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "audit_group_expand_tooltip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "passed_audits_group_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "not_applicable_audits_group_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "manual_audits_group_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "toplevel_warnings_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "scorescale_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "crc_longest_duration_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "crc_initial_navigation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "ls_performance_category_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "lab_data_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "warning_audits_group_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "snippet_expand_button_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "snippet_collapse_button_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "third_party_resources_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "runtime_desktop_emulation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "runtime_mobile_emulation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "runtime_no_emulation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "runtime_settings_benchmark", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "runtime_settings_CPU_throttling", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "runtime_settings_device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "runtime_settings_fetch_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "runtime_settings_network_throttling", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "runtime_settings_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "runtime_settings_UA", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "runtime_settings_UA_network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "runtime_settings_Url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "runtime_unknown", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 34, name: "dropdown_copy_JSON", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 35, name: "dropdown_dark_theme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "dropdown_print_expanded", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "dropdown_print_summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "dropdown_save_gist", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "dropdown_save_HTML", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 40, name: "dropdown_save_JSON", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 41, name: "dropdown_viewer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 42, name: "footer_issue", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 43, name: "throttling_provided", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 44, name: "runtime_settings_channel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 45, name: "calculator_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 46, name: "runtime_settings_axe_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 47, name: "view_treemap_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 48, name: "show_relevant_audits", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 49, name: "runtime_single_load", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 50, name: "runtime_single_load_tooltip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 51, name: "runtime_analysis_window", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 52, name: "show", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 53, name: "hide", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 54, name: "expand_view", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 55, name: "collapse_view", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 56, name: "runtime_slow_4g", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 57, name: "runtime_custom", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 58, name: "view_trace_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 59, name: "view_original_trace_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 60, name: "runtime_settings_screen_emulation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 61, name: "first_party_chip_label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 62, name: "open_in_a_new_tab_tooltip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 63, name: "unattributable", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): I18n_RendererFormattedStrings {
    return new I18n_RendererFormattedStrings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): I18n_RendererFormattedStrings {
    return new I18n_RendererFormattedStrings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): I18n_RendererFormattedStrings {
    return new I18n_RendererFormattedStrings().fromJsonString(jsonString, options);
  }

  static equals(a: I18n_RendererFormattedStrings | PlainMessage<I18n_RendererFormattedStrings> | undefined, b: I18n_RendererFormattedStrings | PlainMessage<I18n_RendererFormattedStrings> | undefined): boolean {
    return proto3.util.equals(I18n_RendererFormattedStrings, a, b);
  }
}

/**
 * Message containing Stack Pack information.
 *
 * @generated from message nozzle.urls.v1.StackPack
 */
export class StackPack extends Message<StackPack> {
  /**
   * The human readable id of the Stack Pack.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * The user facing title of the Stack Pack.
   *
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * The icon as a data url.
   *
   * @generated from field: string icon_data_URL = 3;
   */
  iconDataURL = "";

  /**
   * Map of audit-ids to Stack Pack descriptions.
   *
   * @generated from field: map<string, string> descriptions = 4;
   */
  descriptions: { [key: string]: string } = {};

  constructor(data?: PartialMessage<StackPack>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.StackPack";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon_data_URL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "descriptions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StackPack {
    return new StackPack().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StackPack {
    return new StackPack().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StackPack {
    return new StackPack().fromJsonString(jsonString, options);
  }

  static equals(a: StackPack | PlainMessage<StackPack> | undefined, b: StackPack | PlainMessage<StackPack> | undefined): boolean {
    return proto3.util.equals(StackPack, a, b);
  }
}

/**
 * Message containing an Entity.
 *
 * @generated from message nozzle.urls.v1.LhrEntity
 */
export class LhrEntity extends Message<LhrEntity> {
  /**
   * Name of the entity.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * An optional homepage URL of the entity.
   *
   * @generated from field: optional string homepage = 2;
   */
  homepage?: string;

  /**
   * An optional category name for the entity.
   *
   * @generated from field: optional string category = 3;
   */
  category?: string;

  /**
   * An optional flag indicating if the entity is the first party.
   *
   * @generated from field: optional bool is_first_party = 4;
   */
  isFirstParty?: boolean;

  /**
   * An optional flag indicating if the entity is not recognized.
   *
   * @generated from field: optional bool is_unrecognized = 5;
   */
  isUnrecognized?: boolean;

  /**
   * A list of URL origin strings that belong to this entity.
   *
   * @generated from field: repeated string origins = 6;
   */
  origins: string[] = [];

  constructor(data?: PartialMessage<LhrEntity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.urls.v1.LhrEntity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "homepage", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "is_first_party", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "is_unrecognized", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "origins", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LhrEntity {
    return new LhrEntity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LhrEntity {
    return new LhrEntity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LhrEntity {
    return new LhrEntity().fromJsonString(jsonString, options);
  }

  static equals(a: LhrEntity | PlainMessage<LhrEntity> | undefined, b: LhrEntity | PlainMessage<LhrEntity> | undefined): boolean {
    return proto3.util.equals(LhrEntity, a, b);
  }
}

