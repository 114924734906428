import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_auth/_nav/projects')({
  preSearchFilters: [
    search => {
      return {
        ...search,
        projectsOverviewWidget: search?.projectsOverviewWidget,
      }
    },
  ],
})
