import { useQuery } from 'react-query'
import { nozzleCacheClient } from './nozzleCacheClient'
import MeiliSearch, { Index } from 'meilisearch'
import { getAccessToken } from './Api'
import LocalStorage from './LocalStorage'
import * as React from 'react'
import { countryOptions, formatCountry } from '../options/countryOptions'
import { languageOptions } from '../options/languageOptions'
import { locationTypeOptions } from './locationType'
import { formatOption } from './Format'
import { formatEngine, renderEngine } from './engines'
import { Tag } from '../components/Tags'

export type LocaleModelNz = {
  localeId: number
  mostFrequentScheduleId: string | null
  groups?: string[]
  aliases?: LocaleModelAlias[]
}

export type LocaleModelAlias = {
  alias: string
  groups?: string[]
  mostFrequentScheduleId: string
}

export type Locale = {
  engine: string
  location: string
  country: string
  language: string
  location_type: string
  locale_id: number
  language_code: string
  country_code: string
  location_id: number
  location_type_priority?: number
  locale_created_at?: string
  location_created_at?: string
}

export function useLocalesSearchIndex() {
  const [searchIndex, setSearchIndex] = React.useState<
    undefined | Index<Locale>
  >()

  const nearest5Minute = Math.floor(Date.now() / 300000) * 300000

  React.useEffect(() => {
    getAccessToken().then(token => {
      const client = new MeiliSearch({
        host: 'https://api.nozzle.app/locales',
        apiKey: token,
      })
      setSearchIndex(client.index('locales'))
    })
  }, [nearest5Minute])

  return searchIndex
}

export function useLocalesSearchQuery(search: string) {
  const localesSearchIndex = useLocalesSearchIndex()
  const recentLocales = useRecentLocales()

  return useQuery(
    ['locales-search', search],
    async () => {
      if (search) {
        return localesSearchIndex!.search!(search).then(d => d.hits)
      }

      return Promise.all(
        [
          ...recentLocales.recentLocaleIds.filter(
            d => !quickLocaleIds.includes(d)
          ),
          ...quickLocaleIds,
        ].map(async d => {
          const existing = (await nozzleCacheClient.get([
            'locale',
            Number(d),
          ])) as Locale

          if (existing) {
            return existing
          }

          return localesSearchIndex!.getDocument(d)!
        })
      )
    },
    {
      enabled: Boolean(localesSearchIndex?.search),
      keepPreviousData: true,
    }
  )
}

export function useLocalesByIdQuery(opts: { localeIds: (string | number)[] }) {
  const searchIndex = useLocalesSearchIndex()
  const localeIdsSet = new Set(opts.localeIds)
  const localeIdsArr = [...localeIdsSet.values()]

  return useQuery({
    queryKey: ['localesByid', localeIdsArr],
    queryFn: async () => {
      const locales = (
        await searchIndex!.getDocuments({
          limit: 999999999,
          filter: `locale_id IN [${localeIdsArr.join(',')}]`,
        })
      ).results.map(d => [d.locale_id, d])

      if (locales.length !== localeIdsArr.length) {
        throw new Error(
          'Not all locales were returned by MeiliSearch. We might need to chunk and batch multiple requests.'
        )
      }

      return Object.fromEntries(locales)
    },
    enabled: Boolean(opts.localeIds && searchIndex?.getDocument),
    keepPreviousData: true,
    retry: false,
  })
}

//

export const quickLocaleIds = [
  // engine: 'Google',
  // language: 'English',
  // location: 'US',
  // type: 'Country',
  44249,
  // engine: 'Google',
  // language: 'English',
  // location: 'UK',
  // type: 'Country',
  15405,
  // engine: 'Google',
  // language: 'English',
  // location: 'Canada',
  // type: 'Country',
  4586,
  // engine: 'Google',
  // language: 'Spanish',
  // location: 'US',
  // type: 'Country',
  60626,
  // engine: 'Google',
  // language: 'Portuguese',
  // location: 'Brazil',
  // type: 'Country',
  3615,
  // engine: 'Google',
  // language: 'English',
  // location: 'New York, New York',
  // type: 'City',
  38283,
  // engine: 'Google',
  // language: 'English',
  // location: 'Los Angeles, California',
  // type: 'City',
  36430,
  // engine: 'Google',
  // language: 'English',
  // location: 'London, England',
  // type: 'City',
  14964,
]

export function useRecentLocales() {
  const [recentLocaleIds, setRecentLocaleIds] = React.useState<number[]>(
    LocalStorage.get('recentLocaleIds') || []
  )

  React.useEffect(() => {
    LocalStorage.set('recentLocaleIds', recentLocaleIds.slice(0, 100))
  }, [recentLocaleIds])

  return {
    recentLocaleIds,
    setRecentLocaleIds,
  } as const
}

export function formatLocaleCountry(locale: Locale) {
  return formatCountry(locale.country ?? locale.country_code)
}

export function renderLocaleCountry(locale: Locale) {
  return <Tag compact>{formatLocaleCountry(locale)}</Tag>
}

export function formatLocaleLanguage(locale: Locale) {
  return formatOption(languageOptions, locale.language ?? locale.language_code)
}

export function renderLocaleLanguage(locale: Locale) {
  return <Tag compact>{formatLocaleLanguage(locale)}</Tag>
}

export function formatLocaleLocationType(locale: Locale) {
  return formatOption(locationTypeOptions, locale.location_type)
}

export function renderLocaleLocationType(locale: Locale) {
  return <Tag compact>{formatLocaleLocationType(locale)}</Tag>
}

export function formatLocaleLocationCombo(locale: Locale) {
  return locale.location_type === 'Country'
    ? ''
    : `${formatLocaleLocationType(locale)}: ${locale.location}`
}

export function renderLocaleLocationCombo(locale: Locale) {
  const formatted = formatLocaleLocationCombo(locale)
  return formatted ? <Tag compact>{formatted}</Tag> : null
}

export function formatLocale(
  locale: Locale | undefined,
  opts?: { engine?: string }
) {
  if (!locale) return 'No Locale Found'

  const engine = opts?.engine ?? 'g'

  return [
    engine ? formatEngine(engine) : null,
    formatLocaleCountry(locale),
    formatLocaleLanguage(locale),
    formatLocaleLocationCombo(locale),
  ]
    .filter(Boolean)
    .join(' - ')
}

export function renderLocale<TString extends boolean = false>(
  locale: Locale | undefined,
  opts?: { engine?: string; string: TString }
) {
  if (!locale) return 'No Locale Found'

  const engine = opts?.engine ?? 'g'

  const country = renderLocaleCountry(locale)
  const language = renderLocaleLanguage(locale)
  const location = renderLocaleLocationCombo(locale)

  return (
    <span className="inline-flex items-center gap-1">
      {[
        engine ? <span className="pr-1">{renderEngine(engine)}</span> : null,
        country,
        language,
        location,
      ]
        .filter(Boolean)
        .reduce((acc, d) => (
          <>
            {acc}
            <span>{d}</span>
          </>
        ))}
    </span>
  )
}
