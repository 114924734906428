import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceByUrlMetricsWidget,
  PerformanceByUrlTopWidget,
  PerformanceByUrlWinnersWidget,
  PerformanceByUrlLosersWidget,
  PerformanceByUrlUrlsWidget,
  PerformanceByUrlUrlIds,
} from '../../../containers/Dashboards/PerformanceByUrl'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-by-url'
)({
  validateSearch: search =>
    search as {
      performanceByUrlMetricsWidget?: PerformanceByUrlMetricsWidget
      performanceByUrlTopWidget?: PerformanceByUrlTopWidget
      performanceByUrlWinnersWidget?: PerformanceByUrlWinnersWidget
      performanceByUrlLosersWidget?: PerformanceByUrlLosersWidget
      performanceByUrlUrlsWidget?: PerformanceByUrlUrlsWidget
      performanceByUrlUrlIds?: PerformanceByUrlUrlIds
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceByUrlMetricsWidget: search.performanceByUrlMetricsWidget,
        performanceByUrlTopWidget: search.performanceByUrlTopWidget,
        performanceByUrlWinnersWidget: search.performanceByUrlWinnersWidget,
        performanceByUrlLosersWidget: search.performanceByUrlLosersWidget,
        performanceByUrlUrlsWidget: search.performanceByUrlUrlsWidget,
        performanceByUrlUrlIds: search.performanceByUrlUrlIds,
      }
    },
  ],
})
