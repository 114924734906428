// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/reports/v1/metrics.proto (package nozzle.reports.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Keyword } from "../../keywords/v1/keywords_pb.js";
import { Entity_Type } from "../../urls/v1/language_pb.js";

/**
 * KeywordMetrics represents metrics for individual or aggregations of keywords
 *
 * @generated from message nozzle.reports.v1.KeywordMetrics
 */
export class KeywordMetrics extends Message<KeywordMetrics> {
  /**
   * @generated from field: nozzle.keywords.v1.Keyword keyword = 1;
   */
  keyword?: Keyword;

  /**
   * @generated from field: nozzle.reports.v1.SerpMetrics serp_metrics = 2;
   */
  serpMetrics?: SerpMetrics;

  /**
   * @generated from field: repeated nozzle.reports.v1.RollupMetrics metrics_by_rollup = 3;
   */
  metricsByRollup: RollupMetrics[] = [];

  constructor(data?: PartialMessage<KeywordMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.KeywordMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword", kind: "message", T: Keyword },
    { no: 2, name: "serp_metrics", kind: "message", T: SerpMetrics },
    { no: 3, name: "metrics_by_rollup", kind: "message", T: RollupMetrics, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KeywordMetrics {
    return new KeywordMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KeywordMetrics {
    return new KeywordMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KeywordMetrics {
    return new KeywordMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: KeywordMetrics | PlainMessage<KeywordMetrics> | undefined, b: KeywordMetrics | PlainMessage<KeywordMetrics> | undefined): boolean {
    return proto3.util.equals(KeywordMetrics, a, b);
  }
}

/**
 * SerpMetrics represents metrics for individual or aggregations of SERPs
 *
 * @generated from message nozzle.reports.v1.SerpMetrics
 */
export class SerpMetrics extends Message<SerpMetrics> {
  /**
   * @generated from field: nozzle.reports.v1.Metric search_volume = 1;
   */
  searchVolume?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric available_traffic = 2;
   */
  availableTraffic?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric max_estimated_traffic = 3;
   */
  maxEstimatedTraffic?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric cpc = 4;
   */
  cpc?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric ads_high_top_of_page_bid = 5;
   */
  adsHighTopOfPageBid?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric ads_low_top_of_page_bid = 6;
   */
  adsLowTopOfPageBid?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric adwords_competition = 7;
   */
  adwordsCompetition?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric no_click_ctr = 8;
   */
  noClickCtr?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric paid_ctr = 9;
   */
  paidCtr?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric unpaid_ctr = 10;
   */
  unpaidCtr?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.ResultMetrics result_metrics = 11;
   */
  resultMetrics?: ResultMetrics;

  constructor(data?: PartialMessage<SerpMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.SerpMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search_volume", kind: "message", T: Metric },
    { no: 2, name: "available_traffic", kind: "message", T: Metric },
    { no: 3, name: "max_estimated_traffic", kind: "message", T: Metric },
    { no: 4, name: "cpc", kind: "message", T: Metric },
    { no: 5, name: "ads_high_top_of_page_bid", kind: "message", T: Metric },
    { no: 6, name: "ads_low_top_of_page_bid", kind: "message", T: Metric },
    { no: 7, name: "adwords_competition", kind: "message", T: Metric },
    { no: 8, name: "no_click_ctr", kind: "message", T: Metric },
    { no: 9, name: "paid_ctr", kind: "message", T: Metric },
    { no: 10, name: "unpaid_ctr", kind: "message", T: Metric },
    { no: 11, name: "result_metrics", kind: "message", T: ResultMetrics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SerpMetrics {
    return new SerpMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SerpMetrics {
    return new SerpMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SerpMetrics {
    return new SerpMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: SerpMetrics | PlainMessage<SerpMetrics> | undefined, b: SerpMetrics | PlainMessage<SerpMetrics> | undefined): boolean {
    return proto3.util.equals(SerpMetrics, a, b);
  }
}

/**
 * ResultMetrics represents metrics for individual or aggregations of results
 *
 * @generated from message nozzle.reports.v1.ResultMetrics
 */
export class ResultMetrics extends Message<ResultMetrics> {
  /**
   * @generated from field: nozzle.reports.v1.Metric serp_count = 1;
   */
  serpCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric keyword_count = 2;
   */
  keywordCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric result_count = 3;
   */
  resultCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric url_count = 4;
   */
  urlCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric cluster_count = 16;
   */
  clusterCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric top_rank = 5;
   */
  topRank?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric top_paid_adjusted_rank = 6;
   */
  topPaidAdjustedRank?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric ctr = 7;
   */
  ctr?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric estimated_traffic = 8;
   */
  estimatedTraffic?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric traffic_opportunity = 9;
   */
  trafficOpportunity?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric pixels_from_top = 10;
   */
  pixelsFromTop?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric above_the_fold_percentage = 11;
   */
  aboveTheFoldPercentage?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric serp_percentage = 12;
   */
  serpPercentage?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric title_count = 14;
   */
  titleCount?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric description_count = 15;
   */
  descriptionCount?: Metric;

  constructor(data?: PartialMessage<ResultMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.ResultMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "serp_count", kind: "message", T: Metric },
    { no: 2, name: "keyword_count", kind: "message", T: Metric },
    { no: 3, name: "result_count", kind: "message", T: Metric },
    { no: 4, name: "url_count", kind: "message", T: Metric },
    { no: 16, name: "cluster_count", kind: "message", T: Metric },
    { no: 5, name: "top_rank", kind: "message", T: Metric },
    { no: 6, name: "top_paid_adjusted_rank", kind: "message", T: Metric },
    { no: 7, name: "ctr", kind: "message", T: Metric },
    { no: 8, name: "estimated_traffic", kind: "message", T: Metric },
    { no: 9, name: "traffic_opportunity", kind: "message", T: Metric },
    { no: 10, name: "pixels_from_top", kind: "message", T: Metric },
    { no: 11, name: "above_the_fold_percentage", kind: "message", T: Metric },
    { no: 12, name: "serp_percentage", kind: "message", T: Metric },
    { no: 14, name: "title_count", kind: "message", T: Metric },
    { no: 15, name: "description_count", kind: "message", T: Metric },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResultMetrics {
    return new ResultMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResultMetrics {
    return new ResultMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResultMetrics {
    return new ResultMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: ResultMetrics | PlainMessage<ResultMetrics> | undefined, b: ResultMetrics | PlainMessage<ResultMetrics> | undefined): boolean {
    return proto3.util.equals(ResultMetrics, a, b);
  }
}

/**
 * RollupMetrics represents metrics for individual or aggregations of rollups.
 * label should always be set, and id should be set if the rollup has an id, like
 * a domain or a brand.
 *
 * @generated from message nozzle.reports.v1.RollupMetrics
 */
export class RollupMetrics extends Message<RollupMetrics> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: nozzle.reports.v1.ResultMetrics metrics = 3;
   */
  metrics?: ResultMetrics;

  constructor(data?: PartialMessage<RollupMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.RollupMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metrics", kind: "message", T: ResultMetrics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RollupMetrics {
    return new RollupMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RollupMetrics {
    return new RollupMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RollupMetrics {
    return new RollupMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: RollupMetrics | PlainMessage<RollupMetrics> | undefined, b: RollupMetrics | PlainMessage<RollupMetrics> | undefined): boolean {
    return proto3.util.equals(RollupMetrics, a, b);
  }
}

/**
 * Metric represents a collection of common metric aggregations
 *
 * @generated from message nozzle.reports.v1.Metric
 */
export class Metric extends Message<Metric> {
  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation sum = 1;
   */
  sum?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation avg = 2;
   */
  avg?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation min = 3;
   */
  min?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation p25 = 4;
   */
  p25?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation p50 = 5;
   */
  p50?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation p75 = 6;
   */
  p75?: MetricAggregation;

  /**
   * @generated from field: optional nozzle.reports.v1.MetricAggregation max = 7;
   */
  max?: MetricAggregation;

  constructor(data?: PartialMessage<Metric>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.Metric";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sum", kind: "message", T: MetricAggregation, opt: true },
    { no: 2, name: "avg", kind: "message", T: MetricAggregation, opt: true },
    { no: 3, name: "min", kind: "message", T: MetricAggregation, opt: true },
    { no: 4, name: "p25", kind: "message", T: MetricAggregation, opt: true },
    { no: 5, name: "p50", kind: "message", T: MetricAggregation, opt: true },
    { no: 6, name: "p75", kind: "message", T: MetricAggregation, opt: true },
    { no: 7, name: "max", kind: "message", T: MetricAggregation, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Metric {
    return new Metric().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Metric {
    return new Metric().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Metric {
    return new Metric().fromJsonString(jsonString, options);
  }

  static equals(a: Metric | PlainMessage<Metric> | undefined, b: Metric | PlainMessage<Metric> | undefined): boolean {
    return proto3.util.equals(Metric, a, b);
  }
}

/**
 * MetricAggregation represents a single metric aggregation, including optional
 * percentage values and other post-aggregations
 *
 * @generated from message nozzle.reports.v1.MetricAggregation
 */
export class MetricAggregation extends Message<MetricAggregation> {
  /**
   * @generated from field: optional float value = 1;
   */
  value?: number;

  /**
   * @generated from field: optional float change = 2;
   */
  change?: number;

  /**
   * @generated from field: optional float step_change = 3;
   */
  stepChange?: number;

  /**
   * @generated from field: optional float best = 4;
   */
  best?: number;

  /**
   * @generated from field: optional float worst = 5;
   */
  worst?: number;

  /**
   * @generated from field: optional float percentage_value = 6;
   */
  percentageValue?: number;

  /**
   * @generated from field: optional float percentage_change = 7;
   */
  percentageChange?: number;

  /**
   * @generated from field: optional float percentage_step_change = 8;
   */
  percentageStepChange?: number;

  /**
   * @generated from field: optional float percentage_best = 9;
   */
  percentageBest?: number;

  /**
   * @generated from field: optional float percentage_worst = 10;
   */
  percentageWorst?: number;

  constructor(data?: PartialMessage<MetricAggregation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.MetricAggregation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "change", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "step_change", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "best", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "worst", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 6, name: "percentage_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 7, name: "percentage_change", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 8, name: "percentage_step_change", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 9, name: "percentage_best", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 10, name: "percentage_worst", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetricAggregation {
    return new MetricAggregation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetricAggregation {
    return new MetricAggregation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetricAggregation {
    return new MetricAggregation().fromJsonString(jsonString, options);
  }

  static equals(a: MetricAggregation | PlainMessage<MetricAggregation> | undefined, b: MetricAggregation | PlainMessage<MetricAggregation> | undefined): boolean {
    return proto3.util.equals(MetricAggregation, a, b);
  }
}

/**
 * EntityMetrics represents metrics for individual or aggregations of entities
 *
 * @generated from message nozzle.reports.v1.EntityMetrics
 */
export class EntityMetrics extends Message<EntityMetrics> {
  /**
   * @generated from field: string entity = 1;
   */
  entity = "";

  /**
   * @generated from field: nozzle.urls.v1.Entity.Type type = 2;
   */
  type = Entity_Type.UNKNOWN;

  /**
   * @generated from field: nozzle.reports.v1.Metric salience = 3;
   */
  salience?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric sentiment_score = 4;
   */
  sentimentScore?: Metric;

  /**
   * @generated from field: nozzle.reports.v1.Metric sentiment_magnitude = 5;
   */
  sentimentMagnitude?: Metric;

  constructor(data?: PartialMessage<EntityMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.EntityMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Entity_Type) },
    { no: 3, name: "salience", kind: "message", T: Metric },
    { no: 4, name: "sentiment_score", kind: "message", T: Metric },
    { no: 5, name: "sentiment_magnitude", kind: "message", T: Metric },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityMetrics {
    return new EntityMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityMetrics {
    return new EntityMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityMetrics {
    return new EntityMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: EntityMetrics | PlainMessage<EntityMetrics> | undefined, b: EntityMetrics | PlainMessage<EntityMetrics> | undefined): boolean {
    return proto3.util.equals(EntityMetrics, a, b);
  }
}

/**
 * CategoryMetrics represents metrics for individual or aggregations of categories
 *
 * @generated from message nozzle.reports.v1.CategoryMetrics
 */
export class CategoryMetrics extends Message<CategoryMetrics> {
  /**
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: nozzle.reports.v1.Metric confidence = 2;
   */
  confidence?: Metric;

  constructor(data?: PartialMessage<CategoryMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.CategoryMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "confidence", kind: "message", T: Metric },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryMetrics {
    return new CategoryMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryMetrics {
    return new CategoryMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryMetrics {
    return new CategoryMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryMetrics | PlainMessage<CategoryMetrics> | undefined, b: CategoryMetrics | PlainMessage<CategoryMetrics> | undefined): boolean {
    return proto3.util.equals(CategoryMetrics, a, b);
  }
}

/**
 * A Facet is a collection of FacetValues, with optional min and max values.
 *
 * @generated from message nozzle.reports.v1.Facet
 */
export class Facet extends Message<Facet> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated nozzle.reports.v1.FacetValue facets = 2;
   */
  facets: FacetValue[] = [];

  /**
   * min & max will only be set for numeric facets
   *
   * @generated from field: float min = 3;
   */
  min = 0;

  /**
   * @generated from field: float max = 4;
   */
  max = 0;

  constructor(data?: PartialMessage<Facet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.Facet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "facets", kind: "message", T: FacetValue, repeated: true },
    { no: 3, name: "min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Facet {
    return new Facet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Facet {
    return new Facet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Facet {
    return new Facet().fromJsonString(jsonString, options);
  }

  static equals(a: Facet | PlainMessage<Facet> | undefined, b: Facet | PlainMessage<Facet> | undefined): boolean {
    return proto3.util.equals(Facet, a, b);
  }
}

/**
 * FacetValue is a single facet. If there is a meaningful id, it will be used,
 * otherwise a hash of the label will be used as the id.
 *
 * @generated from message nozzle.reports.v1.FacetValue
 */
export class FacetValue extends Message<FacetValue> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * min & max will only be set for numeric facets
   *
   * actual minimum value
   *
   * @generated from field: float min = 4;
   */
  min = 0;

  /**
   * actual maximum value
   *
   * @generated from field: float max = 5;
   */
  max = 0;

  /**
   * minimum possible value
   *
   * @generated from field: float logical_min = 6;
   */
  logicalMin = 0;

  /**
   * maximum possible value
   *
   * @generated from field: float logical_max = 7;
   */
  logicalMax = 0;

  constructor(data?: PartialMessage<FacetValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.reports.v1.FacetValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "logical_min", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "logical_max", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacetValue {
    return new FacetValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacetValue {
    return new FacetValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacetValue {
    return new FacetValue().fromJsonString(jsonString, options);
  }

  static equals(a: FacetValue | PlainMessage<FacetValue> | undefined, b: FacetValue | PlainMessage<FacetValue> | undefined): boolean {
    return proto3.util.equals(FacetValue, a, b);
  }
}

