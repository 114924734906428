// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/serpwoo.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Device } from "../../keywords/v1/keywords_pb.js";

/**
 * ImportSerpwooRequest is the request for ImportSerpwoo.
 *
 * @generated from message nozzle.workspaces.v1.ImportSerpwooRequest
 */
export class ImportSerpwooRequest extends Message<ImportSerpwooRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: bytes zip_file = 2;
   */
  zipFile = new Uint8Array(0);

  constructor(data?: PartialMessage<ImportSerpwooRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ImportSerpwooRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "zip_file", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSerpwooRequest {
    return new ImportSerpwooRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSerpwooRequest {
    return new ImportSerpwooRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSerpwooRequest {
    return new ImportSerpwooRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSerpwooRequest | PlainMessage<ImportSerpwooRequest> | undefined, b: ImportSerpwooRequest | PlainMessage<ImportSerpwooRequest> | undefined): boolean {
    return proto3.util.equals(ImportSerpwooRequest, a, b);
  }
}

/**
 * ImportSerpwooResponse is the response for ImportSerpwoo.
 *
 * @generated from message nozzle.workspaces.v1.ImportSerpwooResponse
 */
export class ImportSerpwooResponse extends Message<ImportSerpwooResponse> {
  /**
   * @generated from field: int32 project_count = 1;
   */
  projectCount = 0;

  /**
   * @generated from field: int32 project_with_error_count = 2;
   */
  projectWithErrorCount = 0;

  /**
   * @generated from field: nozzle.workspaces.v1.SerpwooImportStats stats = 3;
   */
  stats?: SerpwooImportStats;

  /**
   * @generated from field: repeated nozzle.workspaces.v1.SerpwooProject projects = 4;
   */
  projects: SerpwooProject[] = [];

  constructor(data?: PartialMessage<ImportSerpwooResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ImportSerpwooResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "project_with_error_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "stats", kind: "message", T: SerpwooImportStats },
    { no: 4, name: "projects", kind: "message", T: SerpwooProject, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportSerpwooResponse {
    return new ImportSerpwooResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportSerpwooResponse {
    return new ImportSerpwooResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportSerpwooResponse {
    return new ImportSerpwooResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportSerpwooResponse | PlainMessage<ImportSerpwooResponse> | undefined, b: ImportSerpwooResponse | PlainMessage<ImportSerpwooResponse> | undefined): boolean {
    return proto3.util.equals(ImportSerpwooResponse, a, b);
  }
}

/**
 * SerpwooProject is a summary of the imported data
 *
 * @generated from message nozzle.workspaces.v1.SerpwooProject
 */
export class SerpwooProject extends Message<SerpwooProject> {
  /**
   * @generated from field: int64 project_id = 1;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int64 keyword_source_id = 3;
   */
  keywordSourceId = protoInt64.zero;

  /**
   * @generated from field: nozzle.keywords.v1.Device device = 4;
   */
  device = Device.UNSPECIFIED;

  /**
   * @generated from field: string serpwoo_location = 5;
   */
  serpwooLocation = "";

  /**
   * @generated from field: string nozzle_location = 6;
   */
  nozzleLocation = "";

  /**
   * @generated from field: nozzle.workspaces.v1.SerpwooImportStats stats = 7;
   */
  stats?: SerpwooImportStats;

  constructor(data?: PartialMessage<SerpwooProject>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.SerpwooProject";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "keyword_source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "device", kind: "enum", T: proto3.getEnumType(Device) },
    { no: 5, name: "serpwoo_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "nozzle_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "stats", kind: "message", T: SerpwooImportStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SerpwooProject {
    return new SerpwooProject().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SerpwooProject {
    return new SerpwooProject().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SerpwooProject {
    return new SerpwooProject().fromJsonString(jsonString, options);
  }

  static equals(a: SerpwooProject | PlainMessage<SerpwooProject> | undefined, b: SerpwooProject | PlainMessage<SerpwooProject> | undefined): boolean {
    return proto3.util.equals(SerpwooProject, a, b);
  }
}

/**
 * SerpwooImportStats shows statistics about the imported data
 *
 * @generated from message nozzle.workspaces.v1.SerpwooImportStats
 */
export class SerpwooImportStats extends Message<SerpwooImportStats> {
  /**
   * @generated from field: int32 keyword_count = 1;
   */
  keywordCount = 0;

  /**
   * @generated from field: int32 keyword_quote_error_count = 2;
   */
  keywordQuoteErrorCount = 0;

  /**
   * @generated from field: int32 keyword_other_error_count = 3;
   */
  keywordOtherErrorCount = 0;

  /**
   * @generated from field: int32 brand_count = 4;
   */
  brandCount = 0;

  /**
   * @generated from field: int32 brand_positive_count = 5;
   */
  brandPositiveCount = 0;

  /**
   * @generated from field: int32 brand_neutral_count = 6;
   */
  brandNeutralCount = 0;

  /**
   * @generated from field: int32 brand_negative_count = 7;
   */
  brandNegativeCount = 0;

  /**
   * @generated from field: int32 brand_unknown_count = 8;
   */
  brandUnknownCount = 0;

  constructor(data?: PartialMessage<SerpwooImportStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.SerpwooImportStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "keyword_quote_error_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "keyword_other_error_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "brand_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "brand_positive_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "brand_neutral_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "brand_negative_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "brand_unknown_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SerpwooImportStats {
    return new SerpwooImportStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SerpwooImportStats {
    return new SerpwooImportStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SerpwooImportStats {
    return new SerpwooImportStats().fromJsonString(jsonString, options);
  }

  static equals(a: SerpwooImportStats | PlainMessage<SerpwooImportStats> | undefined, b: SerpwooImportStats | PlainMessage<SerpwooImportStats> | undefined): boolean {
    return proto3.util.equals(SerpwooImportStats, a, b);
  }
}

