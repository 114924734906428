import { createFileRoute } from '@tanstack/react-router'
import { allQueryFacets } from '../utils/queryFacets'
import { multiSortBy } from '../utils'

export const Route = createFileRoute('/_auth')({
  postSearchFilters: [
    search => {
      // The types for the old v1 search params
      // We'll migrate these to the new search params in the next step
      const v1Search = search as {
        dashboard?: {
          teamId?: string
          rollupBy?: string
          rollupValue?: string
          groupBy?: string
        }
      }

      // Migrate dashboard.teamId to search.projectId
      if (v1Search?.dashboard?.teamId) {
        search.projectId = v1Search.dashboard.teamId
        delete v1Search.dashboard.teamId
      }

      // Migrate dashboard.rollupBy to search.rollup
      if (v1Search.dashboard?.rollupBy) {
        if (!search.rollup) {
          const queryFacet =
            allQueryFacets.find(
              d => d.filterKey === v1Search.dashboard!.rollupBy!
            ) ?? allQueryFacets[0]!

          search.rollup = `${queryFacet.id}${
            v1Search.dashboard.rollupValue
              ? `__${v1Search.dashboard.rollupValue}`
              : ''
          }`
        }

        delete v1Search.dashboard.rollupBy
        delete v1Search.dashboard.rollupValue
      }

      // Migrate dashboard.groupBy out of dashboard
      if (v1Search.dashboard?.groupBy) {
        if (!search.groupBy) {
          const queryFacet = allQueryFacets.find(
            d => d.filterKey === v1Search.dashboard!.groupBy!
          )

          if (queryFacet) {
            search.groupBy = queryFacet.id
          }
        }

        delete v1Search.dashboard.groupBy
      }

      // Migrate the rest of the dashboard out of dashboard
      if (v1Search.dashboard) {
        Object.assign(search, v1Search.dashboard)
        v1Search.dashboard = undefined!
      }

      // Sort search param keys to our liking
      return Object.fromEntries(
        multiSortBy(Object.keys(search), [
          d => (d === 'workspace' ? -1 : 0),
          d => (d === 'workspaceId' ? -1 : 0),
          d => (d === 'projectId' ? -1 : 0),
          d => (d === 'userId' ? -1 : 0),
          d => (d.includes('brand') ? -1 : 0),
          d => (d === 'rollup' ? -1 : 0),
          d => (d === 'metricId' ? -1 : 0),
          d => (d === 'keywordId' ? -1 : 0),
          d => (d === 'segmentId' ? -1 : 0),
        ]).map(key => [key, search[key as keyof typeof search]])
      )
    },
  ],
})
