import * as React from 'react'
import { replaceEqualDeep } from '../utils'

export function useStructuralSharing<T>(obj: T): T {
  const previous = React.useRef(obj)

  if (obj !== previous.current) {
    obj = replaceEqualDeep(previous.current, obj)
  }

  React.useEffect(() => {
    previous.current = obj
  })

  return obj
}
