// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file nozzle/workspaces/v1/keyword.proto (package nozzle.workspaces.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Device, Keyword as Keyword$1, LocationType } from "../../keywords/v1/keywords_pb.js";
import { Facet } from "../../reports/v1/metrics_pb.js";

/**
 * ListKeywordsRequest is the request for the ListKeywords method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordsRequest
 */
export class ListKeywordsRequest extends Message<ListKeywordsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * default page size is 100
   *
   * @generated from field: int32 page_size = 4;
   */
  pageSize = 0;

  /**
   * @generated from field: nozzle.workspaces.v1.ListKeywordsFilters filters = 6;
   */
  filters?: ListKeywordsFilters;

  constructor(data?: PartialMessage<ListKeywordsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "filters", kind: "message", T: ListKeywordsFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordsRequest {
    return new ListKeywordsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordsRequest {
    return new ListKeywordsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordsRequest {
    return new ListKeywordsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordsRequest | PlainMessage<ListKeywordsRequest> | undefined, b: ListKeywordsRequest | PlainMessage<ListKeywordsRequest> | undefined): boolean {
    return proto3.util.equals(ListKeywordsRequest, a, b);
  }
}

/**
 * ListKeywordsFilters is a set of available filters to apply to the list request.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordsFilters
 */
export class ListKeywordsFilters extends Message<ListKeywordsFilters> {
  /**
   * @generated from field: string search = 1;
   */
  search = "";

  /**
   * only match keywords on any of the provided keyword_ids
   *
   * @generated from field: repeated int64 include_keyword_ids = 2;
   */
  includeKeywordIds: bigint[] = [];

  /**
   * only match keywords on none of the provided keyword_ids
   *
   * @generated from field: repeated int64 exclude_keyword_ids = 3;
   */
  excludeKeywordIds: bigint[] = [];

  /**
   * only match keywords on any of the provided phrases are in at least one
   *
   * @generated from field: repeated string phrase_contains = 4;
   */
  phraseContains: string[] = [];

  /**
   * only match keywords on none of the provided phrases are in at least one
   *
   * @generated from field: repeated string phrase_does_not_contain = 5;
   */
  phraseDoesNotContain: string[] = [];

  /**
   * only match keywords on the provided regex matches at least one keyword
   *
   * @generated from field: string phrase_regex_contains = 6;
   */
  phraseRegexContains = "";

  /**
   * only match keywords on the provided regex matches zero keyword
   *
   * @generated from field: string phrase_regex_does_not_contain = 7;
   */
  phraseRegexDoesNotContain = "";

  /**
   * only match keywords on any of the provided locale_ids
   *
   * @generated from field: repeated int64 include_locale_ids = 8;
   */
  includeLocaleIds: bigint[] = [];

  /**
   * only match keywords on none of the provided locale_ids
   *
   * @generated from field: repeated int64 exclude_locale_ids = 9;
   */
  excludeLocaleIds: bigint[] = [];

  /**
   * only match keywords on any of the provided language_codes
   *
   * @generated from field: repeated string include_language_codes = 10;
   */
  includeLanguageCodes: string[] = [];

  /**
   * only match keywords on none of the provided language_codes
   *
   * @generated from field: repeated string exclude_language_codes = 11;
   */
  excludeLanguageCodes: string[] = [];

  /**
   * only match keywords on any of the provided devices
   *
   * @generated from field: repeated nozzle.keywords.v1.Device include_devices = 12;
   */
  includeDevices: Device[] = [];

  /**
   * only match keywords on none of the provided devices
   *
   * @generated from field: repeated nozzle.keywords.v1.Device exclude_devices = 13;
   */
  excludeDevices: Device[] = [];

  /**
   * only match keywords on any of the provided location_ids
   *
   * @generated from field: repeated int64 include_location_ids = 14;
   */
  includeLocationIds: bigint[] = [];

  /**
   * only match keywords on none of the provided location_ids
   *
   * @generated from field: repeated int64 exclude_location_ids = 15;
   */
  excludeLocationIds: bigint[] = [];

  /**
   * only match keywords on any of the provided country_codes
   *
   * @generated from field: repeated string include_country_codes = 16;
   */
  includeCountryCodes: string[] = [];

  /**
   * only match keywords on none of the provided country_codes
   *
   * @generated from field: repeated string exclude_country_codes = 17;
   */
  excludeCountryCodes: string[] = [];

  /**
   * only match keywords on any of the provided location_types
   *
   * @generated from field: repeated nozzle.keywords.v1.LocationType include_location_types = 18;
   */
  includeLocationTypes: LocationType[] = [];

  /**
   * only match keywords on none of the provided location_types
   *
   * @generated from field: repeated nozzle.keywords.v1.LocationType exclude_location_types = 19;
   */
  excludeLocationTypes: LocationType[] = [];

  constructor(data?: PartialMessage<ListKeywordsFilters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordsFilters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "include_keyword_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "exclude_keyword_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "phrase_contains", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "phrase_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "phrase_regex_contains", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "phrase_regex_does_not_contain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "include_locale_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 9, name: "exclude_locale_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 10, name: "include_language_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "exclude_language_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "include_devices", kind: "enum", T: proto3.getEnumType(Device), repeated: true },
    { no: 13, name: "exclude_devices", kind: "enum", T: proto3.getEnumType(Device), repeated: true },
    { no: 14, name: "include_location_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 15, name: "exclude_location_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 16, name: "include_country_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "exclude_country_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "include_location_types", kind: "enum", T: proto3.getEnumType(LocationType), repeated: true },
    { no: 19, name: "exclude_location_types", kind: "enum", T: proto3.getEnumType(LocationType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordsFilters {
    return new ListKeywordsFilters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordsFilters {
    return new ListKeywordsFilters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordsFilters {
    return new ListKeywordsFilters().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordsFilters | PlainMessage<ListKeywordsFilters> | undefined, b: ListKeywordsFilters | PlainMessage<ListKeywordsFilters> | undefined): boolean {
    return proto3.util.equals(ListKeywordsFilters, a, b);
  }
}

/**
 * ListKeywordsResponse is the response for the ListKeywords method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordsResponse
 */
export class ListKeywordsResponse extends Message<ListKeywordsResponse> {
  /**
   * @generated from field: repeated nozzle.workspaces.v1.Keyword keywords = 1;
   */
  keywords: Keyword[] = [];

  constructor(data?: PartialMessage<ListKeywordsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keywords", kind: "message", T: Keyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordsResponse {
    return new ListKeywordsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordsResponse {
    return new ListKeywordsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordsResponse {
    return new ListKeywordsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordsResponse | PlainMessage<ListKeywordsResponse> | undefined, b: ListKeywordsResponse | PlainMessage<ListKeywordsResponse> | undefined): boolean {
    return proto3.util.equals(ListKeywordsResponse, a, b);
  }
}

/**
 * ListKeywordsFacetsRequest is the request for the ListKeywordsFacets method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordsFacetsRequest
 */
export class ListKeywordsFacetsRequest extends Message<ListKeywordsFacetsRequest> {
  /**
   * @generated from field: int64 workspace_id = 1;
   */
  workspaceId = protoInt64.zero;

  /**
   * @generated from field: int64 project_id = 2;
   */
  projectId = protoInt64.zero;

  /**
   * @generated from field: nozzle.workspaces.v1.ListKeywordsFilters filters = 7;
   */
  filters?: ListKeywordsFilters;

  constructor(data?: PartialMessage<ListKeywordsFacetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordsFacetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "workspace_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "project_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "filters", kind: "message", T: ListKeywordsFilters },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordsFacetsRequest {
    return new ListKeywordsFacetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordsFacetsRequest {
    return new ListKeywordsFacetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordsFacetsRequest {
    return new ListKeywordsFacetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordsFacetsRequest | PlainMessage<ListKeywordsFacetsRequest> | undefined, b: ListKeywordsFacetsRequest | PlainMessage<ListKeywordsFacetsRequest> | undefined): boolean {
    return proto3.util.equals(ListKeywordsFacetsRequest, a, b);
  }
}

/**
 * ListKeywordsFacetsResponse is the response for the ListKeywordsFacets method.
 *
 * @generated from message nozzle.workspaces.v1.ListKeywordsFacetsResponse
 */
export class ListKeywordsFacetsResponse extends Message<ListKeywordsFacetsResponse> {
  /**
   * @generated from field: nozzle.reports.v1.Facet phrases = 1;
   */
  phrases?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet locales = 2;
   */
  locales?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet countries = 3;
   */
  countries?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet languages = 4;
   */
  languages?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet locations = 5;
   */
  locations?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet devices = 6;
   */
  devices?: Facet;

  /**
   * @generated from field: nozzle.reports.v1.Facet keyword_groups = 7;
   */
  keywordGroups?: Facet;

  constructor(data?: PartialMessage<ListKeywordsFacetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.ListKeywordsFacetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phrases", kind: "message", T: Facet },
    { no: 2, name: "locales", kind: "message", T: Facet },
    { no: 3, name: "countries", kind: "message", T: Facet },
    { no: 4, name: "languages", kind: "message", T: Facet },
    { no: 5, name: "locations", kind: "message", T: Facet },
    { no: 6, name: "devices", kind: "message", T: Facet },
    { no: 7, name: "keyword_groups", kind: "message", T: Facet },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListKeywordsFacetsResponse {
    return new ListKeywordsFacetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListKeywordsFacetsResponse {
    return new ListKeywordsFacetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListKeywordsFacetsResponse {
    return new ListKeywordsFacetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListKeywordsFacetsResponse | PlainMessage<ListKeywordsFacetsResponse> | undefined, b: ListKeywordsFacetsResponse | PlainMessage<ListKeywordsFacetsResponse> | undefined): boolean {
    return proto3.util.equals(ListKeywordsFacetsResponse, a, b);
  }
}

/**
 * Keyword is a keyword with workspace metadata.
 *
 * @generated from message nozzle.workspaces.v1.Keyword
 */
export class Keyword extends Message<Keyword> {
  /**
   * @generated from field: nozzle.keywords.v1.Keyword keyword = 1;
   */
  keyword?: Keyword$1;

  constructor(data?: PartialMessage<Keyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "nozzle.workspaces.v1.Keyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword", kind: "message", T: Keyword$1 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Keyword {
    return new Keyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Keyword {
    return new Keyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Keyword {
    return new Keyword().fromJsonString(jsonString, options);
  }

  static equals(a: Keyword | PlainMessage<Keyword> | undefined, b: Keyword | PlainMessage<Keyword> | undefined): boolean {
    return proto3.util.equals(Keyword, a, b);
  }
}

