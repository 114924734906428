import { createFileRoute } from '@tanstack/react-router'
import {
  PerformanceSingleKeywordMetricsWidget,
  PerformanceSingleKeywordHistoryWidget,
  PerformanceSingleKeywordSerpWidget,
} from '../../../containers/Dashboards/PerformanceSingleKeyword'

export const Route = createFileRoute(
  '/_auth/_nav/dashboards/performance-single-keyword'
)({
  validateSearch: search =>
    search as {
      performanceSingleKeywordMetricsWidget?: PerformanceSingleKeywordMetricsWidget
      performanceSingleKeywordHistoryWidget?: PerformanceSingleKeywordHistoryWidget
      performanceSingleKeywordSerpWidget?: PerformanceSingleKeywordSerpWidget
      keywordId: string
    },
  postSearchFilters: [
    search => {
      return {
        ...search,
        performanceSingleKeywordMetricsWidget:
          search.performanceSingleKeywordMetricsWidget,
        performanceSingleKeywordHistoryWidget:
          search.performanceSingleKeywordHistoryWidget,
        performanceSingleKeywordSerpWidget:
          search.performanceSingleKeywordSerpWidget,
        keywordId: search.keywordId,
      }
    },
  ],
})
